import { isEmpty } from 'lodash';

import {
  ArApType,
  ENDPOINTS,
  InvoiceCategory,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';

import {
  useMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export * from './ecus';

const SERVICE_INVOICE_PARAMS = {
  [ArApType.Ar]: {
    endPoint: ENDPOINTS.arInvoice(InvoiceCategory.Service),
    queryKey: QUERY_KEYS.arInvoice
  },
  [ArApType.Ap]: {
    endPoint: ENDPOINTS.apInvoice(InvoiceCategory.Service),
    queryKey: QUERY_KEYS.apInvoice
  }
};

export const useCUDService = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.service(),
    webTable: WebTable.SERVICE,
    queryKey: QUERY_KEYS.service,
    redirectPath: '../'
  });
};

export const useCUDServiceCustoms = () => {
  return useMutationEnhancer<any, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.service('customs/:id', { id: data?.id }),
        body: data,
        method: 'PUT',
        showToast: true
      });

      return res?.data;
    },
    mutationKeys: [[QUERY_KEYS.serviceCustoms]]
  });
};

export const useFetchService = (id?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.service, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.service(':id', { id })
      });

      return !isEmpty(res.data)
        ? {
            ...res.data,
            isLinkedToShipment: !!res.data.shipmentId
          }
        : null;
    },
    cacheTime: 0,
    enabled: !!id
  });
};

export const useFetchServiceCustoms = (id?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.serviceCustoms, id],
    queryFn: async () => {
      let res = await APIManager.request({
        url: ENDPOINTS.service('customs/:id', { id }),
        body: {
          includeFields: [
            'shippingLine',
            'shippingLineAgent',
            'airline',
            'airlineAgent',
            'currency',
            'coLoaderAgent',
            'coLoader'
          ]
        }
      });

      return res.data ?? null;
    },
    cacheTime: 0,
    enabled: !!id
  });
};

export const useFetchServiceInvoice = (
  arApType: ArApType,
  id?: string | number
) => {
  const { queryKey, endPoint } = SERVICE_INVOICE_PARAMS[arApType];

  return useQueryEnhancer<any>({
    queryKey: [queryKey, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: `${endPoint}/${id}`,
        body: {
          includeFields: [
            'payableAdvice',
            'paymentMode',
            'companyBank',
            'paymentCurrency',
            // ar
            'arInvoiceStatus',
            'accountReceivable',
            'arBank',
            // ap
            'apInvoiceStatus',
            'accountPayable',
            'accountPayableBank'
          ]
        }
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchShipmentServiceOriDes = (shipmentId?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.shipmentServiceOriDes, shipmentId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipmentService('', {
          shipmentId
        })
      });

      return res.data ?? null;
    },
    cacheTime: 0,
    enabled: !!shipmentId
  });
};

export const useServiceCheckThreshold = (serviceId?: string | number) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.serviceCheckThreshold],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.service(':serviceId/check-threshold', { serviceId })
      });

      return res?.data || null;
    },
    enabled: !!serviceId
  });
};

export const useFetchServiceContainer = (
  containerId?: string | number,
  options?: { serviceId?: string | number; shipmentId?: string | number }
) => {
  const { serviceId, shipmentId } = options || {};

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.serviceContainer, containerId, serviceId, shipmentId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: !shipmentId
          ? ENDPOINTS.serviceContainer('unlink-to-shipment/:containerId', {
              containerId
            })
          : ENDPOINTS.shipment(':shipmentId/container/:containerId', {
              containerId,
              shipmentId
            }),
        body: {
          includeFields: !!shipmentId ? ['shipment'] : undefined
        }
      });

      return res.data ?? null;
    },
    enabled: !!containerId && !!serviceId
  });
};
