import { IMetadata } from '@dto';
import { IItemProps } from '@ui';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ENDPOINTS, Status, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDChargeCodeSubCategory } from 'hooks';
import trans from 'translation';
import { KGrid, KInput, KForm, KListItem } from 'uikit';
import { ValidationUtils } from 'utils';

import {
  onShowChargeCodeCategory,
  useDeleteChargeCodeSubCategoryHelper
} from '../../helpers';

interface IFormData {
  chargeCodeCategory: IMetadata;
  code: string;
  name: string;
  status: Status;
  description: string;
  remark: string;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required(),
  chargeCodeCategory: ValidationUtils.requiredAny()
});

const Form = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDChargeCodeSubCategory();

  const { onDelete, deleteLoading } = useDeleteChargeCodeSubCategoryHelper();

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        chargeCodeCategory: item?.chargeCodeCategory,
        code: item?.code ?? '',
        name: item?.name ?? '',
        status: item?.status ?? Status.Active,
        remark: item?.remark ?? '',
        description: item?.description ?? ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { chargeCodeCategory, ...rest } = data;
      const mParams = {
        id: item?.id,
        ...rest,
        chargeCodeCategoryId: chargeCodeCategory.id
      };
      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, item?.id, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('code')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={7}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('name')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2}>
          <KListItem.Radio
            name="isSystem"
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            marginT="0.75rem"
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="chargeCodeCategory"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('charge_code_category')}
                  inputProps={{
                    message: error?.message,
                    required: !item?.isSystem
                  }}
                  disabled={item?.isSystem}
                  apiURL={ENDPOINTS.chargeCodeCategory()}
                  hasEdit
                  onEdit={onShowChargeCodeCategory}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="description"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('description')}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="remark"
            control={methods.control}
            render={({ field }) => {
              return <KInput.TextField {...field} label={trans('remark')} />;
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            disabled: item?.isSystem,
            isLoading: deleteLoading
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(Form);
