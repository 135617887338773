import { ILibraryParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../core';

export const useCUDClientType = () => {
  return useCUDMutationEnhancer<ILibraryParams>({
    endPoint: ENDPOINTS.clientType(),
    webTable: WebTable.CLIENT_TYPE
  });
};

export const useCUDClientCategory = () => {
  return useCUDMutationEnhancer<ILibraryParams>({
    endPoint: ENDPOINTS.clientCategory(),
    webTable: WebTable.CLIENT_CATEGORY
  });
};

export const useCUDClientStage = () => {
  return useCUDMutationEnhancer<ILibraryParams>({
    endPoint: ENDPOINTS.clientStage(),
    webTable: WebTable.CLIENT_STAGE
  });
};
