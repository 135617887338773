import contained from './contained';
import outlined from './outlined';
import root from './root';

const button = {
  styleOverrides: {
    root: { ...root },
    contained: { ...contained.base },
    containedPrimary: { ...contained.primary },
    containedSecondary: { ...contained.secondary },
    outlined: { ...outlined.base },
    outlinedPrimary: { ...outlined.primary },
    outlinedSecondary: { ...outlined.secondary }
  }
};

export default button;
