import { VSection } from '@dto';
import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React, { useState, memo, cloneElement } from 'react';
import { KContainer, KGrid } from 'uikit';

import SectionItem from 'components/SystemSettings/SectionItem';
import trans from 'translation';

const Unit = loadable(() => pMinDelay(import('./Unit'), 200));
const VesselFlight = loadable(() => pMinDelay(import('./VesselFlight'), 200));
const Shipment = loadable(() => pMinDelay(import('./Shipment'), 200));
const PackageType = loadable(() => pMinDelay(import('./PackageType'), 200));
const Other = loadable(() => pMinDelay(import('./Other'), 200));
const Incoterm = loadable(() => pMinDelay(import('./Incoterm'), 200));
const CarrierPolicy = loadable(() => pMinDelay(import('./CarrierPolicy'), 200));
const Container = loadable(() => pMinDelay(import('./Container'), 200));

const listFreight = [
  {
    key: 'vesselFlight',
    img: '/image/vessel-airline.svg',
    title: 'vessel_flight'
  },
  {
    key: 'carrierPolicy',
    img: '/image/carrier-container.svg',
    title: 'carrier_policy'
  },
  {
    key: 'incoterm',
    img: '/image/incoterm.svg',
    title: 'incoterm'
  },
  {
    key: 'shipment',
    img: '/image/shipment-type.svg',
    title: 'shipment'
  },
  {
    key: 'packageType',
    img: '/image/package-type.svg',
    title: 'package_type'
  },
  {
    key: 'container',
    img: '/image/container.svg',
    title: 'container'
  },
  // {
  //   key: 'unit',
  //   img: '/image/unit.svg',
  //   title: 'unit'
  // },
  {
    key: 'other',
    img: '/image/other.svg',
    title: 'other'
  }
];

const listComponentFreight = {
  vesselFlight: <VesselFlight />,
  carrierPolicy: <CarrierPolicy />,
  incoterm: <Incoterm />,
  shipment: <Shipment />,
  packageType: <PackageType />,
  container: <Container />,
  unit: <Unit />,
  other: <Other />
};

const Freight = () => {
  const [section, setSection] = useState<VSection | undefined>(undefined);

  if (section && listComponentFreight[section.key]) {
    // Truyền props vào component tương ứng
    return cloneElement(listComponentFreight[section.key], {
      setSection
    });
  }

  return (
    <KContainer.Card
      header={{
        icon: 'ErrorOutlineOutlined',
        title: trans('tab.freight')
      }}
    >
      <KGrid.Container marginX={'5rem'} marginY={'0.75rem'}>
        {listFreight.map(item => (
          <SectionItem key={item.key} item={item} setItem={setSection} />
        ))}
      </KGrid.Container>
    </KContainer.Card>
  );
};

export default memo(Freight);
