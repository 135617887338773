import { set } from 'lodash';

import {
  AdviceType,
  ARInvoiceStatus,
  ENDPOINTS,
  INVOICE_INCLUDE_FIELDS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';
import { Prototype } from 'core';

import { usePageCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useCUDAdvice = (adviceType: AdviceType, method?: 'DELETE') => {
  const isPayment = adviceType === AdviceType.Payment;

  return usePageCUDMutationEnhancer<any>({
    endPoint: isPayment
      ? ENDPOINTS.paymentAdvice()
      : ENDPOINTS.receivableAdvice(),
    webTable: isPayment
      ? WebTable.ACCOUNTING_PAYMENT_ADVICE
      : WebTable.ACCOUNTING_RECEIVABLE_ADVICE,
    mutationKeys: method ? [] : [[QUERY_KEYS.accountingAdvice]]
  });
};

export const useFetchAdvice = (
  adviceType: AdviceType,
  id?: string | number
) => {
  const isPayment = adviceType === AdviceType.Payment;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.accountingAdvice, adviceType, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: isPayment
          ? ENDPOINTS.paymentAdvice(':id', { id })
          : ENDPOINTS.receivableAdvice(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchAdviceInvoiceList = (
  adviceType: AdviceType,
  data?: any
) => {
  const isPayment = adviceType === AdviceType.Payment;

  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.invoiceAdvice, adviceType, JSON.stringify(data)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: isPayment ? ENDPOINTS.apInvoice2() : ENDPOINTS.arInvoice2(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          includeFields: INVOICE_INCLUDE_FIELDS,
          isAdviceCreating: true,
          ...data
        }
      });

      return (res.data?.data ?? []).map((i: any) => {
        const invoiceStatus = {
          ...(isPayment ? i.apInvoiceStatus : i.arInvoiceStatus)
        };

        const now = Prototype.date.now().startOf('day');
        const dueDate = Prototype.date
          .toMoment(i.dueDate, 'YYYY-MM-DD')
          ?.startOf('day');

        const isOverdue =
          dueDate?.isBefore(now) &&
          invoiceStatus?.code !== ARInvoiceStatus.COMPLETE;

        if (isOverdue) {
          set(invoiceStatus, 'name', 'OVERDUE');
        }

        return {
          ...i,
          checked: false,
          amount: 0,
          invoiceStatus
        };
      });
    }
  });
};
