import React, { useCallback, memo, useMemo } from 'react';
import { KContainer, KLabel } from 'uikit';

import { ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { useDataTable, useGlobalTable } from 'hooks';
import trans from 'translation';
import { TableUtils } from 'utils';

import {
  onShowTermsOfCondition,
  useDeleteTermsOfConditionHelper
} from '../../Setting/helpers';

interface IProps {
  branchId: string | number;
}

const TermsOfCondition = ({ branchId }: IProps) => {
  const { onDelete, deleteLoading } = useDeleteTermsOfConditionHelper();

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => onShowTermsOfCondition(branchId, item)
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete?.([item])
            }
          ])
        },
        {
          label: trans('status'),
          name: 'status',
          options: TableUtils.options.status()
        },
        {
          label: trans('code'),
          name: 'code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data?.[rowData.rowIndex];
                    onShowTermsOfCondition(branchId, item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('name'),
          name: 'name'
        },
        {
          label: trans('category'),
          name: 'termsCategory.name'
        },
        {
          label: trans('air'),
          name: 'isAir',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('ocean'),
          name: 'isOcean',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('is_system'),
          name: 'isSystem',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('type'),
          name: 'type'
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('updated_by'),
          name: 'updatedUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        },
        {
          label: trans('updated_at'),
          name: 'updatedAt'
        }
      ];
    },
    [branchId, onDelete]
  );

  const otherParams = useMemo(() => {
    return {
      branchId,
      includeFields: ['termsCategory']
    };
  }, [branchId]);

  const mappedFields = useMemo(() => {
    return {
      'termsCategory.name': 'termsCategory'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.TERMS_OF_CONDITION,
    tableName: TableName.TERMS_OF_CONDITION,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: ENDPOINTS.termsOfConditions(),
    otherParams,
    mappedFields,
    columnsFunc
  });

  useGlobalTable(table);

  return (
    <>
      <KContainer.CardWithAccordion
        header={{
          icon: 'ErrorOutlineOutlined',
          title: trans('terms_of_condition')
        }}
      >
        <DataTable
          {...table}
          onAdd={() => onShowTermsOfCondition(branchId)}
          onDelete={onDelete}
          isModifying={deleteLoading}
        />
      </KContainer.CardWithAccordion>
    </>
  );
};

export default memo(TermsOfCondition);
