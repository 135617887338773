import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { clone } from 'lodash';
import React, { memo, useMemo, useCallback } from 'react';

import { KColors } from '../../constants';
import KContainer from '../Container';
import KLabel from '../Label';
import { KRadioProps } from '../types';
import { TypoHelper } from '../Typography';

const KRadio = (props: KRadioProps) => {
  const {
    activeColor,
    typo,
    checked,
    label,
    containerStyle,
    disabled,
    onChange,
    onAfterChange,
    size = 18,
    ...rest
  } = props;

  const fontSize = useMemo(() => TypoHelper.getKFontSizeByTypo(typo), [typo]);

  const toggle = useCallback(() => {
    const currentChecked = clone(checked);
    onChange?.(!currentChecked);
    onAfterChange?.(!currentChecked);
  }, [checked, onChange, onAfterChange]);

  const renderCheckbox = useCallback(() => {
    if (checked) {
      return (
        <RadioButtonChecked
          sx={{ width: size, color: activeColor, opacity: disabled ? 0.3 : 1 }}
        />
      );
    } else {
      return (
        <RadioButtonUnchecked
          sx={{ width: size, opacity: disabled ? 0.3 : 1 }}
        />
      );
    }
  }, [activeColor, checked, disabled, size]);

  const Wrapper = disabled ? KContainer.View : KContainer.Touchable;

  return (
    <Wrapper onPress={toggle} row alignItems style={containerStyle} {...rest}>
      {renderCheckbox()}

      {label && (
        <KLabel.Text
          typo={typo}
          // @ts-ignore
          marginL={fontSize * 0.5}
          style={{ lineHeight: 1.5 }}
        >
          {label}
        </KLabel.Text>
      )}
    </Wrapper>
  );
};

KRadio.defaultProps = {
  activeColor: KColors.primary.normal,
  typo: 'TextNmNormal'
};

export default memo(KRadio);
