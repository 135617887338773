import Grid, { GridProps } from '@mui/material/Grid';
import React, { memo, useMemo } from 'react';

interface Props extends GridProps {
  noPadding?: boolean;
}

const KGridContainer = (props: Props) => {
  const { noPadding, style, ...otherProps } = props;

  const commonStyle = useMemo(() => {
    const result = {
      width: 'unset',
      padding: '0 12px'
    };
    if (noPadding) {
      result.padding = '0';
    }
    return { ...result, ...style };
  }, [noPadding, style]);

  return <Grid {...otherProps} style={commonStyle} />;
};

KGridContainer.defaultProps = {
  noPadding: true,
  container: true,
  spacing: 3,
  alignItems: 'center'
};

KGridContainer.displayName = 'KGrid.Container';

export default memo(KGridContainer);
