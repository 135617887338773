import {
  IModifyBranchContact,
  IModifyCompanyGeneral,
  IModifyBankAccount
} from '@request-dto';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchCompany = () => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.companyProfile],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.companyProfile(),
        body: {
          includeFields: ['headBranch']
        }
      });

      return res.data;
    }
  });
};

export const useEditMyCompany = () => {
  return useMutationEnhancer<IModifyCompanyGeneral>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.companyProfile(),
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.data;
    }
  });
};

export const useFetchBranchCompany = (branchId?: number | 'new') => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.companyProfile, branchId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.branch(':id', { id: branchId })
      });

      return res.data;
    },
    enabled: branchId !== 'new' && !!branchId
  });
};

export const useBranchId = () => {
  const { data: branchId, ...rest } = useQuery<
    number | undefined | 'new',
    number | null | 'new'
  >({
    initialData: undefined,
    queryKey: [QUERY_KEYS.branchId],
    enabled: false
  });

  const queryClient = useQueryClient();

  const setBranchId = useCallback(
    (id: number | 'new' | null) => {
      queryClient.setQueryData([QUERY_KEYS.branchId], id);
    },
    [queryClient]
  );

  return {
    branchId,
    setBranchId,
    ...rest
  };
};

export const useCUDBranch = () => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.branch(),
    webTable: WebTable.COMPANY_BRANCH,
    dependentWebTable: [WebTable.BRANCH_CONTACT],
    returnedData: true
  });
};

export const useCUDBranchContact = () => {
  return useCUDMutationEnhancer<IModifyBranchContact>({
    endPoint: ENDPOINTS.branchContact(),
    webTable: WebTable.BRANCH_CONTACT
  });
};

export const useCUDCompanyBankAccount = () => {
  return useCUDMutationEnhancer<IModifyBankAccount>({
    endPoint: ENDPOINTS.accountingCompanyBankAccount(),
    webTable: WebTable.ACCOUNTING_BANK_ACCOUNT
  });
};
