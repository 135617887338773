import React, { useCallback, memo, useMemo } from 'react';

import { ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { useDataTable, useGlobalTable } from 'hooks';
import trans from 'translation';
import { KColors, KContainer, KLabel } from 'uikit';
import { TableUtils } from 'utils';

import {
  onShowQuotationTemplate,
  useDeleteQuotationTemplateHelper
} from '../../helpers';

const QuotationTemplate = ({ source }: { source: 'quotation' | 'invoice' }) => {
  const { onDelete, deleteLoading } = useDeleteQuotationTemplateHelper(source);

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => {
            const mActions = [
              {
                title: trans('edit'),
                icon: 'EditOutlined',
                onPress: () => onShowQuotationTemplate(source, item)
              }
            ];

            if (!item?.isSystem) {
              mActions.push({
                title: trans('delete'),
                icon: 'Delete',
                onPress: () => onDelete?.([item])
              });
            }

            return mActions as any;
          })
        },
        {
          label: trans('code'),
          name: 'code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data?.[rowData.rowIndex];
                    onShowQuotationTemplate(source, item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('name'),
          name: 'name'
        },
        {
          label: trans('description'),
          name: 'description'
        },
        {
          label: trans('remark'),
          name: 'remark'
        },
        {
          label: trans('air'),
          name: 'isAir',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('ocean'),
          name: 'isOcean',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('charter'),
          name: 'isCharter',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('linked'),
          name: 'isLinked',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('dangerous'),
          name: 'isDangerous',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('refrigerated'),
          name: 'isRefrigerated',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('type'),
          name: 'type',
          options: {
            sort: false,
            customBodyRenderLite: (index: number) => {
              const item = data?.[index];
              if (item) {
                const { isImport, isExport, isDomestic } = item;
                return (
                  <KContainer.View row alignItems gap="0.25rem">
                    <KContainer.RenderWhen limit={3}>
                      <KContainer.RenderWhen.If isTrue={isImport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={KColors.palette.blue.w50}
                          brW={1}
                          brC={KColors.palette.blue.w600}
                          width="fit-content"
                          br="x"
                        >
                          <KLabel.Text
                            textTransform="uppercase"
                            color={KColors.palette.blue.w600}
                          >
                            {trans('import')}
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isExport}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={KColors.palette.blue.w50}
                          brW={1}
                          brC={KColors.palette.blue.w600}
                          width="fit-content"
                          br="x"
                        >
                          <KLabel.Text
                            textTransform="uppercase"
                            color={KColors.palette.blue.w600}
                          >
                            {trans('export')}
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>

                      <KContainer.RenderWhen.If isTrue={isDomestic}>
                        <KContainer.View
                          paddingH="0.25rem"
                          background={KColors.palette.blue.w50}
                          brW={1}
                          brC={KColors.palette.blue.w600}
                          width="fit-content"
                          br="x"
                        >
                          <KLabel.Text
                            textTransform="uppercase"
                            color={KColors.palette.blue.w600}
                          >
                            {trans('domestic')}
                          </KLabel.Text>
                        </KContainer.View>
                      </KContainer.RenderWhen.If>
                    </KContainer.RenderWhen>
                  </KContainer.View>
                );
              }
              return null;
            }
          }
        },
        {
          label: trans('charge_code_list'),
          name: 'chargeCodes',
          options: {
            sort: false,
            ...TableUtils.options.withMoreIcon({
              key: 'code'
            })
          }
        },
        {
          label: trans('is_system'),
          name: 'isSystem',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('status'),
          name: 'status',
          options: TableUtils.options.status()
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('updated_by'),
          name: 'updatedUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        },
        {
          label: trans('updated_at'),
          name: 'updatedAt'
        }
      ];
    },
    [onDelete, source]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: ['chargeCodes', 'currency']
    };
  }, []);

  const table = useDataTable({
    name: WebTable.QUOTATION_TEMPLATE,
    tableName: TableName.QUOTATION_TEMPLATE,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: ENDPOINTS.quotationTemplate(source),
    otherParams,
    columnsFunc
  });

  useGlobalTable(table);

  return (
    <KContainer.CardWithAccordion
      header={{
        icon: 'ErrorOutlineOutlined',
        title: trans(`${source}_template`)
      }}
      marginT={source === 'quotation' ? 0 : undefined}
    >
      <DataTable
        {...table}
        onAdd={() => onShowQuotationTemplate(source)}
        onDelete={onDelete}
        isModifying={deleteLoading}
      />
    </KContainer.CardWithAccordion>
  );
};

export default memo(QuotationTemplate);
