interface IDownloadFile {
  contentType: string;
  fileName: string;
  base64: string;
}

export default class DeviceUtils {
  static readonly downloadFile = ({
    contentType,
    fileName,
    base64
  }: IDownloadFile) => {
    const a = document.createElement('a'); // Create tag <a>
    a.href = `data:${contentType};base64,${base64}`; // File with base64 goes here
    a.download = fileName; // File name here
    a.click(); // Downloaded file
    a.remove();
  };
}
