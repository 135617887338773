import React, { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Body from './Breadcrumbs.Body';

import { KBreadcrumbsProps } from '../types';

const KBreadcrumbs = (props: KBreadcrumbsProps) => {
  return (
    <BrowserRouter>
      <Body {...props} />
    </BrowserRouter>
  );
};

export default memo(KBreadcrumbs);
