import { debounce } from 'lodash';
import React, {
  CSSProperties,
  forwardRef,
  memo,
  useCallback,
  useMemo
} from 'react';

import { KTouchableProps } from '../types';
import { TypoHelper } from '../Typography';

const KTouchable = forwardRef<HTMLDivElement, KTouchableProps>((props, ref) => {
  const { onPress, avoidParentPress, ...otherProps } = props;

  const { innerProps, innerStyle } = useMemo(() => {
    const { style, ...rest } = otherProps;
    const { mStyle, mProps } = TypoHelper.destructPropsToStyle(rest);
    const mergeStyles = {
      ...(rest?.disabled || !onPress ? undefined : touchableStyle),
      ...style,
      ...mStyle.layout,
      ...mStyle.spacing,
      ...mStyle.styling
    };

    return {
      innerProps: mProps,
      innerStyle: mergeStyles
    };
  }, [onPress, otherProps]);

  const onPressWrapper = useCallback(
    (e?: any) => {
      if (!innerProps?.disabled) {
        if (avoidParentPress) {
          (
            e as React.MouseEvent<HTMLDivElement, MouseEvent>
          )?.stopPropagation?.();
        }
        onPress?.(e);
      }
    },
    [avoidParentPress, innerProps?.disabled, onPress]
  );

  return (
    <div
      {...innerProps}
      onClick={debounce(onPressWrapper, 300, {
        leading: true,
        trailing: false
      })}
      style={innerStyle}
      ref={ref}
    />
  );
});

export default memo(KTouchable);

const touchableStyle: CSSProperties = {
  cursor: 'pointer'
};
