import { SearchCondition } from '@dto';
import moment from 'moment-timezone';

import { OperandTypes, Operators } from '@constants';
import { Prototype } from 'core';
import trans from 'translation';

export const noActionColumns = () => [' ', trans('action'), trans('download')];

export const normalizeFilterData = (v: any[]): SearchCondition[] => {
  const result = v.map(i => {
    // const r = omit(i, ['min', 'max', 'minDate', 'maxDate']);
    let rData = [Operators.BETWEEN, Operators.IN].includes(i.operatorType)
      ? undefined
      : i.data;
    let rDatas = [Operators.BETWEEN, Operators.IN].includes(i.operatorType)
      ? [OperandTypes.DECIMAL, OperandTypes.INTEGER].includes(i.operandType)
        ? [i.min, i.max]
        : [
              OperandTypes.DATE,
              OperandTypes.DATETIME,
              OperandTypes.TIME
            ].includes(i.operandType)
          ? [i.minDate, i.maxDate]
          : i.data.split(',').map((d: string) => d.trim())
      : undefined;
    if (rData) {
      rData =
        i.operandType === OperandTypes.DATE
          ? Prototype.date.formatDB(rData)
          : [OperandTypes.DATETIME, OperandTypes.TIME].includes(i.operandType)
            ? moment(rData)
            : i.operandType === OperandTypes.BOOLEAN
              ? rData === 'YES'
              : rData;
    }
    if (rDatas) {
      rDatas =
        i.operandType === OperandTypes.DATE
          ? rDatas.map((d: any) => {
              return Prototype.date.formatDB(d);
            })
          : [OperandTypes.DATETIME, OperandTypes.TIME].includes(i.operandType)
            ? rDatas.map((d: any) => {
                return d ? moment(d) : null;
              })
            : rDatas;
    }
    return { ...i, data: rData, datas: rDatas };
  });

  return result;
};
