import { isArray, isEmpty } from 'lodash';
import React, { memo, useCallback, useState } from 'react';

import {
  useDownloadSampleFile,
  useImportExchangeRate,
  useValidateImportedFile
} from 'hooks';
import trans from 'translation';
import { KButton, KContainer, KForm, KInput } from 'uikit';

import ErrorList from './Import.ErrorList';

const Import = () => {
  const { mutate, isLoading } = useImportExchangeRate();
  const { mutate: mutateValidate, isLoading: validateLoading } =
    useValidateImportedFile();
  const { mutate: mutateDownload, isLoading: downloadLoading } =
    useDownloadSampleFile();

  const [file, setFile] = useState<any>(undefined);
  const [data, setData] = useState<any[]>([]);

  const onChange = useCallback((files: any[], onSuccess: any) => {
    if (isArray(files)) {
      if (!isEmpty(files)) {
        const f = files[0];
        setFile(f);
      } else {
        setFile(undefined);
        setData([]);
      }
      onSuccess?.(files);
    }
  }, []);

  const handleSubmit = useCallback(() => {
    const formData = new FormData();
    formData.append('file', file);
    mutateValidate(formData, {
      onSuccess: _data => {
        if (_data?.length > 0) {
          setData(_data);
        } else {
          mutate(formData);
        }
      }
    });
  }, [file, mutate, mutateValidate]);

  const onDownload = useCallback(() => {
    mutateDownload({ importTemplateType: 'EXCHANGE_RATE' });
  }, [mutateDownload]);

  return (
    <KForm>
      <KInput.Dropzone
        maxFiles={1}
        onChange={onChange}
        accept={{
          ['application/*']: ['.xls', '.xlsx']
        }}
      />

      <KContainer.RenderWhen>
        <KContainer.RenderWhen.If isTrue={data.length > 0}>
          <ErrorList data={data} />
        </KContainer.RenderWhen.If>
      </KContainer.RenderWhen>

      <KContainer.View
        row
        alignItems
        justifyContent="flex-end"
        marginT="0.75rem"
      >
        <KButton.Outline
          icon="FileDownloadOutlined"
          kind="primary"
          title={trans('download_sample_file')}
          hasShadow
          marginR="0.75rem"
          isLoading={downloadLoading}
          onPress={onDownload}
        />

        <KButton.Solid
          icon="Save"
          onPress={handleSubmit}
          isLoading={isLoading || validateLoading}
          disabled={!file || data.length > 0}
          title={trans('submit')}
        />
      </KContainer.View>
    </KForm>
  );
};

export default memo(Import);
