import { IAddress, IContact } from '@dto';

import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  Status,
  WebTable
} from '@constants';
import APIManager from '@services';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchAddress = (id?: string | number, addressId?: number) => {
  return useQueryEnhancer<IAddress | undefined>({
    queryKey: [QUERY_KEYS.address, id, addressId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.client(':id/client-address/:addressId', {
          id,
          addressId
        })
      });

      return res.data;
    },
    enabled: !!id && !!addressId
  });
};

export const useFetchAddressCapabilityList = () => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.addressCapabilityList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.addressCapability(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC',
          status: Status.Active
        }
      });

      return res.data?.data;
    }
  });
};

export const useCUDAddress = (id?: string | number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(':id/client-address', { id }),
    webTable: WebTable.CLIENT_ADDRESS,
    dependentWebTable: [WebTable.CLIENT_ADDRESS_CONTACT],
    returnedData: true
  });
};

export const useFetchContact = (id?: number, addressId?: number) => {
  return useQueryEnhancer<IContact | undefined>({
    queryKey: [QUERY_KEYS.contact, id, addressId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientAddress(':id/contact/:contactId', {
          id: addressId,
          contactId: id
        })
      });

      return res.data;
    },
    enabled: !!id && !!addressId
  });
};

export const useCUDContact = (addressId?: number) => {
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.clientAddress(':id/contact', {
      id: addressId
    }),
    webTable: WebTable.CLIENT_ADDRESS_CONTACT
  });
};
