/* eslint-disable max-lines */
import React, { useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { KForm, KGrid, KInput, KPicker } from 'uikit';
import * as yup from 'yup';

import { ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useCUDBankTransaction, useResolverForm } from 'hooks';
import trans from 'translation';
import { ValidationUtils } from 'utils';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

export default function Form() {
  const localUser = StorageEnhance.get(STORAGE_KEYS.user);

  const {
    createMutation: { mutate: createMutation },
    modifyLoading
  } = useCUDBankTransaction();

  const methods = useResolverForm({
    schema: yup.object().shape(
      {
        code: ValidationUtils.required(),
        timestamp: ValidationUtils.requiredDate(),
        withdraw: yup
          .number()
          .required(trans('validation.must_be_at_least_a_deposit_or_withdraw'))
          .when('deposit', {
            is: (deposit: any) => deposit,
            then: schema => schema.notRequired()
          }),
        deposit: yup
          .number()
          .required(trans('validation.must_be_at_least_a_deposit_or_withdraw'))
          .when('withdraw', {
            is: (withdraw: any) => withdraw,
            then: schema => schema.notRequired()
          })
      },
      [['deposit', 'withdraw']]
    ),
    configs: {
      values: {
        companyBank: null,
        code: '',
        description: '',
        timestamp: '',
        withdraw: null,
        deposit: null,
        balance: null
      }
    }
  });

  const [companyBank, deposit, withdraw] = useWatch({
    control: methods.control,
    name: ['companyBank', 'deposit', 'withdraw']
  });

  const onFormValid = useCallback(
    ({ companyBank: _companyBank, ...data }: any) => {
      createMutation({
        ...data,
        timestamp: Prototype.date.toMoment(data.timestamp ?? ''),
        companyBankId: _companyBank?.id
      });
    },
    [createMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="companyBank"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  getOptionLabel={(o: any) => o?.bankName ?? ''}
                  apiURL={ENDPOINTS.companyBankAccount()}
                  apiParams={{
                    branchId: localUser?.branch?.id
                  }}
                  label={trans('bank_name')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="companyBank"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  getOptionLabel={(o: any) => o?.accountNumber ?? ''}
                  apiURL={ENDPOINTS.companyBankAccount()}
                  apiParams={{
                    branchId: localUser?.branch?.id
                  }}
                  label={trans('account_number')}
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={3}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('transaction_number')}
                  disabled={!companyBank}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={3}>
          <Controller
            name="description"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('description')}
                  disabled={!companyBank}
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={3}>
          <Controller
            name="timestamp"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KPicker.DateTime
                  {...field}
                  label={trans('timestamp')}
                  disabled={!companyBank}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="deposit"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Currency
                  {...field}
                  label={trans('deposit')}
                  disabled={!companyBank || withdraw}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={3}>
          <Controller
            name="withdraw"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Currency
                  {...field}
                  label={trans('withdraw')}
                  disabled={!companyBank || deposit}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={3}>
          <KInput.Currency
            name="balance"
            label={trans('balance')}
            value={withdraw || deposit}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>
      <BottomActions
        btnProps={[
          {
            title: 'Save',
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
}
