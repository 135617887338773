import React, { useCallback, memo } from 'react';

import { ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import {
  useAlertMutationEnhancer,
  useCUDClientType,
  useDataTable,
  useGlobalTable
} from 'hooks';
import trans from 'translation';
import { KContainer, KLabel } from 'uikit';
import { TableUtils, UIUtils } from 'utils';

import TypeForm from './Form';

const Type = () => {
  const { deleteMutation } = useCUDClientType();

  const { onDelete, isLoading } = useAlertMutationEnhancer({
    mutation: deleteMutation
  });

  const showPopup = useCallback((item?: any) => {
    UIUtils.popup.open({
      title: trans('client_type'),
      maxWidth: 'md',
      content: () => <TypeForm item={item} />
    });
  }, []);

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => showPopup(item)
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete([item])
            }
          ])
        },
        {
          label: trans('code'),
          name: 'code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data?.[rowData.rowIndex];
                    showPopup(item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('name'),
          name: 'name'
        },
        {
          label: trans('description'),
          name: 'description'
        },
        {
          label: trans('display_order'),
          name: 'displayPosition'
        },
        {
          label: trans('is_system'),
          name: 'isSystem',
          options: TableUtils.options.iconCheckCircle()
        },
        {
          label: trans('status'),
          name: 'status',
          options: TableUtils.options.status()
        },
        {
          label: trans('created_by'),
          name: 'createdUsername'
        },
        {
          label: trans('updated_by'),
          name: 'updatedUsername'
        },
        {
          label: trans('created_at'),
          name: 'createdAt'
        },
        {
          label: trans('updated_at'),
          name: 'updatedAt'
        }
      ];
    },
    [onDelete, showPopup]
  );

  const table = useDataTable({
    name: WebTable.CLIENT_TYPE,
    tableName: TableName.CLIENT_TYPE,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: ENDPOINTS.clientType(),
    columnsFunc
  });

  useGlobalTable(table);

  return (
    <KContainer.CardWithAccordion
      header={{
        icon: 'ErrorOutlineOutlined',
        title: trans('client_type')
      }}
      marginT={0}
    >
      <DataTable
        {...table}
        onAdd={() => showPopup()}
        onDelete={onDelete}
        isModifying={isLoading}
      />
    </KContainer.CardWithAccordion>
  );
};

export default memo(Type);
