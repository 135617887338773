import { compact } from 'lodash';
import React, { createRef, memo, useCallback } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  GroupGeneralService,
  GroupServiceCustom,
  GroupServiceTransport,
  UITab
} from '@constants';
import ServiceProvider from 'context/Service';
import {
  useFetchService,
  useNavigateDefaultTab,
  useUITabsWithCallback
} from 'hooks';
import { useServiceBreadcrumbs } from 'hooks/core/useUIBreadcrumbs';
import trans from 'translation';
import { KBreadcrumbs, KColors, KContainer, KTab, KTabInstance } from 'uikit';
import { isNew } from 'utils';

const DetailsLayout = () => {
  const { serviceId, serviceContainerId, arServiceId, apServiceId } =
    useParams();

  const { data: item } = useFetchService(serviceId);

  const navigate = useNavigate();

  const tabsRef = createRef<KTabInstance>();

  const cb = useCallback(() => {
    let _tabs = compact([
      {
        key: UITab.Service,
        label: trans('tab.general'),
        path: '',
        code: [GroupGeneralService.GENERAL_SERVICE_DETAIL_DETAIL]
      },
      {
        key: UITab.ServiceCustoms,
        label: trans('tab.customs'),
        path: 'customs',
        disabled: !item?.isCustoms,
        code: [
          GroupServiceCustom.SERVICE_CUSTOM_DETAIL_DETAIL,
          GroupServiceCustom.SERVICE_CUSTOM_COMMERCIAL_INVOICE_LIST,
          GroupServiceCustom.SERVICE_CUSTOM_COSTING_WIP_LIST,
          GroupServiceCustom.SERVICE_CUSTOM_SELLING_WIP_LIST,
          GroupServiceCustom.SERVICE_CUSTOM_WIP_SUMMARY_DETAIL
        ]
      },
      {
        key: UITab.ServiceTransport,
        label: trans('tab.transport'),
        path: 'transport',
        disabled: !item?.isTransport,
        code: [
          GroupServiceTransport.SERVICE_TRANSPORT_COSTING_WIP_LIST,
          GroupServiceTransport.SERVICE_TRANSPORT_ROUTING_LIST,
          GroupServiceTransport.SERVICE_TRANSPORT_SELLING_WIP_LIST,
          GroupServiceTransport.SERVICE_TRANSPORT_WIP_SUMMARY_DETAIL
        ]
      },
      {
        key: UITab.ServiceContainers,
        path: 'containers-packing',
        label: trans('tab.containers_packing'),
        code:
          isNew(5) || serviceContainerId
            ? [GroupGeneralService.GENERAL_SERVICE_CONTAINER_LIST]
            : [GroupGeneralService.GENERAL_SERVICE_CONTAINER_DETAIL]
      },
      !item?.isLinkedToShipment
        ? {
            key: UITab.ServiceBilling,
            path: 'billing',
            label: trans('tab.billing'),
            code: [
              GroupGeneralService.GENERAL_SERVICE_BILLING_SUMMARY_DETAIL,
              GroupGeneralService.GENERAL_SERVICE_CHARGE_CODE_LIST,
              isNew(6) || arServiceId
                ? GroupGeneralService.GENERAL_SERVICE_AR_INVOICE_DETAIL
                : GroupGeneralService.GENERAL_SERVICE_AR_INVOICE_LIST,
              isNew(6) || apServiceId
                ? GroupGeneralService.GENERAL_SERVICE_AP_INVOICE_DETAIL
                : GroupGeneralService.GENERAL_SERVICE_AP_INVOICE_LIST
            ]
          }
        : undefined,
      {
        key: UITab.ServiceAlertNote,
        label: trans('tab.alert_note'),
        path: 'alert-note',
        code: [GroupGeneralService.GENERAL_SERVICE_ALERT_NOTE_LIST]
      },
      {
        key: UITab.ServiceDocument,
        path: 'document',
        label: trans('tab.document'),
        code: [GroupGeneralService.GENERAL_SERVICE_DOCUMENT_LIST]
      }
    ]);

    if (!serviceId) {
      _tabs = _tabs.map(i => {
        if (i.key === UITab.Service) {
          return i;
        }
        return { ...i, disabled: true };
      }) as any;
    }

    return _tabs;
  }, [
    apServiceId,
    arServiceId,
    item?.isCustoms,
    item?.isLinkedToShipment,
    item?.isTransport,
    serviceContainerId,
    serviceId
  ]);

  const tabs = useUITabsWithCallback(cb);

  useNavigateDefaultTab(tabsRef, tabs, 4);

  const onChangeTab = useCallback(
    (idx: number) => {
      navigate(tabs[idx].path);
    },
    [navigate, tabs]
  );

  const breadcrumbs = useServiceBreadcrumbs();

  return (
    <>
      <ServiceProvider>
        <KBreadcrumbs
          hasBackIcon
          breadcrumbs={breadcrumbs}
          onBack={() => navigate('../')}
        />

        <>
          <KTab.Solid
            ref={tabsRef}
            tabs={tabs}
            onChangeTab={onChangeTab}
            marginT={0}
            background={KColors.white}
            kind="background"
            activeBackground={KColors.primary.normal}
            textColor={KColors.white}
          />

          <KContainer.View paddingH="0.75rem" paddingV="0.25rem" marginB="4rem">
            <Outlet />
          </KContainer.View>
        </>
      </ServiceProvider>
    </>
  );
};

export default memo(DetailsLayout);
