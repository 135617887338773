import { WithAlertDialogProps } from '@ui';
import { set } from 'lodash';
import React, {
  forwardRef,
  memo,
  useCallback,
  useMemo,
  useState,
  useImperativeHandle
} from 'react';

import trans from 'translation';
import {
  KButton,
  KContainer,
  KDivider,
  KLabel,
  KColors,
  KAlertProps,
  KRadiusValue,
  KSpacingValue,
  KImage,
  MIcon
} from 'uikit';

const KAlert = forwardRef<WithAlertDialogProps>((_, ref) => {
  const [data, setData] = useState<KAlertProps | undefined>(undefined);

  const {
    status = 'info',
    touchOutsideToDismiss,
    // title,
    // titleAlignment,
    // titleColor,
    // titleTypo,
    buttons,
    message,
    messageColor,
    messageTypo,
    alignment
  } = data || {};

  const dismiss = useCallback(() => {
    setData(undefined);
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      open: payload => {
        setData(payload);
      },
      dismiss,
      dismissAll: () => {
        setData(undefined);
      }
    }),
    [dismiss]
  );

  const onPressWrapper = useCallback(
    (onPress: any) => {
      onPress?.();
      dismiss();
    },
    [dismiss]
  );

  const options = useMemo(() => {
    let icon: MIcon, title, background, color;
    switch (status) {
      case 'success':
        icon = 'CheckCircleOutline';
        title = trans('success');
        background = '#A8E7DF';
        color = KColors.primary.normal;
        break;

      case 'danger':
        icon = 'ErrorOutline';
        title = trans('error');
        background = '#ED727299';
        color = '#CF0A0A';
        break;

      default:
        icon = 'InfoOutlined';
        title = trans('confirm');
        background = '#B2D0F9';
        color = '#0E4DA4';
        break;
    }

    return {
      icon,
      title,
      color,
      background
    };
  }, [status]);

  const renderHeader = useMemo(() => {
    if (!data) {
      return null;
    }

    return (
      <KContainer.View padding="0.75rem" dp="flex" alignItems>
        <KImage.MuiIcon icon={options.icon} color={options.color} size={40} />

        <KLabel.Paragraph
          typo={'TextNmMedium'}
          color={options.color}
          numberOfLines={2}
          textAlign
          textTransform="uppercase"
          marginT="0.5rem"
        >
          {options.title}
        </KLabel.Paragraph>
      </KContainer.View>
    );
  }, [data, options.icon, options.color, options.title]);

  const renderActions = useMemo(() => {
    if (!data) {
      return null;
    }

    const isButtonEmpty = !buttons || buttons?.length === 0;
    let mButtons = isButtonEmpty
      ? [
          {
            title: 'OK',
            kind: status,
            weight: 'bold'
          }
        ]
      : buttons;

    mButtons = mButtons.map((i: any) => {
      const clone = { ...i };
      delete clone.onPress;
      set(clone, 'onPress', () => onPressWrapper(() => i.onPress?.()));
      return clone;
    });

    return (
      <KContainer.View>
        <KContainer.View
          dp="flex"
          row
          center
          paddingH="0.75rem"
          paddingV="0.5rem"
        >
          {mButtons.map((i: any, index: number) => {
            const firstItem = index === 0;
            const lastItem = index === mButtons.length - 1;

            return (
              <>
                <KButton.Outline
                  key={`button-${index}`}
                  flex
                  brC={i.color || options.color}
                  marginL={firstItem ? 0 : '0.5rem'}
                  marginR={lastItem ? 0 : '0.5rem'}
                  background={KColors.transparent}
                  textColor={i.color}
                  {...i}
                />

                <KContainer.View height={34}>
                  {!lastItem && (
                    <KDivider type="line" size="hairline" vertical />
                  )}
                </KContainer.View>
              </>
            );
          })}
        </KContainer.View>
      </KContainer.View>
    );
  }, [buttons, data, onPressWrapper, options.color, status]);

  const renderBody = useMemo(() => {
    if (!data) {
      return null;
    }

    return (
      <KContainer.View>
        {typeof message === 'string' ? (
          <KContainer.View padding={'0.75rem'} dp="flex" center>
            <KLabel.Text
              typo={messageTypo || 'TextMdNormal'}
              color={messageColor}
              textAlign
            >
              {message}
            </KLabel.Text>
          </KContainer.View>
        ) : (
          message?.(dismiss)
        )}
      </KContainer.View>
    );
  }, [data, message, messageTypo, messageColor, dismiss]);

  if (!data) {
    return null;
  }

  return (
    <KContainer.Touchable onPress={touchOutsideToDismiss ? dismiss : undefined}>
      <KContainer.View
        style={styles.wrapper}
        dp="flex"
        flex
        alignItems
        justifyContent={alignment === 'top' ? undefined : 'center'}
      >
        <KContainer.View
          brC={options.color}
          brW={1}
          background={options.background}
          minW={200}
          style={styles.content}
        >
          {renderHeader}
          {renderBody}
          {renderActions}
        </KContainer.View>
      </KContainer.View>
    </KContainer.Touchable>
  );
});

export default memo(KAlert);

const styles: { [key: string]: React.CSSProperties } = {
  wrapper: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    // backgroundColor: KColors.overlay,
    zIndex: 1350,
    padding: KSpacingValue['2rem']
  },
  content: {
    borderRadius: KRadiusValue['2x'],
    overflow: 'hidden',
    maxWidth: 300
  }
};
