import { NoteOptions } from '@ui';
import { kebabCase } from 'lodash';

import { ArApType, ENDPOINTS, SourceType } from '@constants';
import APIManager from '@services';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchEmailList = (options: {
  source: SourceType;
  queryKey: string;
  parentId?: number | string;
}) => {
  const { source, parentId, queryKey } = options;
  return useQueryEnhancer<any>({
    queryKey: [queryKey, parentId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.email('', {
          source: source.toLowerCase(),
          id: parentId
        })
      });
      return res?.data?.data ?? null;
    },
    enabled: !!parentId
  });
};

export const useCUDEmail = (options: NoteOptions) => {
  const { source, parentId, ...rest } = options;
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.email('', {
      source: kebabCase(source).toLowerCase(),
      id: parentId
    }),
    ...rest
  });
};

export const useRenderTemplate = () => {
  return useMutationEnhancer<
    BlobPart,
    {
      parentSourceType: SourceType;
      sourceType: ArApType;
      parentSourceId: any;
      sourceIds: any;
      templateId: any;
      clientId: any;
      contactId: any;
      extraData?: {
        selectedDate: any;
      };
      renderFileExtension: 'PDF' | 'DOCX';
    }
  >({
    mutationFn: async body => {
      const res = await APIManager.request({
        url: ENDPOINTS.template('render'),
        method: 'POST',
        body,
        configs: {
          responseType: 'blob'
        }
      });
      return res.data;
    },
    onSuccess: (data, { renderFileExtension }) => {
      const type =
        renderFileExtension === 'PDF'
          ? 'application/pdf'
          : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      if (data) {
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type
          })
        );
        window.open(url);
      }
    }
  });
};
