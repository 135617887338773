import { IMetadata } from '@dto';
import { IItemProps } from '@ui';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { Status, generateOptions, ClientType, ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDFlight } from 'hooks';
import trans from 'translation';
import { KGrid, KInput, KListItem, KForm } from 'uikit';
import { ValidationUtils } from 'utils';

import { useDeleteFlightsHelper } from '../../helpers';

interface IFormData {
  code: string;
  aircraftType: string;
  aircraftRegistrator: string;
  status: Status;
  operatorAirline: IMetadata | null;
}

const schema = yup.object().shape({
  code: ValidationUtils.required()
});

const FlightForm = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDFlight();

  const { onDelete, deleteLoading } = useDeleteFlightsHelper();

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? '',
        aircraftType: item?.aircraftType ?? '',
        aircraftRegistrator: item?.aircraftRegistrator ?? '',
        operatorAirline: item?.operatorAirline,
        status: item?.status ?? Status.Active
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams = {
        ...data,
        id: item?.id,
        operatorAirlineId: data.operatorAirline?.id
      };

      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, item?.id, updateMutation]
  );

  const apiParams = useMemo(
    () => ({
      clientTypeCodes: [ClientType.Airline]
    }),
    []
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={2.4}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('flight')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="operatorAirline"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('operator_airline')}
                  inputProps={{
                    message: error?.message
                  }}
                  apiURL={ENDPOINTS.client()}
                  apiParams={apiParams}
                  hasEdit
                  editURL="client"
                  hasAddNew
                  addNewURL="client"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="aircraftType"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  disabled={item?.isSystem}
                  label={trans('aircraft_type')}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="aircraftRegistrator"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  disabled={item?.isSystem}
                  label={trans('aircraft_register')}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KListItem.Radio
            name="isSystem"
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            containerStyle={{ marginTop: '0.75rem' }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            disabled
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            isLoading: deleteLoading,
            disabled: item?.isSystem
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(FlightForm);
