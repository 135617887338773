import { Appearance } from '@ui';
import React, {
  createContext,
  useCallback,
  useContext,
  useReducer
} from 'react';

import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

type Action = {
  type: string;
  payload?: any;
};

interface State {
  appearance: Appearance;
  language: 'en' | 'vi';
  miniDrawer: boolean;
}

interface TPreferences {
  state: State;
  toggleAppearance: () => void;
  toggleLanguage: () => void;
  toggleDrawer: () => void;
}

const initState: State = {
  appearance: 'light',
  language: 'vi',
  miniDrawer: false
};

// @ts-ignore
const PreferencesContext = createContext<TPreferences>();

const reducer = (state: State, action: Action) => {
  let newState: State;
  switch (action.type) {
    case 'toggleAppearance':
      const appearance = state?.appearance === 'light' ? 'dark' : 'light';
      newState = { ...state, appearance };
      StorageEnhance.set(STORAGE_KEYS.preferences, newState);
      return newState;

    case 'toggleLanguage':
      const language = state?.language === 'en' ? 'vi' : 'en';
      newState = { ...state, language };
      StorageEnhance.set(STORAGE_KEYS.preferences, newState);
      return newState;

    case 'toggleDrawer':
      return { ...state, miniDrawer: !state.miniDrawer };

    default:
      return state;
  }
};

const PreferencesProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const toggleAppearance = useCallback(() => {
    dispatch({
      type: 'toggleAppearance'
    });
  }, []);

  const toggleLanguage = useCallback(() => {
    dispatch({
      type: 'toggleLanguage'
    });
  }, []);

  const toggleDrawer = useCallback(() => {
    dispatch({
      type: 'toggleDrawer'
    });
  }, []);

  return (
    <PreferencesContext.Provider
      value={{ state, toggleAppearance, toggleLanguage, toggleDrawer }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

const usePreferences = (): TPreferences => useContext(PreferencesContext);

export { PreferencesContext, PreferencesProvider, usePreferences };
