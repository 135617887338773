import { PaletteOptions } from '@mui/material';

const baseColors = {
  transparent: 'transparent',
  background: '#F5F5F5',
  backgroundDisabled: '#D8D8D8',
  black: '#263238',
  white: '#fff',
  primary: '#13A89E',
  secondary: '#F3746B',
  success: '#13A89E',
  warning: '#D97706',
  info: '#0E4DA4',
  error: '#CF0A0A',
  disabled: '#666667'
};

const colors: PaletteOptions = {
  background: {
    default: baseColors.background
  },

  text: {
    disabled: baseColors.disabled,
    primary: baseColors.black
    // secondary: '#CF0A0AA1'
  },

  common: {
    black: baseColors.black,
    white: baseColors.white
  },

  primary: {
    main: baseColors.primary
  },

  secondary: {
    main: baseColors.secondary
  },

  info: {
    main: baseColors.info
  },

  success: {
    main: baseColors.success
  },

  warning: {
    main: baseColors.warning
  },

  error: {
    main: baseColors.error
  },

  grey: {
    100: '#f8f9fa',
    200: '#f0f2f5',
    300: '#dee2e6',
    400: '#ced4da',
    500: '#adb5bd',
    600: '#6c757d',
    700: '#495057',
    800: '#343a40',
    900: '#212529'
  }
};

export { baseColors };

export default colors;
