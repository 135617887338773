import React, { Fragment, useCallback, useMemo } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form';
import {
  KColors,
  KContainer,
  KGrid,
  KImage,
  KInput,
  KLabel,
  KListItem
} from 'uikit';

import { ENDPOINTS, FreeDayExport, generateOptions } from '@constants';
import trans from 'translation';
import { AutocompleteUtils } from 'utils';

import { listIdPort, newPolPod } from './Form';

const Pol = () => {
  const methods = useFormContext<any>();

  const [polToPodPorts, port, polToPodAll] = useWatch({
    control: methods.control,
    name: ['polToPodPorts', 'port', 'polToPodAll']
  });

  const apiParamsPort = useMemo(
    () => ({
      // clientTypeCodes: isOcean ? ClientType.SeaPort : ClientType.AirPort,
      excludeIds: listIdPort(polToPodPorts, port)
    }),
    [polToPodPorts, port]
  );

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'polToPodPorts',
    keyName: 'formId'
  });

  const handleRemoveRowSelectedPod = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const handleAddRowSelectedPod = useCallback(async () => {
    const isValid = await methods.trigger('polToPodPorts');
    if (isValid) {
      append(newPolPod);
    }
  }, [append, methods]);

  const lastRow = useCallback(
    (index: number) => polToPodPorts.length === index,
    [polToPodPorts.length]
  );

  const handlePolToPodAll = useCallback(
    (v: boolean) => {
      methods.setValue('polToPodAll', v);
      if (!v) {
        methods.setValue('polPickupEnd', '');
        methods.setValue('polNoFreeDay', '');
        methods.clearErrors(['polPickupEnd', 'polNoFreeDay']);
      }
    },
    [methods]
  );

  return (
    <KContainer.Card
      size="nm"
      height="100%"
      header={{
        title: trans('as_unloco_port_of_loading'),
        border: true
      }}
    >
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <KLabel.Text>{trans('to_unloco_port_of_discharge')}</KLabel.Text>
        </KGrid.Item>

        <KGrid.Item xs={1}>
          <Controller
            name="polToPodAll"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('all')}
                  checked={field.value}
                  onChange={handlePolToPodAll}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2}></KGrid.Item>

        <KGrid.Item xs={3.5}>
          <Controller
            name="polPickupEnd"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('pol_pick_up_end')}
                  options={generateOptions(FreeDayExport)}
                  required={polToPodAll}
                  disabled={!polToPodAll}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.5}>
          <Controller
            name="polNoFreeDay"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('pol_no_of_free_day')}
                  required={polToPodAll}
                  disabled={!polToPodAll}
                  message={error?.message}
                  type="number"
                />
              );
            }}
          />
        </KGrid.Item>

        {!polToPodAll &&
          fields.map((item: any, index: number) => {
            return (
              <Fragment key={item?.formId}>
                <KGrid.Item xs={6}>
                  <Controller
                    name={`polToPodPorts[${index}].ports`}
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KInput.Autocomplete
                          {...field}
                          label={trans('selected_unloco_port_of_discharge')}
                          apiURL={ENDPOINTS.unloco()}
                          apiParams={apiParamsPort}
                          getOptionLabel={AutocompleteUtils.unlocoFormatter}
                          multiple
                          inputProps={{
                            required: true,
                            message: error?.message
                          }}
                          disabled={!lastRow(index + 1)}
                        />
                      );
                    }}
                  />
                </KGrid.Item>

                <KGrid.Item xs={3}>
                  <Controller
                    name={`polToPodPorts[${index}].pickupEndFreeDayStart`}
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KInput.TextField
                          {...field}
                          label={trans('pol_pick_up_end')}
                          options={generateOptions(FreeDayExport)}
                          message={error?.message}
                          required
                          disabled={!lastRow(index + 1)}
                        />
                      );
                    }}
                  />
                </KGrid.Item>

                <KGrid.Item xs={2.3}>
                  <Controller
                    name={`polToPodPorts[${index}].noOfFreeDay`}
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KInput.TextField
                          {...field}
                          label={trans('pol_no_of_free_day')}
                          type="number"
                          disabled={!lastRow(index + 1)}
                          required
                          message={error?.message}
                        />
                      );
                    }}
                  />
                </KGrid.Item>
                <KGrid.Item xs={0.7} alignSelf={'end'}>
                  {polToPodPorts.length > 1 && (
                    <KImage.MuiIcon
                      cursor={'pointer'}
                      color={KColors.secondary.normal}
                      icon="HighlightOff"
                      onClick={() => {
                        handleRemoveRowSelectedPod(index);
                      }}
                    />
                  )}
                  {lastRow(index + 1) && (
                    <KImage.MuiIcon
                      cursor={'pointer'}
                      color={KColors.primary.normal}
                      icon="AddCircleOutlineOutlined"
                      onClick={handleAddRowSelectedPod}
                    />
                  )}
                </KGrid.Item>
              </Fragment>
            );
          })}
      </KGrid.Container>
    </KContainer.Card>
  );
};

export default Pol;
