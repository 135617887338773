import { IItemProps } from '@ui';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { KGrid, KInput, KListItem, KForm, KLabel, KColors } from 'uikit';
import * as yup from 'yup';

import {
  Status,
  DimensionUnit,
  VolumeUnit,
  WeightUnit,
  OPTION_DIMENSION_UNIT,
  OPTION_WEIGHT_UNIT,
  OPTION_VOLUME_UNIT,
  generateOptions
} from '@constants';
import { BottomActions, CheckBoxError } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDPackageType } from 'hooks';
import trans from 'translation';
import { ValidationUtils } from 'utils';

import { useDeletePackageType } from '../../helpers';

interface FormData {
  code: string;
  name: string;
  maxWeight: number | null;
  maxVolume: number | null;
  height: number;
  width: number;
  length: number;
  isAir: boolean;
  isOcean: boolean;
  status: Status;
  dimensionUnit: DimensionUnit | null;
  weightUnit: WeightUnit | null;
  volumeUnit: VolumeUnit | null;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required(),
  length: ValidationUtils.nullableNum(),
  height: ValidationUtils.nullableNum(),
  width: ValidationUtils.nullableNum(),
  maxWeight: ValidationUtils.requiredNumberNullAble(),
  maxVolume: ValidationUtils.requiredNumberNullAble(),
  isAir: ValidationUtils.requiredCheckBoxAirOcean()
});

const PackageTypeForm = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDPackageType();

  const { onDelete, deleteLoading } = useDeletePackageType();

  const isEdit = !!item;

  const methods = useResolverForm<FormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? '',
        name: item?.name ?? '',
        maxWeight: item?.maxWeight ?? null,
        maxVolume: item?.maxVolume ?? null,
        height: item?.height ?? null,
        width: item?.width ?? null,
        length: item?.length ?? null,
        isAir: item?.isAir ?? true,
        isOcean: item?.isOcean ?? true,
        status: item?.status ?? Status.Active,
        dimensionUnit: item?.dimensionUnit ?? DimensionUnit.M,
        weightUnit: item?.weightUnit ?? WeightUnit.KG,
        volumeUnit: item?.volumeUnit ?? VolumeUnit.M3
      }
    }
  });

  const onFormValid = useCallback(
    (data: FormData) => {
      if (isEdit) {
        updateMutation({
          ...data,
          id: item?.id
        });
      } else {
        createMutation(data);
      }
    },
    [createMutation, isEdit, item?.id, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={4}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('code')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('name')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2} textAlign={'center'}>
          <Controller
            name="isOcean"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('ocean')}
                  checked={field.value}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
          <Controller
            name="isAir"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <KListItem.Checkbox
                    {...field}
                    label={trans('air')}
                    checked={field.value}
                    disabled={item?.isSystem}
                  />
                  <CheckBoxError errMessage={error?.message} />
                </>
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2}>
          <KListItem.Radio
            name="isSystem"
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            marginT="0.75rem"
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KLabel.Text typo={'TextXsMedium'}>
            {trans('with_unit.dimension')}{' '}
          </KLabel.Text>
          <KGrid.Container>
            <KGrid.Item xs={3}>
              <Controller
                name="length"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      placeholder="L"
                      type="number"
                      message={error?.message}
                      disabled={item?.isSystem}
                    />
                  );
                }}
              />
            </KGrid.Item>
            <KGrid.Item xs={3}>
              <Controller
                name="width"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      required
                      placeholder="W"
                      type="number"
                      message={error?.message}
                      disabled={item?.isSystem}
                    />
                  );
                }}
              />
            </KGrid.Item>
            <KGrid.Item xs={3}>
              <Controller
                name="height"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      required
                      placeholder="H"
                      type="number"
                      message={error?.message}
                      disabled={item?.isSystem}
                    />
                  );
                }}
              />
            </KGrid.Item>
            <KGrid.Item xs={3}>
              <Controller
                name="dimensionUnit"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      disabled={item?.isSystem}
                      options={OPTION_DIMENSION_UNIT}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KGrid.Container>
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KLabel.Text typo={'TextXsMedium'}>
            {trans('gross_weight')}{' '}
            <KLabel.Text color={KColors.secondary.normal}>*</KLabel.Text>
          </KLabel.Text>
          <KGrid.Container>
            <KGrid.Item xs={6}>
              <Controller
                name="maxWeight"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      type="number"
                      message={error?.message}
                      disabled={item?.isSystem}
                    />
                  );
                }}
              />
            </KGrid.Item>
            <KGrid.Item xs={6}>
              <Controller
                name="weightUnit"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      disabled={item?.isSystem}
                      options={OPTION_WEIGHT_UNIT}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KGrid.Container>
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KLabel.Text typo={'TextXsMedium'}>
            {trans('gross_volume')}{' '}
            <KLabel.Text color={KColors.secondary.normal}>*</KLabel.Text>
          </KLabel.Text>
          <KGrid.Container>
            <KGrid.Item xs={6}>
              <Controller
                name="maxVolume"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      type="number"
                      message={error?.message}
                      disabled={item?.isSystem}
                    />
                  );
                }}
              />
            </KGrid.Item>
            <KGrid.Item xs={6}>
              <Controller
                name="volumeUnit"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      disabled={item?.isSystem}
                      options={OPTION_VOLUME_UNIT}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KGrid.Container>
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete([item]),
            isLoading: deleteLoading,
            disabled: item?.isSystem
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(PackageTypeForm);
