export default class AutocompleteUtils {
  static addressFormatter = (o?: any) =>
    [o?.client?.name, o?.name].filter(i => i).join(' - ');

  static addressFormatterCode = (o?: any) =>
    [o?.client?.code, o?.code].filter(i => i).join(' - ');

  static unlocoFormatter = (o?: any) =>
    [o?.code, [o?.cityName, o?.countryName].filter(i => i).join(', ')]
      .filter(i => i)
      .join(' - ');
}
