import { NoteOptions } from '@ui';

import { ENDPOINTS, SourceType } from '@constants';

import { useCUDMutationEnhancer } from '../core';

export const useCUDEdoc = (options: NoteOptions) => {
  const { source, parentId, ...rest } = options;
  return useCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.edoc('', {
      source: (source === SourceType.ShipmentWithOutConsol
        ? SourceType.Shipment
        : source.split('_').join('-')
      ).toLowerCase(),
      id: parentId
    }),
    ...rest
  });
};
