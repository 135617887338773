import { omit } from 'lodash';

import { Prototype } from 'core';

const defOmitFields = [
  'createdBy',
  'createdAt',
  'updatedBy',
  'updatedAt',
  'syncDate',
  'syncStatus'
];

export default class RequestUtils {
  static readonly normalizeData = <T extends {}>(options: {
    data: T;
    idFields?: string[];
    upperCaseFields?: string[];
    dateFields?: string[];
    dateTimeFields?: string[];
    timeFields?: string[];
    dateTzFields?: string[];
  }) => {
    const {
      data,
      idFields = [],
      upperCaseFields = [],
      dateFields = [],
      dateTimeFields = [],
      timeFields = [],
      dateTzFields = []
    } = options;

    const idParams = idFields.reduce((acc, cur) => {
      return { ...acc, [`${cur}Id`]: data[cur]?.id };
    }, {});

    const upperCaseParams = upperCaseFields.reduce((acc, cur) => {
      return { ...acc, [cur]: data[cur]?.toUpperCase() };
    }, {});

    const dateParams = dateFields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: data[cur] ? Prototype.date.formatDB(data[cur]) : null
      }),
      {}
    );

    const dateTzParams = dateTzFields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: data[cur]
          ? `${Prototype.date.formatDB(data[cur])}T00:00:00.000Z`
          : null
      }),
      {}
    );

    const dateTimeParams = dateTimeFields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: data[cur] ? Prototype.date.toMoment(data[cur]) : null
      }),
      {}
    );

    const timeParams = timeFields.reduce(
      (acc, cur) => ({
        ...acc,
        [cur]: data[cur] ? Prototype.date.formatT(data[cur]) : null
      }),
      {}
    );

    const mParams = {
      ...omit(data, [
        ...idFields,
        ...dateFields,
        ...dateTimeFields,
        ...timeFields,
        ...upperCaseFields,
        ...defOmitFields,
        ...dateTzFields
      ]),

      ...idParams,
      ...dateParams,
      ...dateTimeParams,
      ...timeParams,
      ...upperCaseParams,
      ...dateTzParams
    };

    return mParams;
  };
}
