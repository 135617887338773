import { BaseResponse, IResourceGroup, IRole } from '@dto';
import { ICreateRoleParams } from '@request-dto';
import { useNavigate } from 'react-router-dom';

import { QUERY_KEYS, Status, WebTable, tableRef } from '@constants';
import APIManager, { END_POINTS } from '@services';

import { useMutationEnhancer, useQueryEnhancer } from '../core';

export const useFetchResourceGroupList = () => {
  return useQueryEnhancer<IResourceGroup[]>({
    queryKey: [QUERY_KEYS.resourceGroupList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: END_POINTS.ROLE.GET_PERMISSION_GROUP_LIST
      });

      return ((res.data?.data ?? []) as IResourceGroup[]).map(i => ({
        ...i,
        isChecked: true,
        permissionResources: i.permissionResources.map(pr => ({
          ...pr,
          isChecked: true,
          permissionTypes: pr.permissionTypes.map(pt => ({
            ...pt,
            isChecked: true,
            permissions: pt.permissions.map(p => ({
              ...p,
              isChecked: true
              // createBy: 'ALL',
              // controlBy: 'ALL',
              // status: p.status || 'ALL',
              // selectedStatus: (p.status || 'ALL').split(',').map(s => ({
              //   id: s,
              //   name: s
              // }))
            }))
          }))
        }))
      }));
    }
  });
};

export const useCheckExistedRoleByName = () => {
  return useMutationEnhancer<
    BaseResponse<boolean>,
    { name: string; id?: number | string }
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: END_POINTS.ROLE.CHECK_EXISTED_ROLE,
        body: data
      });

      return res;
    }
  });
};

export const useCreateRole = () => {
  const navigate = useNavigate();

  return useMutationEnhancer<number | undefined, ICreateRoleParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: END_POINTS.ROLE.GET_ROLE_LIST,
        method: 'POST',
        showToast: true,
        body: data
      });

      return res.data?.id;
    },
    onSuccess: data => {
      if (data) {
        navigate(`../${data}`, { replace: true });
      }
    }
  });
};

export const useFetchRole = (id?: number) => {
  return useQueryEnhancer<IRole>({
    queryKey: [QUERY_KEYS.role, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: `${END_POINTS.ROLE.GET_ROLE_LIST}/${id}`
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useUpdateRole = () => {
  return useMutationEnhancer<number | undefined, ICreateRoleParams>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: `${END_POINTS.ROLE.GET_ROLE_LIST}/${data.id}`,
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.data?.id;
    }
  });
};

export const useDeleteRoles = () => {
  return useMutationEnhancer<boolean | undefined, number[]>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: `${END_POINTS.ROLE.GET_ROLE_LIST}`,
        method: 'DELETE',
        showToast: true,
        body: { ids: data }
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.ROLE_PERMISSION]?.init?.();
      }
    }
  });
};

export const useChangeStatusRole = () => {
  return useMutationEnhancer<
    boolean | undefined,
    { id: number; status: Status }
  >({
    mutationFn: async data => {
      const { id, status } = data;
      const res = await APIManager.request({
        url: `${END_POINTS.ROLE.GET_ROLE_LIST}/${id}`,
        method: 'PATCH',
        showToast: true,
        body: { status }
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.ROLE_PERMISSION]?.init?.();
      }
    }
  });
};
