import Avatar from './Avatar';
import Base from './Base';
import MuiIcon from './MuiIcon';

export default class KImage {
  static Base = Base;

  static Avatar = Avatar;

  static MuiIcon = MuiIcon;
}
