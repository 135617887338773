/* eslint-disable max-lines */
import { IModifyCarrierPolicyNew } from '@request-dto';
import { IItemProps } from '@ui';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { KGrid, KInput, KListItem, KForm, KContainer, KLabel } from 'uikit';
import * as yup from 'yup';

import { Status, generateOptions, ClientType, ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDCarrierPolicy } from 'hooks';
import trans from 'translation';
import { AutocompleteUtils, RequestUtils, ValidationUtils } from 'utils';

import Pod from './Pod';
import Pol from './Pol';

import { useDeleteCarrierPolicyHelper } from '../../helpers';

const stringConcatenate = (arr: any[]) => arr.filter(v => v).join(' - ');

interface INewPol {
  pickupEndFreeDayStart: string;
  noOfFreeDay: number;
  ports: any[];
}

export const newPolPod: INewPol = {
  ports: [],
  pickupEndFreeDayStart: '',
  noOfFreeDay: 0
};

export const listIdPort = (listPolPod: any[], port: any) => {
  const newPorts = listPolPod?.map((v: any) => {
    return v.ports;
  });
  const newListPortsId = [...newPorts.flat(), port].map((v: any) => v?.id);
  return newListPortsId.flat();
};

const CarrierPolicyForm = ({ item }: IItemProps) => {
  const { onDelete, deleteLoading } = useDeleteCarrierPolicyHelper();

  const {
    createMutation: { mutate: createMutation, isLoading: addLoading },
    updateMutation: { mutate: updateMutation, isLoading: updateLoading }
  } = useCUDCarrierPolicy();

  const isEdit = !!item;

  const methods = useResolverForm<IModifyCarrierPolicyNew>({
    schema: yup.object().shape({
      carrier: ValidationUtils.requiredAny(),
      port: ValidationUtils.requiredAny(),
      contTypeSizeIds: yup
        .mixed()
        .test(
          'ContainerTypeSize',
          trans('validation.min_array', { min: 1 }),
          function (v: any) {
            const isContainer = this.parent.containerTypeSizeAll;
            return isContainer || v?.length;
          }
        ),
      polPickupEnd: yup
        .mixed()
        .test(
          'PolPickupEnd',
          trans('validation.required.default'),
          function (v: any) {
            const isCheck = this.parent.polToPodAll;
            return !isCheck || v;
          }
        ),
      polNoFreeDay: yup
        .mixed()
        .test('polNoFreeDay', trans('Invalid format'), function (v: any) {
          const isCheck = this.parent.polToPodAll;
          return !isCheck || (v ?? '') !== '';
        })
        .test(
          'polNoFreeDay>0',
          trans('validation.min_number', { min: 0 }),
          function (v: any) {
            return Number(v ?? '') >= 0;
          }
        ),
      polToPodPorts: yup.array().of(
        yup.object().shape({
          ports: yup
            .mixed()
            .test(
              'polToPodPorts',
              trans('validation.min_array', { min: 1 }),
              function (v: any) {
                const isCheck = this.options?.['from']?.[1]?.value.polToPodAll;
                return isCheck || v?.length;
              }
            ),
          pickupEndFreeDayStart: yup
            .mixed()
            .nullable()
            .test(
              'pickupEndFreeDayStart',
              trans('validation.required.default'),
              function (v: any) {
                const isCheck = this.options?.['from']?.[1]?.value.polToPodAll;
                return isCheck || v?.length;
              }
            ),
          noOfFreeDay: yup
            .mixed()
            .test('noOfFreeDay', trans('Invalid format'), function (v: any) {
              const isCheck = this.options?.['from']?.[1]?.value.polToPodAll;
              return isCheck || (v ?? '') !== '';
            })
            .test(
              'noOfFreeDay>0',
              trans('validation.min_number', { min: 0 }),
              function (v: any) {
                return Number(v ?? '') >= 0;
              }
            )
        })
      ),
      ////////////////////////////////
      podFreeDayStart: yup
        .mixed()
        .test(
          'podFreeDayStart',
          trans('validation.required.default'),
          function (v: any) {
            const isCheck = this.parent.podFromPolAll;
            return !isCheck || v?.length;
          }
        ),

      podNoFreeDay: yup
        .mixed()
        .test('podNoFreeDay', trans('Invalid format'), function (v: any) {
          const isCheck = this.parent.podFromPolAll;
          return !isCheck || (v ?? '') !== '';
        })
        .test(
          'podNoFreeDay>0',
          trans('validation.min_number', { min: 0 }),
          function (v: any) {
            return Number(v ?? '') >= 0;
          }
        ),

      podFromPolPorts: yup.array().of(
        yup.object().shape({
          ports: yup
            .mixed()
            .test(
              'podFromPolPorts',
              trans('validation.min_array', { min: 1 }),
              function (v: any) {
                const isCheck =
                  this.options?.['from']?.[1]?.value.podFromPolAll;
                return isCheck || v?.length;
              }
            ),
          pickupEndFreeDayStart: yup
            .mixed()
            .nullable()
            .test(
              'pickupEndFreeDayStart',
              trans('validation.required.default'),
              function (v: any) {
                const isCheck =
                  this.options?.['from']?.[1]?.value.podFromPolAll;
                return isCheck || v?.length;
              }
            ),
          noOfFreeDay: yup
            .mixed()
            .test('noOfFreeDay', trans('Invalid format'), function (v: any) {
              const isCheck = this.options?.['from']?.[1]?.value.podFromPolAll;
              return isCheck || v !== '';
            })
            .test(
              'noOfFreeDay>0',
              trans('validation.min_number', { min: 0 }),
              function (v: any) {
                return Number(v ?? '') >= 0;
              }
            )
        })
      )
    }),
    configs: {
      shouldFocusError: false,
      values: {
        id: item?.id,
        isOcean: item?.isOcean ?? true,
        carrier: item?.carrier,
        port: item?.port,
        containerTypeSizeAll: item?.containerTypeSizeAll ?? false,
        polToPodAll: item?.polToPodAll ?? false,
        podFromPolAll: item?.podFromPolAll ?? false,
        status: item?.status ?? Status.Active,
        polPickupEnd: item?.polPickupEnd ?? '',
        podFreeDayStart: item?.podFreeDayStart ?? '',
        polNoFreeDay: item?.polNoFreeDay ?? '',
        podNoFreeDay: item?.podNoFreeDay ?? '',
        contTypeSizeIds:
          item?.carrierPolicyContTypeSizes?.map(
            (v: any) => v?.containerTypeSize
          ) ?? [],
        polToPodPorts: item?.carrierPolicyPolToPodPorts ?? [newPolPod],
        podFromPolPorts: item?.carrierPolicyPodFromPolPorts ?? [newPolPod]
      }
    }
  });

  const [
    isOcean,
    carrier,
    port,
    containerTypeSizeAll,
    contTypeSizeIds,
    polToPodPorts
  ] = useWatch({
    control: methods.control,
    name: [
      'isOcean',
      'carrier',
      'port',
      'containerTypeSizeAll',
      'contTypeSizeIds',
      'polToPodPorts'
    ]
  });

  const mapDataContTypeSize = useCallback(
    (contTypeSize: any, isTypeSizeAll: boolean) => {
      const result = contTypeSize?.map((v: any) => {
        return v?.id;
      });
      return isTypeSizeAll ? [] : result;
    },
    []
  );

  const mapDataPolPod = useCallback(
    (polPod: any, isPolPodAll: boolean, type: number) => {
      const result = polPod?.map((v: any) => {
        return {
          id: v?.id,
          type: type,
          portIds: v?.ports?.map((o: any) => {
            return o?.id;
          }),
          pickupEndFreeDayStart: v?.pickupEndFreeDayStart,
          noOfFreeDay: v?.noOfFreeDay
        };
      });

      return isPolPodAll ? [] : result;
    },
    []
  );

  const onFormValid = useCallback(
    (data: IModifyCarrierPolicyNew) => {
      const {
        contTypeSizeIds: contTypeSize,
        podFromPolPorts: podFromPol,
        polToPodPorts: polToPod,
        containerTypeSizeAll: isTypeSizeAll,
        podFromPolAll: isPodFromPolAll,
        polToPodAll: isPolToPodAll,
        ...rest
      } = data;

      const mParams = {
        containerTypeSizeAll: isTypeSizeAll,
        contTypeSizeIds: mapDataContTypeSize(contTypeSize, isTypeSizeAll),
        polToPodAll: isPolToPodAll,
        polToPodPorts: mapDataPolPod(polToPod, isPolToPodAll, 1),
        podFromPolAll: isPodFromPolAll,
        podFromPolPorts: mapDataPolPod(podFromPol, isPodFromPolAll, 2),
        ...RequestUtils.normalizeData({
          data: rest,
          idFields: ['carrier', 'port']
        })
      };

      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, mapDataContTypeSize, mapDataPolPod, updateMutation]
  );

  const handleChangeClient = useCallback(
    (v: any) => {
      methods.setValue('carrier', v);
      methods.setValue('port', null);
      methods.setValue('polToPodPorts', [newPolPod as any]);
    },
    [methods]
  );

  const handleChangeOcean = useCallback(
    (isOceanValue: boolean) => {
      methods.setValue('isOcean', isOceanValue);
      methods.setValue('carrier', null);
      methods.setValue('port', null);
      methods.setValue('polToPodPorts', [newPolPod as any]);
      methods.setValue('contTypeSizeIds', []);
    },
    [methods]
  );

  const apiParamsCarrier = useMemo(
    () => ({
      clientTypeCodes: [isOcean ? ClientType.ShippingLine : ClientType.Airline]
    }),
    [isOcean]
  );

  const apiParamsPort = useMemo(
    () => ({
      // clientTypeCodes: isOcean ? ClientType.SeaPort : ClientType.AirPort,
      excludeIds: listIdPort(polToPodPorts, port)
    }),
    [polToPodPorts, port]
  );

  const keyOceanAir = isOcean ? 'isOcean' : 'isAir';

  const apiParamsContainer = useMemo(
    () => ({
      [keyOceanAir]: true
    }),
    [keyOceanAir]
  );

  const handleContainerTypeSizeAll = useCallback(
    (v: any) => {
      methods.setValue('containerTypeSizeAll', v);
      if (v) {
        methods.setValue('contTypeSizeIds', []);
      }
    },
    [methods]
  );

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KContainer.View
          style={{ display: 'flex' }}
          justifyContent="space-between"
        >
          <Controller
            name="isOcean"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.RadioGroup
                  noSpacing
                  data={[
                    {
                      id: 'ocean',
                      label: trans('ocean'),
                      checked: field.value,
                      onChange: v => {
                        if (v) {
                          handleChangeOcean(true);
                        }
                      }
                    },
                    {
                      id: 'air',
                      label: trans('air'),
                      checked: !field.value,
                      onChange: v => {
                        if (v) {
                          handleChangeOcean(false);
                        }
                      }
                    }
                  ]}
                />
              );
            }}
          />

          <KListItem.Radio
            name="isSystem"
            marginT={'0.75rem'}
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
          />
        </KContainer.View>

        <KGrid.Container>
          <KGrid.Item xs={6}>
            <KInput.TextField
              name="demurragePolicyCode"
              label={trans('demurrage_policy_code')}
              value={stringConcatenate([
                carrier?.code,
                port?.code,
                ...(contTypeSizeIds.length
                  ? contTypeSizeIds?.map((v: any) => v?.code)
                  : [trans('all')])
              ])}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KInput.TextField
              name="demurragePolicyName"
              label={trans('demurrage_policy_name')}
              value={stringConcatenate([
                carrier?.name,
                port?.code,
                ...(contTypeSizeIds.length
                  ? contTypeSizeIds?.map((v: any) => v?.name)
                  : [trans('all')])
              ])}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <Controller
              name="carrier"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('carrier_code')}
                    onChange={handleChangeClient}
                    apiURL={ENDPOINTS.client()}
                    apiParams={apiParamsCarrier}
                    inputProps={{
                      message: error?.message,
                      required: true
                    }}
                    getOptionLabel={(o: any) => o?.code ?? ''}
                    hasEdit
                    editURL="client"
                    hasAddNew
                    addNewURL="client"
                    addNewKey="code"
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <Controller
              name="carrier"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('carrier_name')}
                    onChange={handleChangeClient}
                    inputProps={{
                      message: error?.message,
                      required: true
                    }}
                    apiURL={ENDPOINTS.client()}
                    apiParams={apiParamsCarrier}
                    hasEdit
                    editURL="client"
                    hasAddNew
                    addNewURL="client"
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="port"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    label={trans('unloco')}
                    apiURL={ENDPOINTS.unloco()}
                    apiParams={apiParamsPort}
                    getOptionLabel={AutocompleteUtils.unlocoFormatter}
                    disabled={!carrier?.id}
                    inputProps={{
                      message: error?.message,
                      required: true
                    }}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <KLabel.Text>{trans('container_type_size')}</KLabel.Text>
          </KGrid.Item>

          <KGrid.Item xs={1}>
            <Controller
              name="containerTypeSizeAll"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    checked={field.value}
                    label={trans('all')}
                    onChange={handleContainerTypeSizeAll}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={9}>
            <Controller
              name="contTypeSizeIds"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.Autocomplete
                    {...field}
                    multiple
                    inputProps={{
                      required: !containerTypeSizeAll,
                      message: error?.message
                    }}
                    disabled={containerTypeSizeAll}
                    label={trans('selected_container_type_size')}
                    apiParams={apiParamsContainer}
                    apiURL={ENDPOINTS.containerTypeSize()}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Pol />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Pod />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('status')}
                    options={generateOptions(Status)}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="createdBy"
              label={trans('created_by')}
              value={item?.createdUsername}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="createdAt"
              label={trans('created_at')}
              value={Prototype.date.formatDT(item?.createdAt, '')}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="updatedBy"
              label={trans('updated_by')}
              value={item?.updatedUsername}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="updatedAt"
              label={trans('updated_at')}
              value={Prototype.date.formatDT(item?.updatedAt, '')}
              disabled
            />
          </KGrid.Item>
        </KGrid.Container>

        <BottomActions
          showDelete={isEdit}
          btnProps={[
            {
              onPress: () => onDelete?.([item]),
              isLoading: deleteLoading,
              disabled: item?.isSystem
            },
            {
              isLoading: addLoading || updateLoading
            }
          ]}
        />
      </KForm>
    </FormProvider>
  );
};

export default memo(CarrierPolicyForm);
