import { ExpandMore } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary, {
  accordionSummaryClasses
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import React, {
  ComponentType,
  CSSProperties,
  forwardRef,
  memo,
  useMemo
} from 'react';

import KCard from './Card';
import KView from './View';

import { KColors } from '../../constants';
import KLabel from '../Label';
import MuiIcons from '../MuiIcons';
import { KCardWithAccordionProps, KSpacing } from '../types';

const Accordion = withStyles({
  root: {
    margin: '0 !important',
    boxShadow: 'none',
    borderRadius: 4,
    paddingBottom: '0.438rem'
  },
  expanded: {
    paddingBottom: 0
  }
})(MuiAccordion);

const AccordionSummary = styled(MuiAccordionSummary)(() => ({
  padding: 0,
  margin: 0,
  minHeight: '36px !important',
  borderRadius: 4,
  [`& .${accordionSummaryClasses.expanded}`]: {
    minHeight: 36,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  }
}));

const AccordionDetails = withStyles({
  root: {
    padding: 0,
    display: 'block'
  }
})(MuiAccordionDetails);

const KCardWithAccordion = forwardRef<HTMLDivElement, KCardWithAccordionProps>(
  (props, ref) => {
    const {
      defaultExpanded = true,
      expanded,
      header,
      children,
      size,
      ...otherProps
    } = props;

    const { cardPadding }: { cardPadding: KSpacing } = useMemo(() => {
      switch (size) {
        case 'lg':
          return {
            cardPadding: '1.25rem'
          };

        case 'nm':
          return {
            cardPadding: '0.75rem'
          };

        case 'sm':
          return {
            cardPadding: '0.5rem'
          };

        case 'xs':
          return {
            cardPadding: '0.25rem'
          };

        case 'md':
        default:
          return {
            cardPadding: '1rem'
          };
      }
    }, [size]);

    const renderIcon = useMemo(() => {
      const { icon } = header;
      if (!icon) {
        return null;
      }

      const KIcon = MuiIcons[icon] as ComponentType<{ style?: CSSProperties }>;
      return (
        <KView
          background={KColors.blue.normal}
          br={'x'}
          size={36}
          dp="flex"
          center
          marginR={'1rem'}
          style={{ filter: 'drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))' }}
        >
          <KIcon style={{ color: KColors.white, width: 24, height: 24 }} />
        </KView>
      );
    }, [header]);

    const renderHeader = useMemo(() => {
      const { title, renderHeader: _renderHeader, content } = header;

      if (_renderHeader) {
        return _renderHeader();
      }

      return (
        <KView row alignItems>
          {renderIcon}
          {!!title && <KLabel.Text typo="TextMdMedium">{title}</KLabel.Text>}
          {content}
        </KView>
      );
    }, [header, renderIcon]);

    return (
      <KCard {...otherProps} ref={ref}>
        <Accordion
          defaultExpanded={defaultExpanded}
          expanded={expanded}
          elevation={0}
          style={{ padding: 0 }}
          slotProps={{
            transition: {
              unmountOnExit: false
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            sx={{
              '& .MuiAccordionSummary-content': {
                margin: 0
              },
              '& .MuiAccordionSummary-content.Mui-expanded': {
                margin: 0
              },
              '& .MuiAccordionSummary-expandIconWrapper': {
                marginRight: '1rem'
              }
            }}
            style={{
              ...header?.style
            }}
          >
            {renderHeader}
          </AccordionSummary>

          <AccordionDetails style={{ display: 'block', padding: 0 }}>
            <KView marginT={cardPadding} paddingR={cardPadding}>
              {children}
            </KView>
          </AccordionDetails>
        </Accordion>
      </KCard>
    );
  }
);

KCardWithAccordion.defaultProps = {
  marginT: '0.75rem',
  paddingR: 0
};

export default memo(KCardWithAccordion);
