import {
  KTabSolid,
  KTabTransparent,
  KTabBase,
  KTabWhite,
  KTabMain,
  KTabSubMain
} from './Base';
import Outline from './Outline';
import Panel from './Panel';

export default class KTab {
  static Base = KTabBase;

  static Solid = KTabSolid;

  static Transparent = KTabTransparent;

  static Outline = Outline;

  static Panel = Panel;

  static White = KTabWhite;

  static Main = KTabMain;

  static SubMain = KTabSubMain;
}
