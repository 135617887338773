import { IApInvoiceFormData, IArInvoiceFormData } from '@form-dto';
import { compact } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import {
  ConsolType,
  ENDPOINTS,
  ExpConsolType,
  ImpConsolType
} from '@constants';
import NotesToClient from 'containers/Accounting/NotesToClient';
import { Prototype } from 'core';
import { useFetchUserStaffBranch } from 'hooks';
import trans from 'translation';
import { KContainer, KGrid, KInput, KLabel, KTab } from 'uikit';
import { UIUtils } from 'utils';

export const strValInvoiceSummary = (currencyCode: string) => {
  const valSummary = useCallback(
    (labelInvoice: string, totalApInvoice: number, totalArInvoice: number) => {
      const label = `${trans(labelInvoice)} (${currencyCode || ''})`;

      const val = [totalApInvoice, totalArInvoice].join(' - ');
      return `${label}: ${val}`;
    },
    [currencyCode]
  );

  return { valSummary };
};

export const SummuraryAmount = ({
  screen,
  contentRight,
  type,
  disabled = false,
  isNoteToClient = true,
  isRemark = false
}: {
  screen: 'AP' | 'AR';
  contentRight?: {
    subTotal: number;
    taxAmount: number;
    totalAmount: number;
    paymentCurrency: any;
    balance: string | number;
    collect: number;
  };
  type: 'AP_INVOICE' | 'AR_INVOICE' | 'PAYMENT_ADVICE' | 'RECEIVABLE_ADVICE';
  disabled?: boolean;
  isNoteToClient?: boolean;
  isRemark?: boolean;
}) => {
  const {
    subTotal,
    taxAmount,
    totalAmount,
    paymentCurrency,
    balance,
    collect
  } = contentRight ?? {
    subTotal: 0,
    taxAmount: 0,
    totalAmount: 0,
    paymentCurrency: null,
    balance: 0,
    collect: 0
  };

  const methods = useFormContext<IArInvoiceFormData | IApInvoiceFormData>();

  const onShowNotesToClient = (v?: any) => {
    UIUtils.popup.open({
      title: trans('notes_to_client'),
      maxWidth: 'sm',
      content: () => {
        return <NotesToClient item={v} type={type} />;
      }
    });
  };

  return (
    <KGrid.Container justifyContent="space-between" marginTop={5}>
      <KContainer.RenderWhen>
        <KContainer.RenderWhen.If isTrue={isNoteToClient}>
          <KGrid.Item xs={6}>
            <KGrid.Container>
              {isRemark && (
                <KGrid.Item xs={6}>
                  <Controller
                    name="remark"
                    control={methods.control}
                    render={({ field }) => {
                      return (
                        <KInput.TextField
                          {...field}
                          marginT={'0.75rem'}
                          label={trans('remark')}
                          minRows={8}
                          multiline
                        />
                      );
                    }}
                  />
                </KGrid.Item>
              )}

              <KGrid.Item xs={6}>
                <Controller
                  name="accountingNote"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.Autocomplete
                        {...field}
                        label={trans('notes_to_client')}
                        apiURL={ENDPOINTS.notesToClient()}
                        getOptionLabel={(o: any) => o?.title ?? ''}
                        apiParams={{
                          type
                        }}
                        disabled={disabled}
                        onAddNew={onShowNotesToClient}
                        onEdit={onShowNotesToClient}
                        addNewKey="title"
                        hasAddNew
                        hasEdit
                      />
                    );
                  }}
                />

                <Controller
                  name="accountingNote"
                  control={methods.control}
                  render={({ field }) => {
                    return (
                      <KInput.TextField
                        {...field}
                        marginT={'1.25rem'}
                        placeholder="Note Details"
                        value={field?.value?.detail ?? ''}
                        minRows={5}
                        multiline
                        disabled
                      />
                    );
                  }}
                />
              </KGrid.Item>
            </KGrid.Container>
          </KGrid.Item>
        </KContainer.RenderWhen.If>
        <KContainer.RenderWhen.If isTrue={!isNoteToClient}>
          <KGrid.Item xs={3}>
            {isRemark && (
              <Controller
                name="remark"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      marginT={'0.75rem'}
                      label={trans('remark')}
                      minRows={7}
                      multiline
                      disabled
                    />
                  );
                }}
              />
            )}
          </KGrid.Item>
        </KContainer.RenderWhen.If>
      </KContainer.RenderWhen>

      {contentRight && (
        <KGrid.Item xs={3}>
          <KContainer.View height={1} margin="0.5rem" background="black" />

          <KGrid.Container>
            <KGrid.Item xs={8}>
              <KLabel.Paragraph textAlign="right" typo="TextNmBold">
                {trans('sub_total_in')} ({paymentCurrency?.code}):
              </KLabel.Paragraph>

              <KLabel.Paragraph
                textAlign="right"
                typo="TextNmBold"
                marginV="0.25rem"
              >
                {trans('tax')}:
              </KLabel.Paragraph>

              <KLabel.Paragraph textAlign="right" typo="TextMdBold">
                {trans('total')}:
              </KLabel.Paragraph>
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <KLabel.Paragraph typo="TextNmBold">
                {Prototype.number.formatNumber(subTotal, { negative: true })}
              </KLabel.Paragraph>

              <KLabel.Paragraph typo="TextNmBold" marginV="0.25rem">
                {Prototype.number.formatNumber(taxAmount, { negative: true })}
              </KLabel.Paragraph>

              <KLabel.Paragraph typo="TextMdBold">
                {Prototype.number.formatNumber(totalAmount, { negative: true })}
              </KLabel.Paragraph>
            </KGrid.Item>
          </KGrid.Container>

          <KContainer.View height={1} margin="0.5rem" background="black" />

          <KGrid.Container>
            <KGrid.Item xs={8}>
              <KLabel.Paragraph textAlign="right" typo="TextMdBold">
                {screen === 'AR' ? trans('collect') : trans('paid')}:
              </KLabel.Paragraph>

              <KLabel.Paragraph textAlign="right" typo="TextMdBold">
                {trans('balance')}:
              </KLabel.Paragraph>
            </KGrid.Item>

            <KGrid.Item xs={4}>
              <KLabel.Paragraph typo="TextMdBold">
                {Prototype.number.formatNumber(collect, { negative: true })}
              </KLabel.Paragraph>

              <KLabel.Paragraph typo="TextMdBold" color="#0F857D">
                {Prototype.number.formatNumber(totalAmount - collect, {
                  negative: true
                })}
              </KLabel.Paragraph>
            </KGrid.Item>
          </KGrid.Container>
        </KGrid.Item>
      )}
    </KGrid.Container>
  );
};

export const useImpExpTab = () => {
  const { currentBranch } = useFetchUserStaffBranch();
  const name = currentBranch?.department?.name ?? '';
  const isImp = name.includes('IMP');
  const isExp = name.includes('EXP');

  const [tabIndex, setTabIndex] = useState(0);

  const tabs = useMemo(() => {
    return compact([
      // { key: 'ALL', label: trans('all') },
      !isExp ? { key: 'IMP', label: trans('import') } : undefined,
      !isImp ? { key: 'EXP', label: trans('export') } : undefined,
      { key: 'DOM', label: trans('domestic') }
    ]);
  }, [isExp, isImp]);

  const tabOptions = useMemo(() => {
    return isImp ? ImpConsolType : isExp ? ExpConsolType : ConsolType;
  }, [isExp, isImp]);

  const tabData = useMemo(() => {
    return tabs?.[tabIndex]?.key;
  }, [tabIndex, tabs]);

  const Tab = useCallback(() => {
    return (
      <KTab.White
        tabs={tabs}
        tabIndex={tabIndex}
        onChangeTab={setTabIndex}
        kind="background"
        marginH="0.75rem"
        paddingH="0rem"
        spacing="0rem"
        marginT="0rem"
      />
    );
  }, [tabIndex, tabs]);

  return { Tab, tabData, tabOptions, isImp, isExp };
};
