import React, { memo } from 'react';

import Quotation from './Quotation';

const Sales = () => {
  return (
    <>
      <Quotation source="quotation" />
    </>
  );
};

export default memo(Sales);
