import { IVesselParams, IFlightParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDVessel = () => {
  return useCUDMutationEnhancer<IVesselParams>({
    endPoint: ENDPOINTS.vessel(),
    webTable: WebTable.VESSEL
  });
};

export const useCUDFlight = () => {
  return useCUDMutationEnhancer<IFlightParams>({
    endPoint: ENDPOINTS.flight(),
    webTable: WebTable.FLIGHT
  });
};
