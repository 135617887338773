import React, { forwardRef, memo } from 'react';

import { NumberFormat } from './helpers';
import TextField from './TextField';

import { KInputProps } from '../types';

const KInputCurrency = forwardRef<HTMLInputElement, KInputProps>(
  (props, ref) => {
    return (
      <TextField
        {...props}
        ref={ref}
        InputProps={{
          ...props.InputProps,
          inputComponent: NumberFormat
        }}
      />
    );
  }
);

export default memo(KInputCurrency);
