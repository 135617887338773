import { IItemProps } from '@ui';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { KGrid, KInput, KListItem, KForm } from 'uikit';
import * as yup from 'yup';

import { Status, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import {
  useResolverForm,
  useCUDClientCategory,
  useAlertMutationEnhancer
} from 'hooks';
import trans from 'translation';
import { ValidationUtils } from 'utils';

interface FormData {
  code: string;
  name: string;
  displayPosition?: number;
  status: Status;
  description: string;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required(),
  displayPosition: ValidationUtils.requiredDisplayPosition()
});

const CategoryForm = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    deleteMutation,
    modifyLoading
  } = useCUDClientCategory();

  const { onDelete, isLoading } = useAlertMutationEnhancer({
    mutation: deleteMutation
  });

  const isEdit = !!item;

  const methods = useResolverForm<FormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? '',
        name: item?.name ?? '',
        displayPosition: item?.displayPosition ?? 0,
        status: item?.status ?? Status.Active,
        description: item?.description ?? ''
      }
    }
  });

  const onFormValid = useCallback(
    (data: FormData) => {
      const mParams = {
        ...data,
        id: item?.id,
        displayPosition: data.displayPosition || undefined
      };

      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, item?.id, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('code')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('name')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="displayPosition"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  type="number"
                  label={trans('display_order')}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2}>
          <KListItem.Radio
            name="isSystem"
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            containerStyle={{ marginTop: '0.75rem' }}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="description"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('description')}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            disabled
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete([item]),
            isLoading,
            disabled: item?.isSystem
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(CategoryForm);
