import { useMutation } from '@tanstack/react-query';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { KButton, KContainer, KLabel, KListItem, KForm } from 'uikit';
import * as yup from 'yup';

import { useResolverForm } from 'hooks';
import trans from 'translation';
import { DeviceUtils } from 'utils';

interface Props {
  columns: any[];
  onDownload: (dParams: any) => Promise<{ data: any }>;
  dismiss: () => void;
}

interface IFormData {
  selected: any;
  sortBy: string | null;
  isChecked: boolean;
}

const DownloadCsv = ({ columns, onDownload, dismiss }: Props) => {
  const mapped = useMemo(() => {
    return columns.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.mappedName]: cur.label
      };
    }, {});
  }, [columns]);

  const methods = useResolverForm<IFormData>({
    schema: yup.object().shape({}),
    configs: {
      defaultValues: {
        selected: columns.reduce((acc, cur) => {
          return {
            ...acc,
            [cur.mappedName]: true
          };
        }, {}),
        sortBy: null,
        isChecked: true
      }
    }
  });

  const selected = useWatch({ control: methods.control, name: 'selected' });

  const { mutate, isLoading } = useMutation(async (v: any) => {
    const { data } = await onDownload?.(v);
    if (data) {
      DeviceUtils.downloadFile({
        contentType: data.contentType,
        fileName: data.fileName,
        base64: data.bytes
      });
      dismiss();
    } else {
      // ToastManager.notify();
    }
  });

  const onFormValid = useCallback(
    (v: IFormData) => {
      const mappedNames = Object.keys(v.selected).filter(i => v.selected[i]);
      const mParams = {
        columns: mappedNames.join(','),
        fieldNameMap: JSON.stringify(
          mappedNames.reduce((acc, key) => ({ ...acc, [key]: mapped[key] }), {})
        ),
        sortBy: v.sortBy
      };

      mutate(mParams);
    },
    [mapped, mutate]
  );

  const renderHeader = useMemo(() => {
    return (
      <KContainer.View marginB="0.25rem" row alignItems>
        <KContainer.View flex={4} row alignItems>
          <Controller
            control={methods.control}
            render={({ field: { value, onChange } }) => {
              return (
                <KListItem.Checkbox
                  name="isChecked"
                  label={trans('all_columns')}
                  typo="TextXNmMedium"
                  checked={value}
                  onChange={v => {
                    methods.setValue(
                      'selected',
                      Object.keys(selected).reduce(
                        (acc, key) => ({ ...acc, [key]: v }),
                        {}
                      )
                    );
                    onChange(v);
                  }}
                />
              );
            }}
            name="isChecked"
          />
        </KContainer.View>

        <KContainer.View dp="flex" flex alignItems>
          <KLabel.Text typo="TextXNmMedium">{trans('ascending')}</KLabel.Text>
        </KContainer.View>

        <KContainer.View dp="flex" flex alignItems>
          <KLabel.Text typo="TextXNmMedium">{trans('descending')}</KLabel.Text>
        </KContainer.View>
      </KContainer.View>
    );
  }, [methods, selected]);

  const renderContent = useMemo(() => {
    return columns.map(i => {
      const sortDisabled = !i.allowedToSort || !selected[i.mappedName];

      return (
        <KContainer.View key={i.mappedName} row alignItems marginT="0.5rem">
          <KContainer.View dp="flex" flex={4}>
            <Controller
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    label={i.label}
                    checked={field.value}
                    {...field}
                  />
                );
              }}
              name={`selected.${i.mappedName}`}
            />
          </KContainer.View>

          <KContainer.View dp="flex" flex alignItems>
            <Controller
              control={methods.control}
              render={({ field: { value, onChange } }) => {
                return (
                  <KListItem.Radio
                    name="sortBy-asc"
                    checked={value === `${i.mappedName}:ASC`}
                    disabled={sortDisabled}
                    onChange={v => onChange(v ? `${i.mappedName}:ASC` : null)}
                  />
                );
              }}
              name="sortBy"
            />
          </KContainer.View>

          <KContainer.View dp="flex" flex alignItems>
            <Controller
              control={methods.control}
              render={({ field: { value, onChange } }) => {
                return (
                  <KListItem.Radio
                    name="sortBy-desc"
                    checked={value === `${i.mappedName}:DESC`}
                    disabled={sortDisabled}
                    onChange={v => onChange(v ? `${i.mappedName}:DESC` : null)}
                  />
                );
              }}
              name="sortBy"
            />
          </KContainer.View>
        </KContainer.View>
      );
    });
  }, [columns, methods.control, selected]);

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      {renderHeader}

      {renderContent}

      <KContainer.View row justifyContent="flex-end">
        <KButton.Solid
          type="submit"
          isLoading={isLoading}
          title={trans('download')}
          marginT="0.75rem"
        />
      </KContainer.View>
    </KForm>
  );
};

export default memo(DownloadCsv);
