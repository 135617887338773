import { IModifyBankAccount } from '@request-dto';
import { IItemProps } from '@ui';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useCUDCompanyBankAccount, useResolverForm } from 'hooks';
import { useDeleteCompanyBankAccount } from 'pages/Company/helper';
import trans from 'translation';
import { KInput, KGrid, KForm } from 'uikit';
import { ValidationUtils } from 'utils';

// import { useDeleteBankingBankAccountHelper } from '../helpers';

const schema = yup.object().shape({
  chartOfAccount: ValidationUtils.requiredAny(),
  accountNumber: ValidationUtils.required(),
  accountName: ValidationUtils.required(),
  bankName: ValidationUtils.required(),
  bankBranch: ValidationUtils.required(),
  currency: ValidationUtils.requiredAny()
});

const BankAccount = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation, isLoading: addLoading },
    updateMutation: { mutate: updateMutation, isLoading: updateLoading }
  } = useCUDCompanyBankAccount();

  const { onDelete, deleteLoading } = useDeleteCompanyBankAccount();

  const isEdit = !!item;

  const methods = useResolverForm<IModifyBankAccount>({
    schema: schema,
    configs: {
      values: {
        id: item?.id,
        chartOfAccount: item?.chartOfAccount,
        bankName: item?.bankName ?? '',
        bankBranch: item?.bankBranch ?? '',
        swiftCode: item?.swiftCode ?? '',
        accountNumber: item?.accountNumber ?? '',
        accountName: item?.accountName ?? '',
        currency: item?.currency,
        isDefault: item?.isDefault ?? false,
        status: item?.status
      }
    }
  });

  const onSubmit = useCallback(
    (data: IModifyBankAccount) => {
      const { currency, chartOfAccount, ...rest } = data;
      const mParams = {
        currencyId: currency?.id,
        chartOfAccountId: chartOfAccount?.id,
        ...rest
      };
      if (isEdit) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, isEdit, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onSubmit)}>
      <KGrid.Container>
        <KGrid.Item xs={4}>
          <Controller
            name="chartOfAccount"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('chart_of_account')}
                  apiURL={ENDPOINTS.chartOfAccount()}
                  getOptionLabel={(o: any) => o?.displayName ?? ''}
                  inputProps={{ required: true, message: error?.message }}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="chartOfAccount"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('opening_balance')}
                  value={field.value?.openingBalance ?? ''}
                  disabled
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="chartOfAccount"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('calculated_balance')}
                  value={field.value?.currentBalance ?? ''}
                  disabled
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="accountNumber"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('account_no')}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="accountName"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('account_name')}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="bankName"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('bank_name')}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="bankBranch"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('bank_branch')}
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="swiftCode"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField {...field} label={trans('swift_code')} />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="currency"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('currency')}
                  apiURL={ENDPOINTS.currency()}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit && !!onDelete}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            isLoading: deleteLoading
          },
          {
            isLoading: addLoading || updateLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(BankAccount);
