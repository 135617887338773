import { IModifyExchangeRate } from '@request-dto';

import { ENDPOINTS, WebTable, tableRef } from '@constants';
import APIManager from '@services';
import { UIUtils } from 'utils';

import { useCUDMutationEnhancer, useMutationEnhancer } from '../core';

export const useCUDExchangeRate = () => {
  return useCUDMutationEnhancer<IModifyExchangeRate>({
    endPoint: ENDPOINTS.exchangeRate(),
    webTable: WebTable.EXCHANGE_RATE
  });
};

export const useValidateImportedFile = () => {
  return useMutationEnhancer<any, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.exchangeRate('import-validate'),
        method: 'POST',
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });
      return res.data;
    }
  });
};

export const useImportExchangeRate = () => {
  return useMutationEnhancer<boolean | undefined, FormData>({
    mutationFn: async (formData: FormData) => {
      const res = await APIManager.request({
        url: ENDPOINTS.exchangeRate('import-excel'),
        method: 'POST',
        showToast: true,
        body: formData,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });
      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.EXCHANGE_RATE]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useDownloadSampleFile = () => {
  return useMutationEnhancer<boolean | undefined, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.importTemplate(),
        body: data
      });
      return res.data;
    },
    onSuccess: (data: any) => {
      if (data?.url) {
        window.open(data.url, '_blank');
      }
    }
  });
};
