import { IItemProps } from '@ui';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { KGrid, KInput, KListItem, KForm } from 'uikit';
import * as yup from 'yup';

import { Status, generateOptions } from '@constants';
import { BottomActions, CheckBoxError } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDIncoterm } from 'hooks';
import trans from 'translation';
import { ValidationUtils } from 'utils';

import { useDeleteIncotermsHelper } from '../../helpers';

interface IFormData {
  code: string;
  name: string;
  isAir: boolean;
  isOcean: boolean;
  status: Status;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required(),
  isAir: ValidationUtils.requiredCheckBoxAirOcean()
});

const IncoTermForm = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDIncoterm();

  const { onDelete, deleteLoading } = useDeleteIncotermsHelper();

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? '',
        name: item?.name ?? '',
        isAir: item?.isAir ?? true,
        isOcean: item?.isOcean ?? true,
        status: item?.status ?? Status.Active
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      if (isEdit) {
        updateMutation({
          ...data,
          id: item?.id
        });
      } else {
        createMutation(data);
      }
    },
    [createMutation, isEdit, item?.id, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={2.4}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('code')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={4.8}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('name')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={2.4} textAlign={'center'}>
          <Controller
            name="isOcean"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('ocean')}
                  checked={field.value}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
          <Controller
            name="isAir"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <>
                  <KListItem.Checkbox
                    {...field}
                    label={trans('air')}
                    checked={field.value}
                    disabled={item?.isSystem}
                  />
                  <CheckBoxError errMessage={error?.message} />
                </>
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={2.4}>
          <KListItem.Radio
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            marginT="0.75rem"
          />
        </KGrid.Item>
        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            disabled
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            isLoading: deleteLoading,
            disabled: item?.isSystem
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(IncoTermForm);
