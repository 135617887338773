import { set } from 'lodash';

import {
  AirContainerMode,
  ENDPOINTS,
  OceanContainerMode,
  QUERY_KEYS,
  SystemChargeCodeUnit,
  UIDataType,
  WebTable
} from '@constants';
import APIManager from '@services';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

const OPTIONS: Record<UIDataType, { endPoint: string; webTable: WebTable }> = {
  [UIDataType.Cs]: {
    endPoint: ENDPOINTS.accountingWip(),
    webTable: WebTable.CONSOL_WIP
  },
  [UIDataType.Sm]: {
    endPoint: ENDPOINTS.billingWip(),
    webTable: WebTable.SHIPMENT_WIP
  },
  [UIDataType.Sv]: {
    endPoint: ENDPOINTS.serviceWip(),
    webTable: WebTable.SERVICE_WIP
  },
  [UIDataType.Iv]: {
    endPoint: ENDPOINTS.serviceWip(),
    webTable: WebTable.SERVICE_WIP
  }
};

export const useCUDWip2 = (category = UIDataType.Cs) => {
  return useCUDMutationEnhancer<any>({
    ...OPTIONS[category],
    mutationKeys: [[QUERY_KEYS.accountingStatistics]]
  });
};

const UNIT_OPTIONS: Record<
  SystemChargeCodeUnit,
  { clientQuantity?: string; chargeableQuantity?: string }
> = {
  [SystemChargeCodeUnit.BOL]: {
    clientQuantity: 'none',
    chargeableQuantity: 'none'
  },
  [SystemChargeCodeUnit.CON]: {
    clientQuantity: 'totalPacks',
    chargeableQuantity: 'totalPacks'
  },
  [SystemChargeCodeUnit.KG]: {
    clientQuantity: 'clientGrossWeight',
    chargeableQuantity: 'carrierGrossWeight'
  },
  [SystemChargeCodeUnit.M3]: {
    clientQuantity: 'clientVolume',
    chargeableQuantity: 'carrierChargeable'
  },
  [SystemChargeCodeUnit.DAY]: {
    clientQuantity: undefined,
    chargeableQuantity: undefined
  },
  [SystemChargeCodeUnit.SET]: {
    clientQuantity: undefined,
    chargeableQuantity: undefined
  },
  [SystemChargeCodeUnit.SHI]: {
    clientQuantity: undefined,
    chargeableQuantity: undefined
  }
};

export const mapUnitKeyByContainerMode = (
  containerMode?: AirContainerMode | OceanContainerMode
) => {
  const mappedField = {
    FCL: 'fclUnit',
    LCL: 'lclUnit',
    FCX: 'fcxUnit',
    FAK: 'fakUnit',
    ULD_FCL: 'uldUnit',
    LSE_LCL: 'lseUnit',
    ULD_FAK: 'othUnit',
    BCN_FCX: 'bcnUnit'
  };

  return !!containerMode ? mappedField[containerMode] : null;
};

export const useCalculateWipQuantityWithUnit = (
  category = UIDataType.Cs,
  initParams: any
) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.calculateQuantityWithUnit, category, initParams],
    queryFn: async () => {
      const res = await APIManager.request({
        url: `${OPTIONS[category].endPoint}/calculate-quantity-with-unit`,
        body: initParams
      });

      const mapV = {};

      (res.data ?? []).forEach((item: any) => {
        const key: SystemChargeCodeUnit = item?.chargeCodeUnit?.code;

        const { clientQuantity, chargeableQuantity } = UNIT_OPTIONS[key];

        set(mapV, key, {
          chargeCodeUnit: item?.chargeCodeUnit,
          quantity: item?.quantity ?? 1,
          clientQuantity: clientQuantity ? item[clientQuantity] ?? 1 : 1,
          chargeableQuantity: chargeableQuantity
            ? item[chargeableQuantity] ?? 1
            : 1
        });
      });

      return mapV;
    }
  });
};
