import { IPackageTypeParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDPackageType = () => {
  return useCUDMutationEnhancer<IPackageTypeParams>({
    endPoint: ENDPOINTS.packageType(),
    webTable: WebTable.PACKAGE_TYPE,
    dependentWebTable: [WebTable.CONTAINER_PACKAGE_LIST]
  });
};
