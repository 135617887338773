import { IRelatedPartyParams } from '@request-dto';
import { useQueryClient } from '@tanstack/react-query';
import { generatePath } from 'react-router-dom';

import { QUERY_KEYS, WebTable, RelatedTab, ENDPOINTS } from '@constants';
import APIManager, { END_POINTS } from '@services';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../core';

const queryKey = {
  [RelatedTab.Regular]: QUERY_KEYS.relatedPartyList,
  [RelatedTab.Ecus]: QUERY_KEYS.ecusRelatedPartyList
};

export const useFetchRelatedPartyList = (clientId?: string | number) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.relatedPartyList, clientId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CLIENT.GET_LIST_RELATED_CLIENT, {
          clientId
        })
      });

      return res.data;
    },
    enabled: !!clientId
  });
};

export const useFetchEcusRelatedPartyList = (clientId?: string | number) => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.ecusRelatedPartyList, clientId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: generatePath(END_POINTS.CLIENT.GET_ECUS_RELATED_PARTIES, {
          clientId
        })
      });

      return res.data;
    },
    enabled: !!clientId
  });
};

export const useCUDRelatedParty = (
  clientId?: string | number,
  tabName?: string
) => {
  const queryClient = useQueryClient();
  return useCUDMutationEnhancer<IRelatedPartyParams>({
    endPoint: ENDPOINTS.client(':id/related-client', { id: clientId }),
    webTable: WebTable.CLIENT_RELATED_PARTIES,
    onSuccess: () => {
      if (tabName && queryKey?.[tabName]) {
        queryClient.invalidateQueries([queryKey[tabName]]);
      }
    }
  });
};
