import { IItemParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../core';

export const useCUDJobTitle = () => {
  return useCUDMutationEnhancer<IItemParams>({
    endPoint: ENDPOINTS.jobTitle(),
    webTable: WebTable.JOB_TITLE
  });
};

export const useCUDDepartment = () => {
  return useCUDMutationEnhancer<IItemParams>({
    endPoint: ENDPOINTS.department(),
    webTable: WebTable.DEPARTMENT
  });
};

export const useCUDAddressCapability = () => {
  return useCUDMutationEnhancer<IItemParams>({
    endPoint: ENDPOINTS.addressCapability(),
    webTable: WebTable.ADDRESS_CAPABILITY
  });
};
