import { IModifyQuotationTemplate } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../core';

export const useCUDQuotationTemplate = (template: 'quotation' | 'invoice') => {
  return useCUDMutationEnhancer<IModifyQuotationTemplate>({
    endPoint: ENDPOINTS.quotationTemplate(template),
    webTable: WebTable.QUOTATION_TEMPLATE
  });
};
