import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer
} from 'react';

interface IAction {
  type: string;
  payload?: any;
}

interface IState {
  // ids: (string | 'new' | undefined)[]; // [CONSOL, SHIPMENT, CONTAINER, INVOICE]
  ids: {
    consolId?: string | number | 'new';
    shipmentId?: string | number | 'new';
    containerId?: string | number | 'new';
    invoiceId?: string | number | 'new';
  };
  arAp?: 'AR' | 'AP';
}

interface IValue extends IState {
  setIds: (ids: IState['ids'], arAp?: IState['arAp']) => void;

  clear: () => void;
}

const initState: IState = {
  ids: {}
};

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'setIds':
      return {
        ...state,
        arAp: action.payload.arAp,
        ids: action.payload.ids
      };

    case 'setArAp':
      return {
        ...state,
        arAp: action.payload
      };

    case 'clear':
      return {
        ...state,
        ids: {},
        arAp: undefined
      };

    default:
      return { ...initState };
  }
};

// @ts-ignore
const OceanFreightContext = createContext<IValue>();

const OceanFreightProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initState);

  const setIds = useCallback((ids: IState['ids'], arAp?: IState['arAp']) => {
    dispatch({
      type: 'setIds',
      payload: {
        ids,
        arAp
      }
    });
  }, []);

  const clear = useCallback(() => {
    dispatch({
      type: 'clear'
    });
  }, []);

  const value: IValue = useMemo(
    () => ({
      ...state,

      setIds,

      clear
    }),
    [clear, setIds, state]
  );

  return (
    <OceanFreightContext.Provider value={value}>
      {children}
    </OceanFreightContext.Provider>
  );
};

const useOceanFreightContext = (): IValue => useContext(OceanFreightContext);

export default OceanFreightProvider;

export { useOceanFreightContext };
