import { IStaff } from '@dto';
import { useNavigate } from 'react-router-dom';

import {
  ENDPOINTS,
  QUERY_KEYS,
  StaffStatus,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';

import { useMutationEnhancer, useQueryEnhancer } from '../core';
import { useUploadResource } from '../ui';

export * from './branch';
export * from './role';

export const useCreateStaff = () => {
  const navigate = useNavigate();

  return useMutationEnhancer<number | undefined, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff(),
        method: 'POST',
        showToast: true,
        body: data
      });

      return res.data?.id;
    },
    onSuccess: data => {
      if (data) {
        navigate(`../${data}`, { replace: true });
      }
    }
  });
};

export const useUpdateStaff = () => {
  return useMutationEnhancer<number | undefined, any>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id', { id: data.id }),
        method: 'PUT',
        showToast: true,
        body: data
      });

      return res.data?.id;
    }
  });
};

export const useFetchStaff = (id?: number | string) => {
  return useQueryEnhancer<IStaff | undefined>({
    queryKey: [QUERY_KEYS.staff, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchStaffGlobal = (id?: number | string) => {
  return useQueryEnhancer<any | undefined>({
    queryKey: [QUERY_KEYS.staffGlobal, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('global/:id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useUpdateAvatarStaff = () => {
  const uploadImage = useUploadResource();

  return useMutationEnhancer<
    boolean | undefined,
    { id: string; formData: FormData }
  >({
    mutationFn: async data => {
      const { id, formData } = data;
      const resourceRes = await uploadImage.mutateAsync(formData);

      if (resourceRes) {
        const res = await APIManager.request({
          url: ENDPOINTS.staff(':id/avatar', { id }),
          method: 'PUT',
          showToast: true,
          body: {
            avatar: resourceRes.key
          }
        });
        return res.success;
      }

      return undefined;
    },
    mutationKeys: [[QUERY_KEYS.staff]]
  });
};

export const usePreDeleteStaffs = () => {
  return useMutationEnhancer<boolean | undefined, number[]>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('confirm-delete'),
        method: 'GET',
        body: { ids: data }
      });

      return res.data;
    }
  });
};

export const useDeleteStaffs = () => {
  return useMutationEnhancer<boolean | undefined, number[]>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff('bulk-delete'),
        method: 'DELETE',
        showToast: true,
        body: { ids: data }
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.STAFF]?.init?.();
      }
    }
  });
};

export const useChangeStaffStatus = () => {
  return useMutationEnhancer<
    boolean | undefined,
    { id: string; status: StaffStatus }
  >({
    mutationFn: async data => {
      const { id, status } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id/status', { id }),
        method: 'PUT',
        showToast: true,
        body: { staffStatus: status }
      });

      return res.success;
    },
    mutationKeys: [[QUERY_KEYS.staff]]
  });
};

// export const useFetchStaffPermissionsGroup = () => {
//   return useQueryEnhancer<any>({
//     queryKey: [QUERY_KEYS.staffPermissionsGroup],
//     queryFn: async () => {
//       const res = await APIManager.request({
//         url: ENDPOINTS.staff('permissions-group'),
//         method: 'GET'
//       });

//       return res.data ?? null;
//     }
//   });
// };
