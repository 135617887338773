import { IWithOceanAirParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';
import { useCUDMutationEnhancer } from 'hooks/core';

export const useCUDHandlingType = () => {
  return useCUDMutationEnhancer<IWithOceanAirParams>({
    endPoint: ENDPOINTS.handlingType(),
    webTable: WebTable.HANDlING_TYPE
  });
};

export const useCUDDeliveryMode = () => {
  return useCUDMutationEnhancer<IWithOceanAirParams>({
    endPoint: ENDPOINTS.deliveryMode(),
    webTable: WebTable.DELIVERY_MODE
  });
};
