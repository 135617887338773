import React from 'react';

import { useDeleteHelper } from 'hooks';
import { useCUDTermsOfCondition } from 'hooks/ssLibrary/setting';
import trans from 'translation';
import { UIUtils } from 'utils';

import TermsOfConditionForm from '../Setting/TermsOfCondition/Form';

export const onShowTermsOfCondition = (branchId: number | string, v?: any) => {
  UIUtils.popup.open({
    title: trans('terms_of_condition'),
    maxWidth: 'md',
    content: () => <TermsOfConditionForm branchId={branchId} item={v} />
  });
};

export const useDeleteTermsOfConditionHelper = () => {
  const {
    deleteMutation: { mutate, isLoading, ...rest }
  } = useCUDTermsOfCondition();

  const onDelete = useDeleteHelper({
    name: 'terms_of_condition',
    mutate
  });

  return { onDelete, deleteLoading: isLoading, ...rest };
};
