import React, { memo } from 'react';
import { Outlet } from 'react-router-dom';

import { Portal } from 'containers/Portal';

const AuthLayout = () => {
  return (
    <>
      <Outlet />

      <Portal />
    </>
  );
};

export default memo(AuthLayout);
