import { IModifyQuotationTemplate } from '@request-dto';
import { IItemProps } from '@ui';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller, FormProvider, useWatch } from 'react-hook-form';
import { KGrid, KInput, KListItem, KForm, KContainer, KLabel } from 'uikit';
import * as yup from 'yup';

import { Status, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm } from 'hooks';
import { useCUDQuotationTemplate } from 'hooks/ssLibrary/sales';
import trans from 'translation';
import { ValidationUtils } from 'utils';

import ChargeCode from './ChargeCode';

import { useDeleteQuotationTemplateHelper } from '../../helpers';

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required(),
  type: yup
    .array(yup.boolean())
    .test('min1', trans('validation.select_at_least_one'), function (v) {
      return v?.some(i => i);
    }),

  kind: yup
    .array(yup.boolean())
    .test('max1', trans('validation.select_at_most_one'), function (v) {
      return !v?.every(i => i);
    })
});

const ClientTypeForm = ({
  item,
  source
}: IItemProps & {
  source: 'quotation' | 'invoice';
}) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDQuotationTemplate(source);

  const { onDelete, deleteLoading } = useDeleteQuotationTemplateHelper(source);

  const isEdit = !!item;

  const methods = useResolverForm<any>({
    schema,
    configs: {
      values: {
        id: item?.id,
        name: item?.name ?? '',
        code: item?.code ?? '',
        description: item?.description ?? '',
        remark: item?.remark ?? '',
        isAir: item?.isAir ?? false,
        isOcean: item?.isOcean ?? true,
        isCharter: item?.isCharter ?? true,
        isLinked: item?.isLinked ?? false,

        type: [!!item?.isImport, !!item?.isExport, !!item?.isDomestic],

        kind: [!!item?.isDangerous, !!item?.isRefrigerated],

        status: item?.status ?? Status.Active,
        chargeCodes: item?.chargeCodes ?? [],
        chargeCodeIds: (item?.chargeCodes ?? []).map((v: any) => v?.id)
      }
    }
  });

  const [chargeCodes, kind] = useWatch({
    control: methods.control,
    name: ['chargeCodes', 'kind']
  });

  const {
    formState: { errors }
  } = methods;

  const keyRefDang = useMemo(
    () => (kind[0] && 'isDangerous') || (kind[1] && 'isRefrigerated'),
    [kind]
  );

  const isMessageRefDang = useMemo(() => {
    if (keyRefDang) {
      const data = (chargeCodes ?? []).filter((v: any) => {
        return v[keyRefDang];
      });
      return data?.length > 0;
    }
    return true;
  }, [chargeCodes, keyRefDang]);

  const onFormValid = useCallback(
    (data: IModifyQuotationTemplate) => {
      const { type: typeArr, kind: kindArr } = data;

      if (isMessageRefDang) {
        delete data.chargeCodes;
        const mParams = {
          ...data,
          isOcean: !data?.isAir,
          isLinked: !data?.isCharter,

          isImport: typeArr[0] ?? undefined,
          isExport: typeArr[1] ?? undefined,
          isDomestic: typeArr[2] ?? undefined,

          isDangerous: kindArr[0] ?? undefined,
          isRefrigerated: kindArr[1] ?? undefined
        };

        if (!!mParams.id) {
          updateMutation(mParams);
        } else {
          createMutation(mParams);
        }
      }
    },
    [createMutation, isMessageRefDang, updateMutation]
  );

  const isDisabled = useMemo(
    () => (chargeCodes ?? []).length > 0 || item?.isSystem,
    [chargeCodes, item?.isSystem]
  );

  const onAfterChangeType = useCallback(() => {
    methods.trigger('type');
  }, [methods]);

  const onAfterChangeKind = useCallback(() => {
    methods.trigger('kind');
  }, [methods]);

  return (
    <FormProvider {...methods}>
      <KForm onSubmit={methods.handleSubmit(onFormValid)}>
        <KGrid.Container>
          <KGrid.Item xs={2}>
            <Controller
              name="isAir"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.RadioGroup
                    noSpacing
                    disabled={isDisabled}
                    data={[
                      {
                        id: 'ocean',
                        label: trans('ocean'),
                        checked: !field.value,
                        onChange: v => {
                          if (v) {
                            methods.setValue('isAir', false);
                            methods.setValue('isOcean', true);
                          }
                        }
                      },
                      {
                        id: 'air',
                        label: trans('air'),
                        checked: field.value,
                        onChange: v => {
                          if (v) {
                            methods.setValue('isAir', true);
                            methods.setValue('isOcean', false);
                          }
                        }
                      }
                    ]}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <Controller
              name="isCharter"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.RadioGroup
                    disabled={isDisabled}
                    noSpacing
                    data={[
                      {
                        id: 'charter',
                        label: trans('charter'),
                        checked: field.value,
                        onChange: v => {
                          if (v) {
                            methods.setValue('isCharter', true);
                          }
                        }
                      },
                      {
                        id: 'linked',
                        label: trans('is_linked'),
                        checked: !field.value,
                        onChange: v => {
                          if (v) {
                            methods.setValue('isCharter', false);
                          }
                        }
                      }
                    ]}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2}>
            <Controller
              name="type.0"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    disabled={isDisabled}
                    label={trans('import')}
                    onAfterChange={onAfterChangeType}
                    checked={field.value}
                  />
                );
              }}
            />

            <Controller
              name="type.1"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('export')}
                    onAfterChange={onAfterChangeType}
                    disabled={isDisabled}
                    checked={field.value}
                  />
                );
              }}
            />

            <Controller
              name="type.2"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    disabled={isDisabled}
                    label={trans('domestic')}
                    onAfterChange={onAfterChangeType}
                    checked={field.value}
                  />
                );
              }}
            />
            {!!errors?.type && (
              <KLabel.Error withIcon>
                {errors?.type?.message as any}
              </KLabel.Error>
            )}
          </KGrid.Item>
          <KGrid.Item xs={4}>
            <KGrid.Item display={'flex'}>
              <Controller
                name="kind.0"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KListItem.Checkbox
                      marginR={'1rem'}
                      {...field}
                      disabled={isDisabled}
                      label={trans('dangerous')}
                      onAfterChange={onAfterChangeKind}
                      checked={field.value}
                    />
                  );
                }}
              />
              <Controller
                name="kind.1"
                control={methods.control}
                render={({ field }) => {
                  return (
                    <KListItem.Checkbox
                      {...field}
                      disabled={isDisabled}
                      label={trans('refrigerated')}
                      onAfterChange={onAfterChangeKind}
                      checked={field.value}
                    />
                  );
                }}
              />
            </KGrid.Item>
            {!!errors?.kind && (
              <KLabel.Error withIcon>
                {errors?.kind?.message as any}
              </KLabel.Error>
            )}
          </KGrid.Item>
          <KGrid.Item xs={2}>
            <KListItem.Radio
              label={trans('is_system')}
              disabled
              checked={item?.isSystem}
            />
          </KGrid.Item>

          <KGrid.Item xs={5}>
            <Controller
              name="code"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required
                    label={trans('code')}
                    message={error?.message}
                    disabled={item?.isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={7}>
            <Controller
              name="name"
              control={methods.control}
              render={({ field, fieldState: { error } }) => {
                return (
                  <KInput.TextField
                    {...field}
                    required
                    label={trans('name')}
                    message={error?.message}
                    disabled={item?.isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="description"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('description')}
                    disabled={item?.isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <Controller
              name="remark"
              control={methods.control}
              render={({ field }) => {
                return <KInput.TextField {...field} label={trans('remark')} />;
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <Controller
              name="status"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KInput.TextField
                    {...field}
                    label={trans('status')}
                    options={generateOptions(Status)}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="createdBy"
              disabled
              label={trans('created_by')}
              value={item?.createdUsername ?? ''}
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="createdAt"
              label={trans('created_at')}
              value={Prototype.date.formatDT(item?.createdAt, '')}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="updatedBy"
              label={trans('updated_by')}
              value={item?.updatedUsername ?? ''}
              disabled
            />
          </KGrid.Item>

          <KGrid.Item xs={2.4}>
            <KInput.TextField
              name="updatedAt"
              label={trans('updated_at')}
              value={Prototype.date.formatDT(item?.updatedAt, '')}
              disabled
            />
          </KGrid.Item>
        </KGrid.Container>

        <KContainer.View marginT={'0.75rem'}>
          {!isMessageRefDang && (
            <KLabel.Error withIcon>
              {trans('message.err_ref_dang', { refDang: keyRefDang })}
            </KLabel.Error>
          )}
          <ChargeCode
            chargeCodes={[...chargeCodes]}
            isSystem={item?.isSystem}
          />
        </KContainer.View>

        <BottomActions
          showDelete={isEdit}
          btnProps={[
            {
              onPress: () => onDelete([item]),
              disabled: item?.isSystem,
              isLoading: deleteLoading
            },
            {
              isLoading: modifyLoading
            }
          ]}
        />
      </KForm>
    </FormProvider>
  );
};

export default memo(ClientTypeForm);
