const PAGE = 0;

const SIZE = 20;

const END_POINTS = {
  ROLE: {
    GET_ROLE_LIST: '/role',
    GET_PERMISSION_GROUP_LIST: '/permission/resource_group',
    CHECK_EXISTED_ROLE: '/role/exist'
  },
  RESOURCE: {
    RESOURCE_UPLOAD: '/resource/upload'
  },
  STAFF: {
    // Current Profile
    CHOOSE_CURRENT_PROFILE_BRANCH_AS_DEFAULT:
      '/staff/staff-branch/:branchId/default',

    // Staff
    CHOOSE_STAFF_BRANCH_AS_DEFAULT: '/staff/:id/staff-branch/:branchId/default',
    DELETE_STAFF_BRANCH_LIST: '/staff/:id/staff-branch/bulk-delete',
    GET_STAFF_ROLE_LIST_FOR_GRANT: '/staff/:id/staff-role/role',
    GET_STAFF_ROLE_LIST: '/staff/:id/staff-role',
    GET_STAFF_PERMISSION_LIST: '/staff/:id/staff-role/permissions'
  },
  CLIENT: {
    GET_LIST_RELATED_CLIENT: '/client/:clientId/related-client/client-type',
    GET_ECUS_RELATED_PARTIES:
      '/client/:clientId/related-client/ecus-client-type'
  },
  CONSOL: {
    GET_SHIPMENT_CONTAINER_LIST: '/shipment/:shipmentId/container',

    GET_DATE_CONSOL_CONTAINER: '/container-consol/:containerConsolId/date',

    GET_CONSOL_CONTAINER_PENALTY_LIST:
      '/container-penalty/container-consol/:containerId',

    GET_LIST_FOR_TRANSPORT: '/consol/:consolId/container/list-for-transport',
    GET_BUY_RATE_TRANSFER: '/exchange-rate/buy-rate-transfer',

    GET_CONSOL_TRANSPORT: '/consol-transport'
  },
  SERVICE: {
    UNLINK_TO_SHIPMENT: '/service/container/unlink-to-shipment',
    SERVICE_CONTAINER_PACKAGE: '/service/container/:serviceContainerId/package',
    SERVICE_CONTAINER_DATE: '/service/container-date/:serviceContainerId',
    SERVICE_CONTAINER_PENALTY:
      '/service/container-penalty/container/:serviceContainerId',
    TRANSPORT_SERVICE: '/transport-service',
    LIST_FOR_REDIT_NOTE_AR:
      '/service/ar-invoice/ar-charge-code/list-for-credit-note',
    LIST_FOR_REDIT_NOTE_AP:
      '/service/ap-invoice/ap-charge-code/list-for-credit-note'
  },
  COMPANY: {
    GET_BRANCH_LIST: '/branch',
    UPDATE_COMPANY_AVATAR: '/company-profile/avatar'
  },

  ACCOUNTING: {
    LIST_FOR_REDIT_NOTE_AP:
      '/accounting/ap-invoice/ap-charge-code/list-for-credit-note',
    LIST_FOR_REDIT_NOTE_AR:
      '/accounting/ar-invoice/ar-charge-code/list-for-credit-note'
  },
  BILL: {
    LIST_FOR_REDIT_NOTE_AR:
      '/billing/ar-invoice/ar-charge-code/list-for-credit-note',
    LIST_FOR_REDIT_NOTE_AP:
      '/billing/ap-invoice/ap-charge-code/list-for-credit-note'
  }
};

export { PAGE, SIZE, END_POINTS };
