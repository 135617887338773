import { baseColors } from '../base/colors';

const navbar = {
  styleOverrides: {
    root: {
      backgroundColor: baseColors.white
    }
  }
};

export default navbar;
