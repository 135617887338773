import React, { memo, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import trans from 'translation';
import { KContainer, KGrid, KLabel, KListItem } from 'uikit';

import { IFormData } from './helpers';

const Type = () => {
  const methods = useFormContext<IFormData>();

  const {
    formState: { errors }
  } = methods;

  const onAfterChange = useCallback(() => {
    methods.trigger('type');
  }, [methods]);

  const [isSystem] = useWatch({
    control: methods.control,
    name: ['isSystem']
  });

  return (
    <>
      <KContainer.Fieldset
        title={trans('charge_code_type')}
        required
        marginT="0.5rem"
        paddingT="1rem"
        error={!!errors?.type}
      >
        <KGrid.Container>
          <KGrid.Item xs={4}>
            <Controller
              name="type.0"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('import')}
                    checked={field.value}
                    onAfterChange={onAfterChange}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={4}>
            <Controller
              name="type.1"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('export')}
                    checked={field.value}
                    onAfterChange={onAfterChange}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={4}>
            <Controller
              name="type.2"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('domestic')}
                    checked={field.value}
                    onAfterChange={onAfterChange}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KContainer.Fieldset>

      {!!errors?.type && (
        <KContainer.View marginT="0.5rem">
          <KLabel.Error withIcon>{errors?.type?.message}</KLabel.Error>
        </KContainer.View>
      )}
    </>
  );
};

export default memo(Type);
