import { baseColors } from '../../base/colors';
import pxToRem from '../../functions/pxToRem';

const contained = {
  base: {
    minHeight: pxToRem(30),
    color: baseColors.white,

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(20)} !important`
    }
  },

  primary: {
    backgroundColor: baseColors.primary,

    '&:hover': {
      backgroundColor: baseColors.primary
    }
  },

  secondary: {
    backgroundColor: baseColors.secondary,

    '&:hover': {
      backgroundColor: baseColors.secondary
    }
  }
};

export default contained;
