import { ILibraryParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../core';

export const useCUDEcusClientType = () => {
  return useCUDMutationEnhancer<ILibraryParams>({
    endPoint: ENDPOINTS.ecusClientType(),
    webTable: WebTable.ECUS_CLIENT_TYPE
  });
};
