import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { KForm, KGrid, KInput } from 'uikit';
import * as yup from 'yup';

import { BottomActions } from 'components';
import { useCUDNotesToClient, useResolverForm } from 'hooks';
import trans from 'translation';
import { ValidationUtils } from 'utils';

const schema = yup.object().shape({
  title: ValidationUtils.required(),
  detail: ValidationUtils.required(),
  type: ValidationUtils.required()
});

interface IFormData {
  id?: number | string;
  title: string;
  detail: string;
  type: 'AP_INVOICE' | 'AR_INVOICE' | 'PAYMENT_ADVICE' | 'RECEIVABLE_ADVICE';
}

const NotesToClient = ({
  item,
  type
}: {
  item: any;
  type: 'AP_INVOICE' | 'AR_INVOICE' | 'PAYMENT_ADVICE' | 'RECEIVABLE_ADVICE';
}) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDNotesToClient();

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        id: item?.id,
        title: item?.title ?? '',
        detail: item?.detail ?? '',
        type: type
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      if (isEdit) {
        updateMutation(data);
      } else {
        createMutation(data);
      }
    },
    [createMutation, isEdit, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={12}>
          <Controller
            name="title"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('title')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>
        <KGrid.Item xs={12}>
          <Controller
            name="detail"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('content')}
                  message={error?.message}
                  disabled={item?.isSystem}
                  multiline
                  minRows={5}
                />
              );
            }}
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        btnProps={[
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(NotesToClient);
