import { CustomTooltipProps } from 'ag-grid-react';
import React from 'react';
const Tooltip = (props: CustomTooltipProps & { color: string }) => {
  return (
    <div
      className="custom-tooltip"
      style={{
        backgroundColor: '#fff',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
        border: '1px solid #ccc',
        color: 'black',
        padding: '5px',
        borderRadius: '5px',
        fontSize: '14px'
      }}
    >
      <div>{props.value}</div>
    </div>
  );
};

export default Tooltip;
