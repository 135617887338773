import { IMetadata } from '@dto';
import React, { memo, useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { KGrid, KInput, KForm } from 'uikit';
import * as yup from 'yup';

import { Status, generateOptions, RelatedTab, ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDRelatedParty, onShowClientType } from 'hooks';
import trans from 'translation';
import { RequestUtils, ValidationUtils } from 'utils';

interface IFormData {
  clientType: IMetadata | null;
  ecusClientType: IMetadata | null;
  relatedClient: any;
  status: Status;
}

interface IProps {
  item?: any;
  clientId?: string | number;
  onDelete?: (listId: number[]) => void;
  tabName: string;
}

const schemaClientType = yup.object().shape({
  clientType: ValidationUtils.requiredAny(),
  relatedClient: ValidationUtils.requiredAny()
});

const schemaEcusClientType = yup.object().shape({
  ecusClientType: ValidationUtils.requiredAny(),
  relatedClient: ValidationUtils.requiredAny()
});

const relatedTab = {
  [RelatedTab.Regular]: {
    label: 'client_type',
    name: 'clientType',
    nameId: 'clientTypeId',
    apiURL: ENDPOINTS.clientType(),
    schema: schemaClientType,
    apiParams: (v: number) => ({
      clientTypeIds: v
    })
  },
  [RelatedTab.Ecus]: {
    label: 'ecus_client_type',
    name: 'ecusClientType',
    nameId: 'ecusClientTypeId',
    apiURL: ENDPOINTS.ecusClientType(),
    schema: schemaEcusClientType,
    apiParams: (v: number) => ({
      ecusClientTypeIds: v
    })
  }
};

const RelatedForm = ({ item, onDelete, clientId, tabName }: IProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDRelatedParty(clientId, tabName);

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema: relatedTab[tabName].schema,
    configs: {
      values: {
        clientType: item?.clientType,
        ecusClientType: item?.ecusClientType,
        relatedClient: item?.relatedClient,
        status: item?.status ?? Status.Active
      }
    }
  });

  const handleChangeClient = useCallback(
    (v: any) => {
      methods.setValue('relatedClient', v);
    },
    [methods]
  );

  const onFormValid = useCallback(
    (data: IFormData) => {
      const mParams: any = RequestUtils.normalizeData({
        data,
        idFields: ['clientType', 'ecusClientType', 'relatedClient']
      });
      if (isEdit) {
        updateMutation({
          ...mParams,
          id: item?.id
        });
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, isEdit, item?.id, updateMutation]
  );

  const [clientType, ecusClientType, relatedClient] = useWatch({
    control: methods.control,
    name: ['clientType', 'ecusClientType', 'relatedClient']
  });

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={12}>
          <Controller
            name={relatedTab[tabName]?.name}
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans(relatedTab[tabName]?.label)}
                  onChange={(v: any) => {
                    methods.setValue(relatedTab[tabName]?.name, v);
                    methods.setValue('relatedClient', null);
                  }}
                  inputProps={{
                    message: error?.message,
                    required: true
                  }}
                  apiURL={relatedTab[tabName]?.apiURL}
                  hasEdit
                  onEdit={onShowClientType}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={5}>
          <Controller
            name="relatedClient"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('client_code')}
                  onChange={handleChangeClient}
                  inputProps={{
                    message: error?.message,
                    required: true
                  }}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  apiURL={ENDPOINTS.client()}
                  apiParams={relatedTab[tabName]?.apiParams(
                    clientType?.id || ecusClientType?.id
                  )}
                  hasEdit
                  editURL="client"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={7}>
          <Controller
            name="relatedClient"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('client_name')}
                  onChange={handleChangeClient}
                  inputProps={{
                    message: error?.message,
                    required: true
                  }}
                  apiURL={ENDPOINTS.client()}
                  apiParams={relatedTab[tabName]?.apiParams(
                    clientType?.id || ecusClientType?.id
                  )}
                  hasEdit
                  editURL="client"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            value={relatedClient?.defaultAddress?.countryName ?? ''}
            label={trans('country')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            value={relatedClient?.defaultAddress?.stateName ?? ''}
            label={trans('state')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            value={relatedClient?.defaultAddress?.cityName ?? ''}
            label={trans('city')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={7}>
          <KInput.TextField
            value={relatedClient?.defaultAddress?.fullAddress ?? ''}
            label={trans('address')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={5}>
          <KInput.TextField
            value={relatedClient?.defaultAddress?.unlocoCode ?? ''}
            label={trans('unloco')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            value={relatedClient?.taxId ?? ''}
            label={trans('tax_id')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            value={relatedClient?.website ?? ''}
            label={trans('website')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            value={relatedClient?.status ?? ''}
            label={trans('client_status')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}></KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit && !!onDelete}
        btnProps={[
          {
            onPress: () => onDelete?.([item?.id]),
            disabled: item?.isSystem
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(RelatedForm);
