import { IUploadResourceResponse } from '@request-dto';

import APIManager, { END_POINTS } from '@services';

import { useMutationEnhancer } from '../core';

export const useUploadResource = () => {
  return useMutationEnhancer<IUploadResourceResponse | undefined, FormData>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: END_POINTS.RESOURCE.RESOURCE_UPLOAD,
        method: 'POST',
        body: data,
        configs: {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      });

      return res.data;
    }
  });
};
