import { baseColors } from '../../base/colors';
import pxToRem from '../../functions/pxToRem';

const outlined = {
  base: {
    backgroundColor: baseColors.transparent,
    minHeight: pxToRem(30),
    color: baseColors.white,

    '&:hover': {
      backgroundColor: baseColors.transparent
    },

    '& .material-icon, .material-icons-round, svg': {
      fontSize: `${pxToRem(20)} !important`
    }
  },

  primary: {
    borderColor: baseColors.primary
  },

  secondary: {
    borderColor: baseColors.secondary
  }
};

export default outlined;
