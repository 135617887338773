import {
  IModifyInvoicePaymentModeParams,
  IItemParams,
  IModifyInvoiceTemplate
} from '@request-dto';

import { ArApType, ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDARInvoiceStatus = () => {
  return useCUDMutationEnhancer<IItemParams>({
    endPoint: ENDPOINTS.arInvoiceStatus(),
    webTable: WebTable.AR_INVOICE_STATUS
  });
};

export const useCUDAPInvoiceStatus = () => {
  return useCUDMutationEnhancer<IItemParams>({
    endPoint: ENDPOINTS.apInvoiceStatus(),
    webTable: WebTable.AP_INVOICE_STATUS
  });
};

export const useCUDInvoicePaymentMode = () => {
  return useCUDMutationEnhancer<IModifyInvoicePaymentModeParams>({
    endPoint: ENDPOINTS.invoicePaymentMode(),
    webTable: WebTable.INVOICE_PAYMENT_MODE
  });
};

export const useCUDWipTemplate = (arApType: ArApType) => {
  return useCUDMutationEnhancer<IModifyInvoiceTemplate>({
    endPoint: ENDPOINTS.invoiceTemplate(),
    webTable:
      arApType === ArApType.Ar
        ? WebTable.SELLING_WIP_TEMPLATE
        : WebTable.COSTING_WIP_TEMPLATE
  });
};
