import React from 'react';
import { KListItem, KListItemBaseItemProps, KPopperProps } from 'uikit';

import { UIUtils } from 'utils';

interface IProps extends Omit<KPopperProps, 'content' | 'anchorEl'> {
  btnRef: React.RefObject<HTMLButtonElement>;
  data: KListItemBaseItemProps[];
}

export const usePopperListItem = (props: IProps) => {
  const { btnRef, data, ...rest } = props;

  const onPress = () => {
    UIUtils.popper.open({
      anchorEl: btnRef.current,
      touchOutsideToDismiss: true,
      padding: '0.5rem',
      style: { zIndex: 4 },
      ...rest,
      placement: 'bottom-end',
      content: dismiss => {
        return (
          <KListItem.Base
            alignItems
            data={data.map(i => ({
              ...i,
              onPress: () => {
                (i.onPress as any)?.();
                dismiss();
              }
            }))}
          />
        );
      }
    });
  };

  return { onPress };
};
