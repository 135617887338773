import React, { memo } from 'react';
import { KBreadcrumbs, KColors, KContainer, KImage } from 'uikit';

import trans from 'translation';

const Dashboard = () => {
  return (
    <>
      <KBreadcrumbs title={trans('routes.dashboard')} hasBackIcon={false} />

      <KContainer.View
        center
        dp="flex"
        height="calc(100vh - 122px)"
        background={KColors.white}
      >
        <KImage.Base source={'/image/logo.webp'} />
      </KContainer.View>
    </>
  );
};

export default memo(Dashboard);
