import { snakeCase } from 'lodash';

import trans from 'translation';

import { DimensionUnit, TemperatureUnit, VolumeUnit, WeightUnit } from './enum';

export const generateOptions = <T extends string, TValue extends string>(
  e: {
    [key in T]: TValue;
  },
  needTranslate = true
) => {
  return Object.keys(e).map(i => {
    return {
      key: e[i],
      label: (
        (needTranslate ? trans(`option.${e[i].toLowerCase()}`) : e[i]) ?? ''
      ).toUpperCase()
    };
  });
};

export const OPTION_DIMENSION_UNIT = generateOptions(DimensionUnit, false);

export const OPTION_WEIGHT_UNIT = generateOptions(WeightUnit, false);

export const OPTION_VOLUME_UNIT = generateOptions(VolumeUnit, false);

export const OPTION_TEMPERATURE_UNIT = generateOptions(TemperatureUnit, false);

export const generateNumOptions = <T extends string, TValue extends number>(e: {
  [key in T]: TValue;
}) => {
  return Object.keys(e).map(i => {
    return {
      key: e[i],
      label: trans(`option.${snakeCase(i).toLowerCase()}`).toUpperCase()
    };
  });
};

export const generateValueOptions = <
  T extends string,
  TValue extends string
>(e: {
  [key in T]: TValue;
}) => {
  return Object.values(e).map(i => {
    return trans(`option.${snakeCase(i as any).toLowerCase()}`).toUpperCase();
  });
};
