import React, { memo, useMemo } from 'react';

import KRadio from './Radio';

import { KColors } from '../../constants';
import KContainer from '../Container';
import { KRadioGroupProps } from '../types';
import { TypoHelper } from '../Typography';

const KRadioGroup = (props: KRadioGroupProps) => {
  const { containerStyle, direction, data, noSpacing, ...rest } = props;

  const fontSize = useMemo(
    () => TypoHelper.getKFontSizeByTypo(rest.typo),
    [rest.typo]
  );

  if (direction === 'row') {
    return (
      <KContainer.View row alignItems style={containerStyle}>
        {data.map((i, idx) => {
          const style =
            idx > 0
              ? {
                  marginLeft: fontSize
                }
              : {};

          return (
            <KRadio
              key={`radio-${idx}`}
              {...i}
              {...rest}
              containerStyle={style}
            />
          );
        })}
      </KContainer.View>
    );
  }

  return (
    <KContainer.View style={containerStyle}>
      {data.map((i, idx) => {
        const style = {
          paddingTop: idx === 0 ? 0 : fontSize * 0.5,
          paddingBottom:
            idx === data.length - 1 || noSpacing ? 0 : fontSize * 0.5
        };

        return (
          <KRadio
            key={`radio-${idx}`}
            {...i}
            {...rest}
            containerStyle={style}
          />
        );
      })}
    </KContainer.View>
  );
};

KRadioGroup.defaultProps = {
  activeColor: KColors.primary.normal,
  typo: 'TextNmNormal',
  direction: 'column'
};

export default memo(KRadioGroup);
