import React, { memo } from 'react';

import Category from './Category';
import Stage from './Stage';
import Type from './Type';

const Client = () => {
  return (
    <>
      <Type />
      <Category />
      <Stage />
    </>
  );
};

export default memo(Client);
