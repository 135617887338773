import React, { memo, useCallback } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form';
import { KColors, KContainer, KGrid, KImage, KInput, KLabel } from 'uikit';

import { CalculatorType, ENDPOINTS } from '@constants';
import trans from 'translation';

import { IFormData } from './helpers';

const SubChargeCodes = () => {
  const methods = useFormContext<IFormData>();

  const {
    formState: { errors }
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'subChargeCodes',
    keyName: 'formId'
  });

  const [calculatorType, isSystem] = useWatch({
    control: methods.control,
    name: ['calculatorType', 'isSystem']
  });

  const onAppend = useCallback(async () => {
    const isValid = await methods.trigger('subChargeCodes');
    if (isValid) {
      append({ subChargeCode: undefined, ratio: 0 }, { shouldFocus: false });
    }
  }, [append, methods]);

  return (
    <KContainer.RenderWhen>
      <KContainer.RenderWhen.If
        isTrue={calculatorType === CalculatorType.Composite}
      >
        <KGrid.Item xs={12}>
          <KContainer.Fieldset
            title={trans('sub_charge_code_list')}
            required
            marginT="0.5rem"
            paddingT="1rem"
            error={!!errors?.subChargeCodes}
          >
            <KGrid.Container>
              {fields.map((d, index) => {
                return (
                  <React.Fragment key={d.formId}>
                    <KGrid.Item xs={4}>
                      <KGrid.Container>
                        <KGrid.Item xs={6.8}>
                          <Controller
                            name={`subChargeCodes.${index}.subChargeCode`}
                            control={methods.control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <KInput.Autocomplete
                                  {...field}
                                  label={trans('sub_charge_code')}
                                  onChange={(v: any) => {
                                    methods.setValue(
                                      `subChargeCodes.${index}.subChargeCode`,
                                      v
                                    );
                                    methods.trigger('subChargeCodes');
                                  }}
                                  apiURL={ENDPOINTS.chargeCode()}
                                  apiParams={{
                                    calculatorTypes: [
                                      CalculatorType.Descriptive,
                                      CalculatorType.Value
                                    ]
                                  }}
                                  inputProps={{
                                    message: error?.message
                                  }}
                                  getOptionLabel={(o: any) => o?.code ?? ''}
                                  disabled={isSystem}
                                />
                              );
                            }}
                          />
                        </KGrid.Item>

                        <KGrid.Item xs={3.2}>
                          <Controller
                            name={`subChargeCodes.${index}.ratio`}
                            control={methods.control}
                            render={({ field, fieldState: { error } }) => {
                              return (
                                <KInput.TextField
                                  {...field}
                                  label={trans('with_unit.ratio')}
                                  message={error?.message}
                                  disabled={isSystem}
                                />
                              );
                            }}
                          />
                        </KGrid.Item>

                        <KGrid.Item xs={2}>
                          <KContainer.RenderWhen>
                            <KContainer.RenderWhen.If isTrue={!isSystem}>
                              <KContainer.View row alignItems marginT="0.75rem">
                                {fields.length > 1 && (
                                  <KImage.MuiIcon
                                    icon="CancelOutlined"
                                    color={KColors.secondary.normal}
                                    onPress={() => remove(index)}
                                  />
                                )}

                                {index === fields.length - 1 && (
                                  <KImage.MuiIcon
                                    icon="AddCircleOutline"
                                    color={KColors.primary.normal}
                                    marginL="0.25rem"
                                    onPress={onAppend}
                                  />
                                )}
                              </KContainer.View>
                            </KContainer.RenderWhen.If>
                          </KContainer.RenderWhen>
                        </KGrid.Item>
                      </KGrid.Container>
                    </KGrid.Item>
                  </React.Fragment>
                );
              })}
            </KGrid.Container>
          </KContainer.Fieldset>

          {!!errors?.subChargeCodes && (
            <KContainer.View marginT="0.5rem">
              <KLabel.Error withIcon>
                {errors?.subChargeCodes?.message}
              </KLabel.Error>
            </KContainer.View>
          )}
        </KGrid.Item>
      </KContainer.RenderWhen.If>
    </KContainer.RenderWhen>
  );
};

export default memo(SubChargeCodes);
