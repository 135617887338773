import React, { memo, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { KContainer, KGrid, KLabel, KListItem } from 'uikit';

import trans from 'translation';

import { IFormData } from './helpers';

const Mode = () => {
  const methods = useFormContext<IFormData>();

  const {
    formState: { errors }
  } = methods;

  const onAfterChange = useCallback(
    (v: boolean, mode: 'ocean' | 'air') => {
      if (!v) {
        methods.setValue(
          'unit',
          methods.getValues('unit').map(i => {
            if (i.mode === mode) {
              return {
                ...i,
                isChecked: false,
                data: undefined
              };
            }
            return i;
          })
        );
      }
      methods.trigger('mode');
      methods.setValue('containerTypeSizes', []);
    },
    [methods]
  );

  const [isSystem] = useWatch({
    control: methods.control,
    name: ['isSystem']
  });

  return (
    <>
      <KContainer.Fieldset
        title={trans('charge_code_mode')}
        required
        marginT="0.5rem"
        paddingT="1rem"
        error={!!errors?.mode}
      >
        <KGrid.Container>
          <KGrid.Item xs={6}>
            <Controller
              name="mode.0"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('air')}
                    checked={field.value}
                    onAfterChange={v => onAfterChange(v, 'air')}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <Controller
              name="mode.1"
              control={methods.control}
              render={({ field }) => {
                return (
                  <KListItem.Checkbox
                    {...field}
                    label={trans('ocean')}
                    checked={field.value}
                    onAfterChange={v => onAfterChange(v, 'ocean')}
                    disabled={isSystem}
                  />
                );
              }}
            />
          </KGrid.Item>
        </KGrid.Container>
      </KContainer.Fieldset>

      {!!errors?.mode && (
        <KContainer.View marginT="0.5rem">
          <KLabel.Error withIcon>{errors?.mode?.message}</KLabel.Error>
        </KContainer.View>
      )}
    </>
  );
};

export default memo(Mode);
