import React from 'react';

import { useCUDExchangeRate, useDeleteHelper } from 'hooks';
import trans from 'translation';
import { UIUtils } from 'utils';

import ExchangeRateForm from './Form';

export const onShowExchangeRate = (v?: any, firstToCurrency?: any) => {
  UIUtils.popup.open({
    title: trans('routes.exchange_rate'),
    maxWidth: 'md',
    content: () => (
      <ExchangeRateForm item={v} firstToCurrency={firstToCurrency} />
    )
  });
};

export const useDeleteExchangeRate = () => {
  const {
    deleteMutation: { mutate, isLoading, ...rest }
  } = useCUDExchangeRate();

  const onDelete = useDeleteHelper({
    name: 'routes.exchange_rate',
    mutate
  });

  return { onDelete, deleteLoading: isLoading, ...rest };
};
