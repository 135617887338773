import { IChargeCode } from '@invoice';
import { Box, Popover } from '@mui/material';
import {
  ColDef,
  ColGroupDef,
  IRowDragItem,
  ICellRendererParams
} from 'ag-grid-community';
import React from 'react';

import { CustomButtonComponent } from 'components/SheetData/helper';
import { Prototype } from 'core';
import trans from 'translation';
import { KButton, KChip, KColors, KContainer, KImage, KSwitch } from 'uikit';

const MoreIcon = ({
  value,
  palette
}: {
  value: string[];
  palette: 'warning' | 'primary';
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const Chip = ({ label }: { label: string }) => (
    <Box>
      <KChip
        width="max-content"
        background={KColors.palette[palette].w50}
        brC={KColors.palette[palette].w700}
        label={label}
        textTransform={'uppercase'}
        color={KColors.palette[palette].w700}
      />
    </Box>
  );
  return (
    <KContainer.View>
      <Popover
        id={open ? 'simple-popover' : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <KContainer.View
          padding="1rem"
          dp="flex"
          row
          gap="0.5rem"
          width={450}
          flexW="wrap"
        >
          {value.map((i: string) => (
            <Chip label={i} />
          ))}
        </KContainer.View>
      </Popover>
      <KContainer.View dp="flex" row gap="0.5rem">
        {value.slice(0, 2).map((i: string) => (
          <Chip label={i} />
        ))}
        {value.length > 2 && (
          <KButton.Icon
            icon="MoreHoriz"
            tintColor={KColors.white}
            background={KColors.primary.normal}
            br="x"
            size="sm"
            onPress={handleClick}
          />
        )}
      </KContainer.View>
    </KContainer.View>
  );
};
export const columnDefs = ({
  handleDeleteChargeCode
}: {
  handleDeleteChargeCode: (rowDeleted: IChargeCode[]) => void;
}): ColDef<IChargeCode>[] | ColGroupDef<IChargeCode>[] => {
  return [
    {
      headerCheckboxSelection: true,
      checkboxSelection: true,
      maxWidth: 50,
      pinned: 'left',
      filter: false,
      sortable: false,
      lockVisible: true,
      editable: false,
      colId: 'checkbox'
    },
    {
      colId: 'action',
      pinned: 'left',
      rowDrag: true,
      lockVisible: true,
      headerName: trans('action'),
      cellRenderer: CustomButtonComponent,
      cellRendererParams: {
        onDelete: ({ data }: ICellRendererParams) => {
          handleDeleteChargeCode([data]);
        }
      },
      maxWidth: 98,
      minWidth: 98,
      filter: false,
      sortable: false,
      rowDragText: (params: IRowDragItem) => {
        return params.rowNode?.data.code;
      }
    },
    {
      headerName: trans('charge_code'),
      field: 'code',
      cellClass: 'text-left',
      headerClass: 'text-left'
    },
    {
      headerName: trans('description'),
      field: 'description',
      cellClass: 'text-left',
      headerClass: 'text-left',
      width: 190
    },
    {
      headerName: trans('cont_mode_unit'),
      field: 'conModeUnit',
      width: 306,
      minWidth: 306,
      cellClass: 'text-left',
      headerClass: 'text-left',
      cellRenderer: ({ data }: ICellRendererParams<any>) => {
        const newValue = data?.conModeUnit.split(',');
        return newValue && <MoreIcon value={newValue} palette="warning" />;
      }
    },
    {
      headerName: trans('calculator_type'),
      field: 'calculatorType',
      cellClass: 'text-left',
      headerClass: 'text-left',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        return (
          <KChip
            background={KColors.palette.blue.w50}
            brC={KColors.palette.blue.w600}
            label={value}
            textTransform={'uppercase'}
            color={KColors.palette.blue.w600}
          />
        );
      }
    },
    {
      headerName: trans('container_size'),
      field: 'containerTypeSizes',
      cellClass: 'text-left',
      headerClass: 'text-left',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        const code: string[] = [];
        value?.forEach((i: any) => {
          code.push(i?.code);
        });
        return <MoreIcon value={code} palette="primary" />;
      }
    },
    {
      headerName: trans('sub_charge_code'),
      field: 'subChargeCodes',
      cellClass: 'text-left',
      headerClass: 'text-left',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        const code: string[] = [];
        value?.forEach((i: any) => {
          code.push(i?.subChargeCode?.code);
          return <MoreIcon value={code} palette="primary" />;
        });
      }
    },
    {
      headerName: trans('default_profit_type'),
      field: 'wipType',
      cellClass: 'text-left',
      headerClass: 'text-left',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        return (
          value && (
            <KChip
              background={KColors.palette.blue.w50}
              brC={KColors.palette.blue.w600}
              label={value}
              textTransform={'uppercase'}
              color={KColors.palette.blue.w600}
            />
          )
        );
      }
    },
    {
      headerName: trans('default_profit_margin'),
      field: 'wipValue',
      cellClass: 'text-left',
      headerClass: 'text-left',
      valueFormatter({ value }) {
        return Prototype.number.formatNumber(value);
      }
    },
    {
      headerName: trans('base_unit_price'),
      field: 'baseValue',
      valueFormatter: ({ data, value }) => {
        const currency = data?.currency?.code ?? '';
        return `${currency} - ${Prototype.number.formatNumber(value)}`;
      },
      cellClass: 'text-left',
      headerClass: 'text-left'
    },
    {
      headerName: trans('min_selling_unit_price'),
      field: 'minSelling',
      cellClass: 'text-left',
      headerClass: 'text-left',
      valueFormatter: ({ data, value }) => {
        const currency = data?.currency?.code ?? '';
        return `${currency} - ${Prototype.number.formatNumber(value)}`;
      }
    },
    {
      headerName: trans('markup_type'),
      field: 'markupType',
      cellClass: 'text-left',
      headerClass: 'text-left',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        return (
          <KChip
            background={KColors.palette.blue.w50}
            brC={KColors.palette.blue.w600}
            label={value}
            textTransform={'uppercase'}
            color={KColors.palette.blue.w600}
          />
        );
      }
    },
    {
      headerName: trans('markup_level_1'),
      cellClass: 'text-left',
      headerClass: 'text-left',
      valueFormatter({ value }) {
        return Prototype.number.formatNumber(value);
      },
      field: 'markupLevel1'
    },
    {
      headerName: trans('markup_level_2'),
      cellClass: 'text-left',
      headerClass: 'text-left',
      valueFormatter({ value }) {
        return Prototype.number.formatNumber(value);
      },
      field: 'markupLevel2'
    },
    {
      headerName: trans('markup_level_3'),
      cellClass: 'text-left',
      headerClass: 'text-left',
      valueFormatter({ value }) {
        return Prototype.number.formatNumber(value);
      },
      field: 'markupLevel3'
    },
    {
      headerName: trans('is_system'),
      field: 'isSystem',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        return (
          <KImage.MuiIcon
            icon="CheckCircleOutlineOutlined"
            color={value ? KColors.primary.normal : KColors.palette.gray.w50}
          />
        );
      }
    },
    {
      headerName: trans('status'),
      field: 'status',
      cellRenderer: ({ value }: ICellRendererParams<IChargeCode>) => {
        return <KSwitch id="status" checked={value} />;
      }
    }
  ];
};
