import Card from './Card';
import CardWithAccordion from './CardWithAccordion';
import Fieldset from './Fieldset';
import RenderWhen from './RenderWhen';
import Touchable from './Touchable';
import View from './View';

export default class KContainer {
  static View = View;

  static Card = Card;

  static CardWithAccordion = CardWithAccordion;

  static Touchable = Touchable;

  static RenderWhen = RenderWhen;

  static Fieldset = Fieldset;
}
