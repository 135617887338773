import Base from './Base';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import Radio from './Radio';
import RadioGroup from './RadioGroup';

export default class KListItem {
  static Base = Base;

  static Checkbox = Checkbox;

  static CheckboxGroup = CheckboxGroup;

  static Radio = Radio;

  static RadioGroup = RadioGroup;
}
