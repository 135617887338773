import { makeStyles } from '@mui/styles';
import React, { memo, forwardRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles({
  quillWrapper: {
    '& .ql-toolbar': {
      position: 'sticky',
      top: -14,
      left: 0,
      right: 0,
      backgroundColor: 'white',
      zIndex: 1
    }
  }
});
type Props = {
  initialBody?: string;
} & ReactQuillProps;

const RichText = forwardRef<ReactQuill, Props>(
  ({ initialBody = '', ...rest }: Props, ref) => {
    const classes = useStyles();
    return (
      <ReactQuill
        theme="snow"
        className={classes.quillWrapper}
        scrollingContainer={'#scrolling-container'}
        ref={ref}
        value={initialBody}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote', 'code-block'],
            ['link', 'image', 'video', 'formula'],
            // [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction
            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ font: [] }],
            [{ align: [] }],
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ['clean'] // remove formatting button
          ]
        }}
        {...rest}
      />
    );
  }
);

export default memo(RichText);
