import { IWithOceanAirParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDIncoterm = () => {
  return useCUDMutationEnhancer<IWithOceanAirParams>({
    endPoint: ENDPOINTS.incoterm(),
    webTable: WebTable.INCOTERM
  });
};
