import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import React, { memo, useCallback } from 'react';

import { KColors } from '../../constants';
import { KSwitchProps } from '../types';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 33,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor:
          theme.palette.mode === 'dark'
            ? KColors.primary.dark
            : KColors.primary.normal
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 16,
    height: 16,
    borderRadius: '50%',
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === 'dark' ? KColors.gray.normal : KColors.gray.dark,
    boxSizing: 'border-box'
  }
}));

const KSwitch = (props: KSwitchProps) => {
  const { onChange, ...otherProps } = props;

  const onChangeWrapper = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e.target.checked);
    },
    [onChange]
  );

  return <AntSwitch onChange={onChangeWrapper} {...otherProps} />;
};

export default memo(KSwitch);
