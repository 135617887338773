import React from 'react';
import { KContainer, KGrid, KInput, KListItem } from 'uikit';

import { BottomActions } from 'components';
import trans from 'translation';

const Integration = () => {
  return (
    <>
      <KGrid.Container>
        <KGrid.Item xs={6}>
          <KContainer.Card
            size="nm"
            header={{
              icon: 'InfoOutlined',
              title: trans('email setting')
            }}
          >
            <KGrid.Container>
              <KGrid.Item xs={12}>
                <KInput.Autocomplete
                  label={trans('Provider Name')}
                  getOptionLabel={(o: any) => {
                    return o?.code;
                  }}
                  editURL="client"
                  hasEdit
                />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <KInput.TextField label={trans('User Name')} />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <KInput.TextField label={trans('Pass word')} />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <KInput.TextField label={trans('Protocol')} />
              </KGrid.Item>

              <KGrid.Item xs={4}>
                <KInput.TextField label={trans('Port')} />
              </KGrid.Item>

              <KGrid.Item xs={2}>
                <KListItem.Checkbox label={trans('Enable TLS')} />
              </KGrid.Item>
            </KGrid.Container>
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KContainer.Card
            size="nm"
            header={{
              icon: 'InfoOutlined',
              title: trans('Notification')
            }}
          >
            <KGrid.Container>
              <KGrid.Item xs={12}>
                <KInput.Autocomplete
                  label={trans('Provider Name')}
                  getOptionLabel={(o: any) => {
                    return o?.code;
                  }}
                  editURL="client"
                  hasEdit
                />
              </KGrid.Item>

              <KGrid.Item xs={12}>
                <KInput.TextField label={trans('App ID')} />
              </KGrid.Item>

              <KGrid.Item xs={12}>
                <KInput.TextField label={trans('App Key')} />
              </KGrid.Item>
            </KGrid.Container>
          </KContainer.Card>
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <KContainer.Card
            size="nm"
            header={{
              icon: 'InfoOutlined',
              title: trans('Invoice Setting ')
            }}
          >
            <KGrid.Container>
              <KGrid.Item xs={12}>
                <KInput.Autocomplete
                  label={trans('Provider Name')}
                  getOptionLabel={(o: any) => {
                    return o?.code;
                  }}
                  editURL="client"
                  hasEdit
                />
              </KGrid.Item>

              <KGrid.Item xs={12}>
                <KInput.TextField label={trans('Xero ID / MISA App ID')} />
              </KGrid.Item>

              <KGrid.Item xs={12}>
                <KInput.TextField
                  label={trans('Xero Secret/ MISA Access Code')}
                />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <KInput.TextField
                  label={trans('Xero Account Code / MISA Org_company Code')}
                />
              </KGrid.Item>

              <KGrid.Item xs={6}>
                <KInput.TextField label={trans('Xero Bank Account Code')} />
              </KGrid.Item>
            </KGrid.Container>
          </KContainer.Card>
        </KGrid.Item>
      </KGrid.Container>
      <BottomActions
        btnProps={[
          {
            isLoading: false
          }
        ]}
      />
    </>
  );
};

export default Integration;
