import React from 'react';

import {
  ConsolType,
  ExchangeRateTypeALL,
  ExchangeRateTypeExport,
  ExchangeRateTypeImport,
  InvoiceDate,
  SpendMoneyRequestStatus,
  VolumeUnit,
  WeightUnit,
  generateOptions
} from '@constants';
import { Prototype } from 'core';
import trans from 'translation';
import { KColors, KInput } from 'uikit';

import UIUtils from './ui';

interface ITypes {
  title: string;
  onPress: () => void;
  icon: any;
  color?: any;
}

export const actionType = (types: ITypes) => {
  const { onPress = () => {}, title, icon, color } = types;
  return {
    title: trans(title),
    icon: {
      name: icon,
      color: color || KColors.primary.normal
    },
    onPress
  };
};

export const formatSummary = (data: any = {}) => {
  Object.keys(data).map(
    x => (data[x] = Prototype.number.formatNumber(data[x]))
  );
  return data;
};

export const showAlertWarning = (
  message:
    | 'message.all_invoice_will_be_deleted'
    | 'message.all_charge_codes_will_be_deleted'
    | string,
  callbackSubmit: () => void
) => {
  {
    UIUtils.alert.open({
      message: trans(message),
      buttons: [
        {
          title: trans('yes'),
          kind: 'danger',
          color: KColors.warning.normal,
          onPress: () => callbackSubmit()
        },
        {
          title: trans('no')
        }
      ]
    });
  }
};

export const useBasicAction = () => {
  const actionAdd = (onPress: () => void, title?: string) => {
    return actionType({
      title: trans(title ?? 'new_statement_of_account'),
      icon: 'AddBox',
      onPress
    });
  };

  const actionSendStatus = (onPress: () => void, title?: string) => {
    return actionType({
      title: trans(title ?? 'send_status'),
      icon: 'ArrowUpward',
      onPress
    });
  };

  const actionBackStatus = (onPress: () => void, title?: string) => {
    return actionType({
      title: trans(title ?? 'back_status'),
      icon: 'ArrowDownward',
      onPress
    });
  };

  const actionCancel = (onPress: () => void) => {
    return actionType({
      title: trans('cancel'),
      icon: 'CancelOutlined',
      onPress,
      color: KColors.secondary.normal
    });
  };

  const actionPrint = (onPress: () => void) => {
    return actionType({
      title: trans('export_soa'),
      icon: 'CloudDownloadOutlined',
      onPress
    });
  };

  const actionSpitPayment = (onPress: () => void) => {
    return actionType({
      title: trans('split_payment'),
      icon: 'CallSplit',
      onPress
    });
  };
  return {
    actionAdd,
    actionSendStatus,
    actionBackStatus,
    actionCancel,
    actionPrint,
    actionSpitPayment
  };
};

export const convertHtml = (content: string) => {
  return <div dangerouslySetInnerHTML={{ __html: content }}></div>;
};

export const optionExchangeRateType = (dataType: ConsolType) => {
  const isImport = dataType === ConsolType.Import;
  if (isImport) {
    return generateOptions(ExchangeRateTypeImport);
  }

  const isExport = dataType === ConsolType.Export;
  if (isExport) {
    return generateOptions(ExchangeRateTypeExport);
  }

  return generateOptions(InvoiceDate);
};

export const exchangeRateDateValue = (
  v: ExchangeRateTypeALL,
  isEdit: boolean,
  timeImport: any,
  timeExport: any,
  createdAt: any
) => {
  let newDate: any = Prototype.date.now();
  if (v === ExchangeRateTypeALL.ETA) {
    // import
    newDate = Prototype.date.toLocal(timeImport);
  } else if (v === ExchangeRateTypeALL.ETD) {
    // export
    newDate = Prototype.date.toLocal(timeExport);
  } else if (v === ExchangeRateTypeALL.INVOICE_DATE) {
    if (isEdit) {
      newDate = createdAt;
    }
  }

  return newDate;
};

export const showNoteContent = (item?: any) => {
  UIUtils.popup.open({
    title: trans('general_note'),
    maxWidth: 'md',
    content: () => (
      <KInput.TextField multiline minRows={4} value={item?.noteContent ?? ''} />
    )
  });
};

// Anything to m3
export const convertUnitVolume = {
  [VolumeUnit.M3]: 1,
  [VolumeUnit.L]: 1 / 1000,
  [VolumeUnit.ML]: 1 / 1000000
};

// Anything to ton
export const convertUnitWeight = {
  [WeightUnit.TON]: 1,
  [WeightUnit.KG]: 1 / 1000,
  [WeightUnit.G]: 1 / 1000000
};

export const commonSpendReceiveMoney = (status: SpendMoneyRequestStatus) => {
  const valueStatus = {
    fullAction: 'fullAction',
    draftAndCancel: 'draftAndCancel',
    cancel: 'cancel',
    noAction: 'noAction'
  };

  const statusUpdate = {
    [SpendMoneyRequestStatus.DRAFT]: valueStatus.fullAction,
    [SpendMoneyRequestStatus.ACTIVE]: valueStatus.cancel,
    [SpendMoneyRequestStatus.PAYMENT_REQUEST]: valueStatus.draftAndCancel,
    [SpendMoneyRequestStatus.CANCEL]: valueStatus.noAction
  };

  return {
    isDraft: statusUpdate[status] === valueStatus.fullAction,
    isActive: statusUpdate[status] === valueStatus.cancel,
    isPaymentRequest: statusUpdate[status] === valueStatus.draftAndCancel,
    isCancel: statusUpdate[status] === valueStatus.noAction
  };
};

export const typedMemo: <M>(c: M) => M = React.memo;
