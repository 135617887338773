import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
  IndeterminateCheckBoxOutlined
} from '@mui/icons-material';
import { clone } from 'lodash';
import React, { memo, useCallback } from 'react';

import { KColors } from '../../constants';
import KContainer from '../Container';
import KLabel from '../Label';
import { KCheckboxProps } from '../types';

const KCheckbox = (props: KCheckboxProps) => {
  const {
    activeColor,
    typo,
    checked,
    label,
    containerStyle,
    disabled,
    onChange,
    onAfterChange,
    withMinus,
    size = 20,
    ...rest
  } = props;

  const toggle = useCallback(() => {
    const currentChecked = clone(checked);
    onChange?.(!currentChecked);
    onAfterChange?.(!currentChecked);
  }, [checked, onChange, onAfterChange]);

  const renderCheckbox = useCallback(() => {
    if (checked) {
      const Icon = withMinus ? IndeterminateCheckBoxOutlined : CheckBoxOutlined;
      return (
        <Icon
          sx={{
            width: size,
            height: size,
            color: activeColor,
            opacity: disabled ? 0.3 : 1
          }}
        />
      );
    } else {
      return (
        <CheckBoxOutlineBlankOutlined
          sx={{ width: size, height: size, opacity: disabled ? 0.3 : 1 }}
        />
      );
    }
  }, [activeColor, checked, disabled, size, withMinus]);

  const Wrapper = disabled ? KContainer.View : KContainer.Touchable;

  return (
    <Wrapper onPress={toggle} row alignItems style={containerStyle} {...rest}>
      {renderCheckbox()}

      <KLabel.Text typo={typo} marginL="0.5rem" style={{ lineHeight: 1 }}>
        {label}
      </KLabel.Text>
    </Wrapper>
  );
};

KCheckbox.defaultProps = {
  activeColor: KColors.primary.normal,
  typo: 'TextNmNormal'
};

export default memo(KCheckbox);
