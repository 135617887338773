import { IBranchOfStaff } from '@dto';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { KButton, KForm, KGrid, KInput, KListItem, KPicker } from 'uikit';
import * as yup from 'yup';

import { ENDPOINTS, Status, WorkingType, generateOptions } from '@constants';
import { Prototype } from 'core';
import {
  onShowDepartment,
  onShowJobTitle,
  useAssignBranchToStaff,
  useResolverForm,
  useUpdateStaffBranch
} from 'hooks';
import trans from 'translation';
import { ValidationUtils } from 'utils';

import { useRemoveFromBranchHelper } from '../helpers';

interface IProps {
  item?: IBranchOfStaff;
  staffId?: string;
}

interface IFormData {
  branch: any;
  department: any;
  jobTitle: any;
  workingType: WorkingType;
  status: Status;
  startDate: string;
  endDate: string;
  isDefault: boolean;
  isBranchManager: boolean;
}

const schema = yup.object().shape({
  startDate: ValidationUtils.date(),
  endDate: ValidationUtils.date()
});

const AssignBranch = ({ item, staffId }: IProps) => {
  const isEdit = !!item;

  const { mutate: createMutation, isLoading: createLoading } =
    useAssignBranchToStaff();
  const { mutate: updateMutation, isLoading: updateLoading } =
    useUpdateStaffBranch();

  const { onRemoveFromBranch, isLoading: deleteLoading } =
    useRemoveFromBranchHelper({ id: staffId } as any);

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        branch: item?.branch ?? null,
        department: item?.department ?? null,
        jobTitle: item?.jobTitle ?? null,
        workingType: item?.workingType ?? WorkingType.InHouse,
        status: item?.status ?? Status.Active,
        startDate: item?.startDate ?? '',
        endDate: item?.endDate ?? '',
        isDefault: item?.isDefault ?? false,
        isBranchManager: item?.isBranchManager ?? false
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      const { branch, department, jobTitle, startDate, endDate, ...rest } =
        data;
      const mParams = {
        ...rest,
        branchId: branch.id,
        departmentId: department?.id,
        jobTitleId: jobTitle?.id,
        staffId,
        id: item?.id,
        startDate: startDate ? Prototype.date.formatDB(startDate) : null,
        endDate: endDate ? Prototype.date.formatDB(endDate) : null
      };
      if (isEdit) {
        updateMutation(mParams as any);
      } else {
        createMutation(mParams as any);
      }
    },
    [createMutation, isEdit, item?.id, staffId, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container alignItems="center">
        <KGrid.Item xs={12}>
          <Controller
            name="branch"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('branch')}
                  apiURL={ENDPOINTS.branch()}
                  inputProps={{
                    required: true
                  }}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="department"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('department')}
                  apiURL={ENDPOINTS.department()}
                  hasEdit
                  onEdit={onShowDepartment}
                  onAddNew={onShowDepartment}
                  hasAddNew
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="jobTitle"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('job_title')}
                  apiURL={ENDPOINTS.jobTitle()}
                  hasEdit
                  onEdit={onShowJobTitle}
                  onAddNew={onShowJobTitle}
                  hasAddNew
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="workingType"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('working_type')}
                  options={generateOptions(WorkingType)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="startDate"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KPicker.Date
                  {...field}
                  label={trans('start_date')}
                  minDate={Prototype.date.now()}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="endDate"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KPicker.Date
                  {...field}
                  label={trans('end_date')}
                  minDate={Prototype.date.now()}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdBy"
            value={item?.createdUsername ?? ''}
            label={trans('created_by')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdAt"
            value={Prototype.date.formatDT(item?.createdAt, '')}
            label={trans('created_at')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedBy"
            value={item?.updatedUsername ?? ''}
            label={trans('updated_by')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedAt"
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            label={trans('updated_at')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="isDefault"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('staff.choose_as_default_branch')}
                  checked={field.value}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="isBranchManager"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('branch_manager')}
                  checked={field.value}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6} container justifyContent="flex-end">
          {isEdit && (
            <KButton.Solid
              title={trans('delete')}
              icon="Delete"
              kind="secondary"
              marginR="0.5rem"
              onPress={() => onRemoveFromBranch([item])}
              isLoading={deleteLoading}
              disabled={createLoading || updateLoading}
            />
          )}

          <KButton.Solid
            icon="Save"
            type="submit"
            title={trans('save')}
            isLoading={createLoading || updateLoading}
            disabled={deleteLoading}
          />
        </KGrid.Item>
      </KGrid.Container>
    </KForm>
  );
};

export default memo(AssignBranch);
