import { usePrevious } from '@dwarvesf/react-hooks';
import { isEmpty } from 'lodash';
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
  useTransition
} from 'react';
import { useMount } from 'uikit-common';

import { KColors } from '../../constants';
import KContainer from '../Container';
import KLabel from '../Label';
import { KTabInstance, KTabProps } from '../types';
import { KSpacingValue } from '../Typography';

const TAB_WIDTH = 80;

const KTabBase = memo(
  forwardRef<KTabInstance, KTabProps>((props, ref) => {
    const {
      tabs = [],
      initialIndex,
      onChangeTab,
      tintColor,
      activeBackground,
      textColor,
      inactiveTextColor,
      spacing = '1rem',
      transition,
      kind = 'indicator',
      ...rest
    } = props;

    const indicatorRef = React.createRef<HTMLDivElement>();
    const tabRef = useRef(tabs.map(() => React.createRef<HTMLDivElement>()));

    const [tabIndex, setTabIndex] = useState(initialIndex ?? 0);

    const prevIndex = usePrevious(initialIndex);

    const [, startTransition] = useTransition();

    useMount(() => {
      if (!isEmpty(tabs) && tabIndex >= 0 && indicatorRef.current) {
        const tabWidth =
          tabRef.current?.[tabIndex]?.current?.offsetWidth ?? TAB_WIDTH;
        indicatorRef.current.style.width = `${tabWidth}px`;
      }
    });

    useEffect(() => {
      if (initialIndex && initialIndex !== prevIndex) {
        setTabIndex(initialIndex);
      }
    }, [initialIndex, prevIndex]);

    const onChangeWrapper = useCallback(
      (index: number, noAction: boolean = false) => {
        if (index !== tabIndex) {
          setTimeout(() => {
            if (indicatorRef.current) {
              const tabWidth =
                tabRef.current?.[index]?.current?.offsetWidth ?? TAB_WIDTH;

              let width = 0;
              if (index > 0) {
                for (let i = 0; i < index; i++) {
                  width +=
                    (tabRef.current?.[i]?.current?.offsetWidth ?? 0) +
                    (spacing ? KSpacingValue[spacing] : 0);
                }
              }
              indicatorRef.current.style.transition =
                'transform 0.5s, width 0.5s';
              indicatorRef.current.style.transform = `translate(${width}px, 0px)`;
              indicatorRef.current.style.width = `${tabWidth}px`;
            }

            setTabIndex(index);

            if (transition) {
              startTransition(() => {
                if (!noAction) {
                  onChangeTab?.(index);
                }
              });
            } else {
              if (!noAction) {
                onChangeTab?.(index);
              }
            }
          }, 0);
        }
      },
      [indicatorRef, onChangeTab, spacing, tabIndex, transition]
    );

    useImperativeHandle(ref, () => ({
      onChange: onChangeWrapper
    }));

    return (
      <KContainer.Card
        dp="flex"
        row
        alignItems
        paddingV={kind === 'background' ? 0 : '0.5rem'}
        marginV="0.5rem"
        height={43}
        noShadow
        overflow
        overflowY="hidden"
        br={0}
        {...rest}
      >
        {tabs.map((i, idx) => {
          const { key, label } = i;
          const isActive = idx === tabIndex;
          const color = isActive ? textColor : inactiveTextColor;

          return (
            <React.Fragment key={key}>
              {idx !== 0 && spacing && (
                <KContainer.View width={KSpacingValue[spacing]} />
              )}

              <KContainer.Touchable
                ref={tabRef.current[idx]}
                paddingV="0.5rem"
                paddingH="0.75rem"
                minW={TAB_WIDTH}
                onPress={onChangeWrapper.bind(null, idx, false)}
                dp="flex"
                center
                disabled={i.disabled}
                height={kind === 'background' ? '100%' : undefined}
                background={
                  isActive && kind === 'background'
                    ? activeBackground
                    : KColors.transparent
                }
              >
                <KLabel.Text
                  typo="TextNmMedium"
                  color={color}
                  textTransform="uppercase"
                  textAlign
                >
                  {label}
                </KLabel.Text>
              </KContainer.Touchable>
            </React.Fragment>
          );
        })}

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If isTrue={kind === 'indicator'}>
            <KContainer.View
              ref={indicatorRef}
              style={{
                position: 'absolute',
                bottom: 3,
                transition: 'transform 500ms'
              }}
              height={3}
              width={TAB_WIDTH}
              background={tintColor}
            />
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KContainer.Card>
    );
  })
);

const KTabSolid = memo(
  forwardRef<KTabInstance, KTabProps>((props, ref) => {
    return (
      <KTabBase
        background={KColors.opacity.primary[13]}
        tintColor={KColors.primary.normal}
        textColor={KColors.black}
        inactiveTextColor={KColors.gray.light}
        ref={ref}
        {...props}
      />
    );
  })
);

const KTabWhite = memo(
  forwardRef<KTabInstance, KTabProps>((props, ref) => {
    return (
      <KTabBase
        background={KColors.white}
        tintColor={KColors.primary.normal}
        textColor={KColors.white}
        inactiveTextColor={KColors.hexToRgba(KColors.black, 0.54)}
        activeBackground={KColors.primary.normal}
        ref={ref}
        {...props}
      />
    );
  })
);

const KTabTransparent = memo(
  forwardRef<KTabInstance, KTabProps>((props, ref) => {
    return (
      <KTabBase
        background={KColors.transparent}
        tintColor={KColors.primary.normal}
        textColor={KColors.black}
        inactiveTextColor={KColors.opacity.black[54]}
        ref={ref}
        {...props}
      />
    );
  })
);

const KTabMain = memo(
  forwardRef<KTabInstance, KTabProps>((props, ref) => {
    return (
      <KTabSolid
        marginT={0}
        background={KColors.white}
        kind="background"
        activeBackground={KColors.primary.normal}
        textColor={KColors.white}
        ref={ref}
        {...props}
      />
    );
  })
);

const KTabSubMain = memo(
  forwardRef<KTabInstance, KTabProps>((props, ref) => {
    return (
      <KTabSolid
        br={0}
        marginV={0}
        background={KColors.primary.normal}
        textColor={KColors.white}
        tintColor={KColors.white}
        inactiveTextColor={KColors.palette.gray.w25}
        // @ts-ignore
        marginT={-12}
        // @ts-ignore
        marginH={-12}
        ref={ref}
        {...props}
      />
    );
  })
);

export {
  KTabBase,
  KTabSolid,
  KTabTransparent,
  KTabWhite,
  KTabMain,
  KTabSubMain
};
