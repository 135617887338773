import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { Status, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDJobTitle } from 'hooks';
import trans from 'translation';
import { KGrid, KInput, KListItem, KForm } from 'uikit';
import { ValidationUtils } from 'utils';

interface IFormData {
  code: string;
  name: string;
  status: Status;
}

interface Props {
  item?: any;
  onDelete?: (listId: number[]) => void;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required()
});

const JobTitleForm = ({ item, onDelete }: Props) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDJobTitle();

  const isEdit = !!item;

  const methods = useResolverForm<IFormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? '',
        name: item?.name ?? '',
        status: item?.status ?? Status.Active
      }
    }
  });

  const onFormValid = useCallback(
    (data: IFormData) => {
      if (isEdit) {
        updateMutation({
          ...data,
          id: item?.id
        });
      } else {
        createMutation(data);
      }
    },
    [createMutation, isEdit, item?.id, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={2.4}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('code')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={7.2}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('name')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4} alignSelf={'center'}>
          <KListItem.Radio
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            marginT="0.75rem"
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit && !!onDelete}
        btnProps={[
          {
            onPress: () => onDelete?.([item?.id]),
            disabled: item?.isSystem
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(JobTitleForm);
