import React, { Fragment, useCallback, useMemo } from 'react';
import {
  Controller,
  useFieldArray,
  useFormContext,
  useWatch
} from 'react-hook-form';
import {
  KColors,
  KContainer,
  KGrid,
  KImage,
  KInput,
  KLabel,
  KListItem
} from 'uikit';

import { ENDPOINTS, FreeDayImport, generateOptions } from '@constants';
import trans from 'translation';
import { AutocompleteUtils } from 'utils';

import { listIdPort, newPolPod } from './Form';

const Pod = () => {
  const methods = useFormContext<any>();

  const [podFromPolPorts, port, podFromPolAll] = useWatch({
    control: methods.control,
    name: ['podFromPolPorts', 'port', 'podFromPolAll']
  });

  const apiParamsPort = useMemo(
    () => ({
      // clientTypeCodes: isOcean ? ClientType.SeaPort : ClientType.AirPort,
      excludeIds: listIdPort(podFromPolPorts, port)
    }),
    [podFromPolPorts, port]
  );

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: 'podFromPolPorts',
    keyName: 'formId'
  });

  const handleRemoveRowSelectedPol = useCallback(
    (index: number) => {
      remove(index);
    },
    [remove]
  );

  const handleAddRowSelectedPol = useCallback(async () => {
    const isValid = await methods.trigger('podFromPolPorts');
    if (isValid) {
      append(newPolPod);
    }
  }, [append, methods]);

  const lastRow = useCallback(
    (index: number) => podFromPolPorts?.length === index,
    [podFromPolPorts?.length]
  );

  const handlePodFromPolAll = useCallback(
    (v: boolean) => {
      methods.setValue('podFromPolAll', v);
      if (!v) {
        methods.setValue('podFreeDayStart', '');
        methods.setValue('podNoFreeDay', '');
        methods.clearErrors(['podFreeDayStart', 'podNoFreeDay']);
      }
    },
    [methods]
  );

  return (
    <KContainer.Card
      size="nm"
      height="100%"
      header={{
        title: trans('as_unloco_port_of_discharge'),
        border: true
      }}
    >
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <KLabel.Text>{trans('from_unloco_port_of_loading')}</KLabel.Text>
        </KGrid.Item>

        <KGrid.Item xs={1}>
          <Controller
            name="podFromPolAll"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('all')}
                  checked={field.value}
                  onChange={handlePodFromPolAll}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2}></KGrid.Item>

        <KGrid.Item xs={3.5}>
          <Controller
            name="podFreeDayStart"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('pod_free_date_start')}
                  required={podFromPolAll}
                  disabled={!podFromPolAll}
                  options={generateOptions(FreeDayImport)}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.5}>
          <Controller
            name="podNoFreeDay"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required={podFromPolAll}
                  disabled={!podFromPolAll}
                  message={error?.message}
                  type="number"
                  label={trans('pod_no_of_free_day')}
                />
              );
            }}
          />
        </KGrid.Item>

        {!podFromPolAll &&
          fields.map((item: any, index: number) => {
            return (
              <Fragment key={item?.formId}>
                <KGrid.Item xs={6}>
                  <Controller
                    name={`podFromPolPorts[${index}].ports`}
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KInput.Autocomplete
                          {...field}
                          multiple
                          inputProps={{
                            required: true,
                            message: error?.message
                          }}
                          label={trans('selected_unloco_port_of_loading')}
                          apiURL={ENDPOINTS.unloco()}
                          apiParams={apiParamsPort}
                          getOptionLabel={AutocompleteUtils.unlocoFormatter}
                          disabled={!lastRow(index + 1)}
                        />
                      );
                    }}
                  />
                </KGrid.Item>

                <KGrid.Item xs={3}>
                  <Controller
                    name={`podFromPolPorts[${index}].pickupEndFreeDayStart`}
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KInput.TextField
                          {...field}
                          options={generateOptions(FreeDayImport)}
                          label={trans('pod_free_start_date')}
                          message={error?.message}
                          required
                          disabled={!lastRow(index + 1)}
                        />
                      );
                    }}
                  />
                </KGrid.Item>

                <KGrid.Item xs={2.3}>
                  <Controller
                    name={`podFromPolPorts[${index}].noOfFreeDay`}
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => {
                      return (
                        <KInput.TextField
                          {...field}
                          type="number"
                          label={trans('pod_no_of_free_day')}
                          disabled={!lastRow(index + 1)}
                          required
                          message={error?.message}
                        />
                      );
                    }}
                  />
                </KGrid.Item>
                <KGrid.Item xs={0.7} alignSelf={'end'}>
                  {podFromPolPorts?.length > 1 && (
                    <KImage.MuiIcon
                      cursor={'pointer'}
                      color={KColors.secondary.normal}
                      icon="HighlightOff"
                      onClick={() => {
                        handleRemoveRowSelectedPol(index);
                      }}
                    />
                  )}
                  {lastRow(index + 1) && (
                    <KImage.MuiIcon
                      cursor={'pointer'}
                      color={KColors.primary.normal}
                      icon="AddCircleOutlineOutlined"
                      onClick={() => {
                        handleAddRowSelectedPol();
                      }}
                    />
                  )}
                </KGrid.Item>
              </Fragment>
            );
          })}
      </KGrid.Container>
    </KContainer.Card>
  );
};

export default Pod;
