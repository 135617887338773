import axios from 'axios';
import OneSignal from 'react-onesignal';

export default class NotificationManager {
  user: any;
  userId?: string;
  constructor(user: any) {
    this.user = user;
    this.userId = user?.account?.id?.toString();
  }
  initialize = async () => {
    console.log('START_INIT_ONESIGNAL');
    const onesignalAppId = process.env.REACT_APP_ONESIGNAL_APP_ID;
    try {
      if (onesignalAppId) {
        if (this.user) {
          await OneSignal.init({
            appId: onesignalAppId,
            notificationClickHandlerMatch: 'origin',
            notificationClickHandlerAction: 'focus'
            // allowLocalhostAsSecureOrigin: true
          });

          const isEnabled = await OneSignal.isPushNotificationsEnabled();

          if (isEnabled) {
            console.log('INIT ONESIGNAL SUCCESSFULLY');
            await NotificationManager.setExternalUserId(this.userId);

            OneSignal.addListenerForNotificationOpened(event => {
              console.log(event, 'event');
              // window.location.href =
              //   'http://localhost:3000/admin/ocean-freight/consolidation';
            });
          }

          // OneSignal.addListenerForNotificationOpened(event => {
          //   console.log('event1234');
          //   // window.location.href =
          //   //   'http://localhost:3000/admin/ocean-freight/consolidation';
          // });
        } else {
          console.log('USER NOT FOUND');
        }
      } else {
        console.log('APP ID NOT FOUND');
      }
    } catch (error) {
      console.log('INIT ONESIGNAL FAILED');
    }
  };

  static setExternalUserId = async (externalUserId: any) => {
    const playerId = await OneSignal.getUserId();

    const url = `https://onesignal.com/api/v1/players/${playerId}`;
    const appId = process.env.REACT_APP_ONESIGNAL_APP_ID; // Thay YOUR_APP_ID bằng App ID của bạn từ OneSignal
    const apiKey = process.env.REACT_APP_ONESIGNAL_API_KEY; // Thay YOUR_REST_API_KEY bằng REST API Key của bạn từ OneSignal
    const payload = {
      app_id: appId,
      external_user_id: externalUserId
    };

    try {
      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Basic ${apiKey}`,
          'Content-Type': 'application/json'
        }
      });
      console.log('External User ID set successfully:', response.data);
    } catch (error) {
      console.error('Error setting external user ID:', error);
    }
  };

  static sendNotification = async (externalId: any, messageContent: any) => {
    // Use for Testing
    const apiKey = process.env.REACT_APP_ONESIGNAL_API_KEY;
    const appId = process.env.REACT_APP_ONESIGNAL_APP_ID;

    const url = 'https://onesignal.com/api/v1/notifications';
    const headers = {
      'Content-Type': 'application/json; charset=utf-8',
      Authorization: `Basic ${apiKey}`
    };
    const payload = {
      app_id: appId,
      include_external_user_ids: [externalId],
      contents: { en: messageContent }
    };

    try {
      const response = await axios.post(url, payload, { headers });
      console.log(response, 'response');
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  static removeExternalUserId = async () => {
    await OneSignal.removeExternalUserId();
  };
}
