import { IModifyBankAccount } from '@request-dto';
import { IItemProps } from '@ui';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { KListItem, KInput, KGrid, KForm } from 'uikit';
import * as yup from 'yup';

import { ENDPOINTS, Status, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useCUDCompanyBankAccount, useResolverForm } from 'hooks';
import { useDeleteCompanyBankAccount } from 'pages/Company/helper';
import trans from 'translation';
import { ValidationUtils } from 'utils';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

const schema = yup.object().shape({
  accountNumber: ValidationUtils.required(),
  accountName: ValidationUtils.required(),
  bankName: ValidationUtils.required(),
  bankBranch: ValidationUtils.required(),
  currency: ValidationUtils.requiredAny()
});

const BankAccount = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation, isLoading: addLoading },
    updateMutation: { mutate: updateMutation, isLoading: updateLoading }
  } = useCUDCompanyBankAccount();

  const { onDelete, deleteLoading } = useDeleteCompanyBankAccount();

  const isEdit = !!item;

  const localUser = StorageEnhance.get(STORAGE_KEYS.user);

  const methods = useResolverForm<IModifyBankAccount>({
    schema: schema,
    configs: {
      values: {
        id: item?.id,
        accountNumber: item?.accountNumber ?? '',
        accountName: item?.accountName ?? '',
        bankName: item?.bankName ?? '',
        bankBranch: item?.bankBranch ?? '',
        currency: item?.currency,
        bsbNumber: item?.bsbNumber ?? '',
        swiftCode: item?.swiftCode ?? '',
        branch: item?.branch ?? localUser?.branch,
        status: item?.status ?? Status.Active,
        isDefault: item?.isDefault ?? false
      }
    }
  });

  const onSubmit = useCallback(
    (data: IModifyBankAccount) => {
      const { currency, branch, ...rest } = data;
      const mParams = {
        currencyId: currency?.id,
        branchId: branch?.id,
        ...rest
      };
      if (isEdit) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, isEdit, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onSubmit)}>
      <KGrid.Container>
        <KGrid.Item xs={6}>
          <Controller
            name="accountNumber"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  message={error?.message}
                  label={trans('account_number')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="accountName"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  message={error?.message}
                  label={trans('account_name')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="bankName"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('bank_name')}
                  disabled={isEdit}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="bankBranch"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('bank_branch')}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="bsbNumber"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField {...field} label={trans('bsb_number')} />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="swiftCode"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField {...field} label={trans('swift_code')} />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="currency"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  apiURL={ENDPOINTS.currency()}
                  label={trans('currency')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="branch"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  apiURL={ENDPOINTS.branch()}
                  label={trans('branch_code')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="branch"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  inputProps={{
                    required: true,
                    message: error?.message
                  }}
                  apiURL={ENDPOINTS.branch()}
                  label={trans('branch_name')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="isDefault"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  label={trans('choose_as_default')}
                  checked={field.value}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit && !!onDelete}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            isLoading: deleteLoading
          },
          {
            isLoading: addLoading || updateLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(BankAccount);
