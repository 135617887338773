import {
  ArApType,
  BankingPaymentSource,
  ENDPOINTS,
  QUERY_KEYS,
  WebTable
} from '@constants';
import APIManager from '@services';
import { usePageCUDMutationEnhancer, useQueryEnhancer } from 'hooks/core';

export const useFetchSpendReceiveMoney = ({
  source,
  id
}: {
  source: BankingPaymentSource;
  id?: string;
}) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.spendReceiveMoney, id],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.bankingPayment({
          source,
          path: ':id',
          query: { id }
        })
      });

      return res.data;
    },
    enabled: !!id
  });
};

const SOURCE_PARAMS = {
  [BankingPaymentSource.Spend]: {
    webTable: WebTable.SPEND_MONEY,
    queyKey: QUERY_KEYS.spendBankingPayment,
    arApType: ArApType.Ap
  },
  [BankingPaymentSource.Receive]: {
    webTable: WebTable.RECEIVE_MONEY,
    queyKey: QUERY_KEYS.receiveBankingPayment,
    arApType: ArApType.Ar
  }
};

export const useCUDBankingPayment = ({
  source,
  isFromWip
}: {
  source: BankingPaymentSource;
  isFromWip: boolean;
}) => {
  const mParams = SOURCE_PARAMS[source] || {};
  const endPoint = isFromWip
    ? ENDPOINTS.bankingPayment({
        source,
        path: '/from-wip'
      })
    : ENDPOINTS.bankingPayment({
        source
      });
  return usePageCUDMutationEnhancer({
    mutationKeys: [[mParams.queyKey], [QUERY_KEYS.spendReceiveMoney]],
    endPoint,
    webTable: mParams.webTable,
    queryKey: mParams.queyKey
  });
};

export const useCUDSpendBankingPayment = ({ moneyId }: { moneyId: string }) => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.bankingPayment({
      source: BankingPaymentSource.Spend,
      path: '/:moneyId/split',
      query: { moneyId }
    }),
    webTable: WebTable.BANKING_PAYMENT_SPEND,
    queryKey: QUERY_KEYS.spendBankingPayment
  });
};
