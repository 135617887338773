import { IRouting } from '@dto';
import { IAssignTrip } from '@request-dto';

import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  UIDataType,
  WebTable,
  tableRef
} from '@constants';
import APIManager from '@services';
import { UIUtils } from 'utils';

import {
  useCUDMutationEnhancer,
  useMutationEnhancer,
  useQueryEnhancer
} from '../core';

export const useFetchRoutingList = (
  id?: number | string,
  category = UIDataType.Cs
) => {
  return useQueryEnhancer<IRouting[]>({
    queryKey: [
      category === UIDataType.Cs
        ? QUERY_KEYS.consolRouting
        : QUERY_KEYS.shipmentRouting,
      id ? parseInt(id as string) : null
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url:
          category === UIDataType.Cs
            ? ENDPOINTS.consolRouting('', { consolId: id })
            : ENDPOINTS.shipmentRouting('', { shipmentId: id }),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'routingOrder:ASC',
          includeFields: [
            'shippingLine',
            'airline',
            'vessel',
            'type',
            'pod',
            'pol',
            'oblReleasedType',
            'hblReleasedType',
            'shipment',
            'podTimezone',
            'polTimezone'
          ]
        }
      });

      return res.data?.data ?? [];
    },
    enabled: !!id
  });
};

export const useCUDRouting = (
  id?: string | number,
  category = UIDataType.Cs
) => {
  const options = {
    endPoint:
      category === UIDataType.Cs
        ? ENDPOINTS.consolRouting('', { consolId: id })
        : ENDPOINTS.shipmentRouting('', { shipmentId: id }),
    webTable:
      category === UIDataType.Cs
        ? WebTable.CONSOL_ROUTING
        : WebTable.SHIPMENT_ROUTING
  };

  return useCUDMutationEnhancer<any>(options);
};

export const useAssignTrip = () => {
  return useMutationEnhancer<boolean | undefined, IAssignTrip>({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: ENDPOINTS.shipmentRouting('/assign-trip', {
          shipmentId: data.shipmentId
        }),
        method: 'POST',
        showToast: true,
        body: data
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.SHIPMENT_ROUTING]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};
