import React, { useState, memo, useMemo } from 'react';

import { ENDPOINTS } from '@constants';
import { useUser } from 'hooks';
import { useDocumentTitle } from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { KContainer, KTab, KBreadcrumbs, KGrid, KInput, KColors } from 'uikit';

import General from './General';
import Integration from './Integration';
import TermsOfCondition from './TermsOfCondition';

const Setting = () => {
  const { staffPermission, branch: _branch } = useUser();
  useDocumentTitle('System Settings | Setting');
  const [branch, setBranch] = useState<any>(_branch);
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = useMemo(
    () => [
      {
        key: 'general',
        label: trans('tab.general'),
        hasAccordion: true
      },
      {
        key: 'integration',
        label: trans('tab.integration')
      },
      {
        key: 'termsCondition',
        label: trans('terms_of_condition')
      }
    ],
    []
  );

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.system_settings'), trans('routes.setting')]}
      />

      <KContainer.View padding="0.75rem">
        <KGrid.Container>
          <KGrid.Item xs={6}>
            <KInput.Autocomplete
              name="branchCode"
              label={trans('branch_code')}
              getOptionLabel={(o: any) => o?.code ?? ''}
              value={branch}
              onChange={(v: any) => setBranch(v)}
              apiURL={ENDPOINTS.branch()}
              disabled={staffPermission?.isBranchAdmin}
            />
          </KGrid.Item>

          <KGrid.Item xs={6}>
            <KInput.Autocomplete
              name="branchName"
              label={trans('branch_name')}
              value={branch}
              onChange={(v: any) => setBranch(v)}
              apiURL={ENDPOINTS.branch()}
              disabled={staffPermission?.isBranchAdmin}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KTab.Solid
              tabs={tabs}
              onChangeTab={setTabIndex}
              br={0}
              marginV={0}
              background={KColors.primary.normal}
              textColor={KColors.white}
              inactiveTextColor={KColors.normal.normal}
            />
          </KGrid.Item>

          <KGrid.Item xs={12}>
            <KContainer.View overflowY="auto">
              <KTab.Panel value={tabIndex} index={0}>
                <General branch={branch} />
              </KTab.Panel>

              <KTab.Panel value={tabIndex} index={1}>
                <Integration />
              </KTab.Panel>

              <KTab.Panel value={tabIndex} index={2}>
                <TermsOfCondition branchId={branch?.id} />
              </KTab.Panel>
            </KContainer.View>
          </KGrid.Item>
        </KGrid.Container>
      </KContainer.View>
    </>
  );
};

export default memo(Setting);
