import React, { memo, useMemo } from 'react';
import { KContainer, KLabel } from 'uikit';

import { WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { useLocalTable } from 'hooks';
import trans from 'translation';

interface IItem {
  rowNumber: number;
  error: any;
  value: any;
}

interface IProps {
  data: IItem[];
}

const ErrorList = ({ data }: IProps) => {
  const columns = useMemo(() => {
    return [
      {
        label: trans('record_number'),
        name: 'rowNumber'
      },
      {
        label: trans('from_currency_code'),
        name: 'value.fromCurrencyCode'
      },
      {
        label: trans('from_currency_name'),
        name: 'value.fromCurrencyName'
      },
      {
        label: trans('to_currency_code'),
        name: 'value.toCurrencyCode'
      },
      {
        label: trans('to_currency_name'),
        name: 'value.toCurrencyName'
      },
      {
        label: trans('buying_rates_cash'),
        name: 'value.buyRateCast'
      },
      {
        label: trans('buying_rates_transfer'),
        name: 'value.buyRateTransfer'
      },
      {
        label: trans('selling_rates'),
        name: 'value.sellRate'
      }
    ].map(i => {
      return {
        ...i,
        options:
          i.name === 'rowNumber'
            ? undefined
            : {
                customBodyRender: (v: any, rowData: any) => {
                  const item = data?.[rowData.rowIndex];
                  const error = item?.error?.[i.name.slice(6)];

                  return (
                    <>
                      {!!v && <KLabel.Paragraph>{v}</KLabel.Paragraph>}
                      {!!error && <KLabel.Error>{error}</KLabel.Error>}
                    </>
                  );
                }
              }
      };
    });
  }, [data]);

  const table = useLocalTable({
    name: WebTable.EXCHANGE_RATE_IMPORT,
    otherOptions: {
      tableBodyMaxHeight: '35vh',
      selectableRows: 'none'
    },
    showFilter: false,
    columns,
    data
  });

  return (
    <KContainer.View marginT="0.75rem">
      <DataTable {...table} showRightActions={false} />
    </KContainer.View>
  );
};

export default memo(ErrorList);
