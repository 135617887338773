import { VSection } from '@dto';
import React, { memo } from 'react';
import { KContainer, KColors, KImage, KLabel } from 'uikit';

import trans from 'translation';

interface Props {
  item: VSection;
  width?: string;
  setItem?: (item: VSection) => void;
}
const SectionItem = ({ item, width = 'calc(100%/3)', setItem }: Props) => {
  return (
    <KContainer.Touchable
      width={width}
      onPress={() => {
        setItem?.(item);
      }}
    >
      <KContainer.View
        width={'100%'}
        dp="inline-flex"
        height={'7.5rem'}
        padding={'1.5rem'}
      >
        <KImage.Base
          source={item.img}
          width={35}
          height={30}
          paddingH={'1.75rem'}
          dp="flex"
          center
          background={KColors.primary.normal}
        />

        <KContainer.View
          background={KColors.opacity.primary[23]}
          dp="inline-flex"
          alignItems="center"
          width={'50%'}
        >
          <KLabel.Text typo="TextMdNormal" paddingL={'1rem'}>
            {trans(item.title)}
          </KLabel.Text>
        </KContainer.View>
        <KContainer.View
          width={'20%'}
          dp="flex"
          center
          background={KColors.opacity.primary[23]}
          brTR={'2x'}
          brBR={'2x'}
        >
          <KImage.MuiIcon
            icon="ArrowForwardIosOutlined"
            size={30}
            color={KColors.primary.normal}
          />
        </KContainer.View>
      </KContainer.View>
    </KContainer.Touchable>
  );
};

export default memo(SectionItem);
