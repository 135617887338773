/* eslint-disable max-lines */
import { IChargeCode } from '@invoice';
import { difference } from 'lodash';
import React, { memo, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { KContainer } from 'uikit';

import { Status, TableName } from '@constants';
import SheetData from 'components/SheetData';
import trans from 'translation';
import { UIUtils } from 'utils';

import { columnDefs } from './config';

import { onShowQuotationChargeCodeList } from '../../helpers';

interface IProps {
  chargeCodes: any[];
  isSystem?: boolean;
}

const ChargeCode = ({ chargeCodes, isSystem }: IProps) => {
  const methods = useFormContext<any>();

  const handleDeleteChargeCode = useCallback(
    (rowsDeleted: IChargeCode[]) => {
      const newChargeCode = difference(chargeCodes, rowsDeleted);
      methods.setValue('chargeCodes', newChargeCode);
      methods.setValue(
        'chargeCodeIds',
        newChargeCode.map(v => v?.id)
      );
    },
    [chargeCodes, methods]
  );

  const [isAir, isOcean, chargeCodeIds, type] = useWatch({
    control: methods.control,
    name: ['isAir', 'isOcean', 'chargeCodeIds', 'type']
  });

  const onAddChargeCode = useCallback(
    (v: any[]) => {
      methods.setValue('chargeCodes', [...chargeCodes, ...v]);
      methods.setValue(
        'chargeCodeIds',
        [...chargeCodes, ...v].map(o => o?.id)
      );
      UIUtils.popup.dismiss();
    },
    [chargeCodes, methods]
  );
  return (
    <KContainer.Card
      header={{
        icon: 'ErrorOutlineOutlined',
        title: trans('charge_code')
      }}
    >
      <SheetData<IChargeCode>
        tableName={TableName.INVOICE_TEMPLATE}
        data={chargeCodes ?? []}
        columnDefs={columnDefs({ handleDeleteChargeCode })}
        onDelete={handleDeleteChargeCode}
        onAdd={
          isSystem
            ? undefined
            : () =>
                onShowQuotationChargeCodeList(
                  {
                    status: Status.Active,
                    isAir: isAir || undefined,
                    isOcean: isOcean,
                    isImport: type[0],
                    isExport: type[1],
                    isDomestic: type[2],
                    excludeIds: chargeCodeIds,
                    includeFields: ['currency']
                  },
                  onAddChargeCode
                )
        }
      />
    </KContainer.Card>
  );
};

export default memo(ChargeCode);
