import React, { memo, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { KContainer, KGrid, KInput, KLabel, KListItem } from 'uikit';

import { ENDPOINTS } from '@constants';
import trans from 'translation';

import { IFormData, UNIT_SECTION } from './helpers';

import { onShowChargeCodeUnit } from '../../helpers';

const defaultUnit = [
  ...UNIT_SECTION.OCEAN.map(i => ({
    mode: 'ocean' as any,
    isChecked: false,
    data: '',
    containerMode: i
  })),
  ...UNIT_SECTION.AIR.map(i => ({
    mode: 'air' as any,
    isChecked: false,
    data: '',
    containerMode: i
  }))
];

const Unit = () => {
  const methods = useFormContext<IFormData>();

  const {
    formState: { errors }
  } = methods;

  const [mode, isSystem, unit] = useWatch({
    control: methods.control,
    name: ['mode', 'isSystem', 'unit']
  });

  const isBillOfLading = unit[0].isChecked;

  const renderUnitSection = useCallback(
    (section: keyof typeof UNIT_SECTION) => {
      const initIndex = section === 'OCEAN' ? 1 : 5;

      return (
        <KGrid.Container key={section}>
          {UNIT_SECTION[section].map((i, idx) => {
            const name = `unit.${initIndex + idx}.isChecked` as any;
            const unitName = `unit.${initIndex + idx}.data` as any;

            return (
              <KGrid.Item xs={3} key={name}>
                <KContainer.View
                  row
                  alignItems
                  marginT="0.5rem"
                  marginB="0.25rem"
                >
                  <Controller
                    name={name}
                    control={methods.control}
                    render={({ field }) => {
                      return (
                        <KListItem.Checkbox
                          {...field}
                          label={trans(`option.${i.toLowerCase()}`)}
                          checked={field.value}
                          containerStyle={{ marginTop: '0.5rem' }}
                          onAfterChange={v => {
                            if (!v) {
                              methods.setValue(unitName, undefined);
                            }
                            methods.trigger('unit');
                          }}
                          disabled={isSystem || isBillOfLading}
                        />
                      );
                    }}
                  />

                  <KContainer.View flex marginL="0.75rem">
                    <Controller
                      name={unitName}
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => {
                        return (
                          <KInput.Autocomplete
                            {...field}
                            label={trans(`${i.toLowerCase()}_unit`)}
                            onChange={(v: any) => {
                              methods.setValue(unitName, v);
                              methods.trigger('unit');
                            }}
                            inputProps={{
                              message: error?.message
                            }}
                            apiURL={ENDPOINTS.chargeCodeUnit()}
                            hasEdit
                            onEdit={onShowChargeCodeUnit}
                            disabled={!methods.getValues(name) || isSystem}
                          />
                        );
                      }}
                    />
                  </KContainer.View>
                </KContainer.View>
              </KGrid.Item>
            );
          })}
        </KGrid.Container>
      );
    },
    [isBillOfLading, isSystem, methods]
  );

  return (
    <>
      <KContainer.Fieldset
        title={trans('charge_code_unit')}
        required
        marginT="0.5rem"
        error={!!errors.unit}
      >
        <Controller
          name="unit.0.isChecked"
          control={methods.control}
          render={({ field }) => {
            return (
              <KListItem.Checkbox
                {...field}
                label={trans('bill_of_lading')}
                checked={field.value}
                containerStyle={{
                  marginTop: '0.5rem',
                  marginBottom: '0.25rem'
                }}
                onChange={() => {
                  methods.setValue('unit', [
                    {
                      isChecked: !field.value,
                      containerMode: ''
                    },
                    ...defaultUnit
                  ]);
                }}
                onAfterChange={() => methods.trigger('unit')}
                disabled={isSystem}
              />
            );
          }}
        />

        <KContainer.RenderWhen limit={2}>
          <KContainer.RenderWhen.If isTrue={mode[1]}>
            {renderUnitSection('OCEAN')}
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If isTrue={mode[0]}>
            {renderUnitSection('AIR')}
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KContainer.Fieldset>

      {!!errors?.unit && (
        <KContainer.View marginT="0.5rem">
          <KLabel.Error withIcon>{errors?.unit?.message}</KLabel.Error>
        </KContainer.View>
      )}
    </>
  );
};

export default memo(Unit);
