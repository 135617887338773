import {
  IModifyBillLadingParams,
  IModifyFreightOtherParams
} from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDPaymentType = () => {
  return useCUDMutationEnhancer<IModifyFreightOtherParams>({
    endPoint: ENDPOINTS.paymentMode(),
    webTable: WebTable.PAYMENT_MODE
  });
};

export const useCUDFreightPayment = () => {
  return useCUDMutationEnhancer<IModifyFreightOtherParams>({
    endPoint: ENDPOINTS.freightPayment(),
    webTable: WebTable.FREIGHT_PAYMENT
  });
};

export const useCUDBillLadingReleasedType = () => {
  return useCUDMutationEnhancer<IModifyBillLadingParams>({
    endPoint: ENDPOINTS.billLadingReleasedType(),
    webTable: WebTable.BILL_LADING_RELEASED_TYPE
  });
};

export const useCUDLegRoutingType = () => {
  return useCUDMutationEnhancer<IModifyFreightOtherParams>({
    endPoint: ENDPOINTS.legRoutingType(),
    webTable: WebTable.LEG_ROUTING_TYPE
  });
};

export const useCUDPenaltyType = () => {
  return useCUDMutationEnhancer<IModifyFreightOtherParams>({
    endPoint: ENDPOINTS.penaltyType(),
    webTable: WebTable.FREIGHT_OTHER_PENALTY
  });
};

export const useCUDServiceLevel = () => {
  return useCUDMutationEnhancer<IModifyFreightOtherParams>({
    endPoint: ENDPOINTS.serviceLevel(),
    webTable: WebTable.SERVICE_LEVEL
  });
};
