import { IBranch } from '@dto';
import React, { memo } from 'react';
import { KContainer, KLabel, KColors, KChip, KListItem, KImage } from 'uikit';

import { useUser } from 'hooks';
import trans from 'translation';

interface Props {
  index: number;
  isChecked: boolean;
  onPress: (index: number) => void;
  item: IBranch;
}

const BranchItem = ({ index, isChecked, onPress, item }: Props) => {
  const { user } = useUser();

  return (
    <KContainer.Card
      width="48%"
      background={KColors.opacity.primary[13]}
      onPress={() => onPress(index)}
    >
      <KContainer.View dp="inline-flex" justifyContent="space-between">
        <KContainer.View dp="flex" row alignItems gap="0.5rem">
          <KImage.MuiIcon
            icon="LanguageOutlined"
            size={60}
            color={KColors.primary.normal}
          />

          <KContainer.View>
            <KLabel.Text typo="TextNmMedium">{item.name}</KLabel.Text>

            <KLabel.Paragraph color={KColors.primary.normal} numberOfLines={2}>
              {item.fullAddress}
            </KLabel.Paragraph>
          </KContainer.View>
        </KContainer.View>

        <KContainer.View
          dp="flex"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <KContainer.View dp="flex" alignItems="flex-end" gap="0.25rem">
            {item.isDefault && (
              <KChip
                textTransform="uppercase"
                label={trans('default')}
                background={KColors.opacity.blue[47]}
                color={KColors.blue.dark}
                paddingH="1.25rem"
                paddingT="0.25rem"
                width={'6rem'}
                brW={0}
              />
            )}

            {item.id === user?.branch?.id && (
              <KChip
                label="CURRENT"
                paddingL="1.25rem"
                paddingR="1.25rem"
                paddingT="0.25rem"
                width={'6rem'}
                brW={0}
                style={{ fontWeight: 400 }}
              />
            )}
          </KContainer.View>

          <KListItem.Radio name="isChecked" checked={isChecked} />
        </KContainer.View>
      </KContainer.View>
    </KContainer.Card>
  );
};

export default memo(BranchItem);
