import { IStaff } from '@dto';
import React, { useCallback } from 'react';
import { KColors } from 'uikit';

import { ResourceType, StaffStatus } from '@constants';
import {
  useChangeStaffStatus,
  useDeleteStaffs,
  usePreDeleteStaffs,
  useRemoveStaffFromBranch,
  useRevokeStaffRoles,
  useUpdateAvatarStaff
} from 'hooks';
import trans from 'translation';
import { UIUtils } from 'utils';

import AssignBranch from './Details.Right/AssignBranch';

const key = 'staff';

export interface IUseStaffParams {
  id: string;
}

export const useStaffStatus = (status: StaffStatus) => {
  let background, color, brC;
  switch (status) {
    case StaffStatus.Inactive:
      brC = '#A86C13';
      color = '#A86C13';
      background = KColors.hexToRgba('#A86C13', 0.49);
      break;

    case StaffStatus.Leave:
      brC = KColors.gray.normal;
      color = KColors.gray.normal;
      background = KColors.hexToRgba(KColors.gray.normal, 0.28);
      break;

    case StaffStatus.Active:
    default:
      brC = KColors.primary.normal;
      color = KColors.primary.normal;
      background = KColors.hexToRgba(KColors.primary.normal, 0.28);
      break;
  }

  return { background, color, brC };
};

export const useDeleteHelper = () => {
  const { mutateAsync: preMutateAsync } = usePreDeleteStaffs();
  const { mutate, ...rest } = useDeleteStaffs();

  const onDelete = useCallback(
    async (data: any[]) => {
      const ids = data.map(i => i.id);
      const res = await preMutateAsync(ids);
      const message = res
        ? trans(`${key}.message.delete_admin`, { count: data.length })
        : trans(`${key}.message.delete`, { count: data.length });

      UIUtils.alert.open({
        message,
        buttons: [
          {
            title: trans('delete'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(ids)
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [mutate, preMutateAsync]
  );

  return { onDelete, ...rest };
};

export const useUpdateAvatarHelper = (id?: string) => {
  const { mutate, ...rest } = useUpdateAvatarStaff();

  const onUpdateAvatar = useCallback(
    (e: any) => {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('resourceType', ResourceType.Avatar);
      mutate(
        {
          id,
          formData
        } as any,
        {
          onSuccess: () => {
            e.target.value = null;
          }
        }
      );
    },
    [id, mutate]
  );

  return { onUpdateAvatar, ...rest };
};

export const useChangeStaffStatusHelper = (id?: string) => {
  const { mutate, ...rest } = useChangeStaffStatus();

  const onChangeStatus = useCallback(
    (v: StaffStatus) => {
      UIUtils.popper.dismiss();
      if (v === StaffStatus.Leave) {
        UIUtils.alert.open({
          message: trans(`${key}.message.leave`),
          buttons: [
            {
              title: trans('ok'),
              kind: 'danger',
              color: KColors.danger.normal,
              onPress: () => mutate({ id, status: v } as any)
            },
            {
              title: trans('cancel')
            }
          ]
        });
      } else {
        mutate({ id, status: v } as any);
      }
    },
    [id, mutate]
  );

  return { onChangeStatus, ...rest };
};

export const useAssignBranchHelper = (staffId: string) => {
  const onAssignBranch = useCallback(
    (item?: any) => {
      UIUtils.popup.open({
        title: !!item
          ? trans(`${key}.edit_working_branch`)
          : trans(`${key}.assign_branch_to_staff`),
        maxWidth: 'md',
        content: () => <AssignBranch item={item} staffId={staffId} />
      });
    },
    [staffId]
  );

  return { onAssignBranch };
};

export const useRemoveFromBranchHelper = (staff?: IStaff) => {
  const { mutate, isLoading } = useRemoveStaffFromBranch(staff?.id);

  const onRemoveFromBranch = useCallback(
    (data: any[]) => {
      const ids = data.map(i => i.id);

      UIUtils.alert.open({
        message: trans(`${key}.message.remove_from_branch`, {
          count: data.length,
          staffName: staff?.fullName,
          branchName: data.map(i => i.branch.name || '').join(', ')
        }),
        buttons: [
          {
            title: trans('ok'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate(ids)
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [mutate, staff?.fullName]
  );

  return { onRemoveFromBranch, isLoading };
};

export const useRevokeRoleHelpers = (
  staffId: string | number,
  branchId: number
) => {
  const { mutate, isLoading } = useRevokeStaffRoles();

  const onRevokeRoles = useCallback(
    (data: any[]) => {
      const ids = data.map(i => i.roleId);

      UIUtils.alert.open({
        message: trans(`${key}.message.revoke_role`, {
          count: data.length
        }),
        buttons: [
          {
            title: trans('ok'),
            kind: 'danger',
            color: KColors.danger.normal,
            onPress: () => mutate({ roleIds: ids, staffId, branchId } as any)
          },
          {
            title: trans('cancel')
          }
        ]
      });
    },
    [branchId, mutate, staffId]
  );

  return { onRevokeRoles, isLoading };
};
