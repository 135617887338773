import { useEffect } from 'react';

export const useDocumentTitle = (title: string) => {
  useEffect(() => {
    setDocumentTitle(title);
  }, [title]);
};

export const setDocumentTitle = (title: string) => {
  if (typeof title === 'string' && title.trim().length > 0) {
    document.title = title.trim();
  }
};
