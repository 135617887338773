export default {
  citizen_id_s: 'Citizen ID',
  social_insurance_book: 'Social Insurance Book / Superannuation Membership',
  emergency_phone_number: 'Emergency Phone Number',
  emergency_relationship: 'Emergency Relationship',
  emergency_name: 'Emergency Name',
  assign_branch_to_staff: 'Assign Branch To Staff',
  view_branch_detail: 'View Branch Detail',
  edit_working_branch: 'Edit Working Branch',
  remove_from_branch: 'Remove From Branch',
  choose_as_default_branch: 'Choose As Default Branch',

  message: {
    delete: 'Are you sure want to delete this staff?',
    delete_plural: 'Are you sure want to delete these staffs?',
    leave: 'Are you sure this staff already left?',
    delete_admin:
      'This staff is a branch administrator or a branch manager. Are you sure would like to delete this staff?',
    delete_admin_plural:
      'Some staffs are branch administrators or branch managers. Are you sure would like to delete all these staffs?',
    remove_from_branch:
      'Are you sure to remove staff {{staffName}} from branch {{branchName}}?',
    remove_from_branch_plural:
      'Are you sure to remove staff {{staffName}} from these branch {{branchName}}?',
    revoke_role: 'Are you sure to revoke this role from staff?',
    revoke_role_plural: 'Are you sure to revoke these roles from staff?'
  }
};
