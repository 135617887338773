import React, { memo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { KGrid, KInput, KLabel } from 'uikit';

import { ENDPOINTS } from '@constants';
import trans from 'translation';

import { IFormData } from './helpers';

const Account = () => {
  const methods = useFormContext<IFormData>();

  const {
    formState: { errors }
  } = methods;

  const [isSystem] = useWatch({
    control: methods.control,
    name: ['isSystem']
  });

  return (
    <>
      <KGrid.Container>
        <KGrid.Item xs={6}>
          <Controller
            name="account.0"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('revenue_account')}
                  getOptionLabel={(o: any) => o?.displayName ?? ''}
                  apiURL={ENDPOINTS.chartOfAccount()}
                  disabled={isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="account.1"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('cost_account')}
                  getOptionLabel={(o: any) => o?.displayName ?? ''}
                  apiURL={ENDPOINTS.chartOfAccount()}
                  disabled={isSystem}
                />
              );
            }}
          />
        </KGrid.Item>
      </KGrid.Container>

      {!!errors?.account && (
        <KLabel.Error>{errors?.account?.message}</KLabel.Error>
      )}
    </>
  );
};

export default memo(Account);
