import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { KGrid, KInput } from 'uikit';

import { Status, generateOptions } from '@constants';
import { Prototype } from 'core';
import trans from 'translation';

interface IProps {
  item?: any;
}

const FormFooter = ({ item }: IProps) => {
  const methods = useFormContext<any>();

  return (
    <>
      <KGrid.Item xs={2.4}>
        <Controller
          name="status"
          control={methods.control}
          render={({ field }) => {
            return (
              <KInput.TextField
                {...field}
                label={trans('status')}
                options={generateOptions(Status)}
              />
            );
          }}
        />
      </KGrid.Item>

      <KGrid.Item xs={2.4}>
        <KInput.TextField
          name="createdBy"
          label={trans('created_by')}
          value={item?.createdUsername ?? ''}
          disabled
        />
      </KGrid.Item>

      <KGrid.Item xs={2.4}>
        <KInput.TextField
          name="createdAt"
          label={trans('created_at')}
          value={Prototype.date.formatDT(item?.createdAt, '')}
          disabled
        />
      </KGrid.Item>

      <KGrid.Item xs={2.4}>
        <KInput.TextField
          name="updatedBy"
          label={trans('updated_by')}
          value={item?.updatedUsername ?? ''}
          disabled
        />
      </KGrid.Item>

      <KGrid.Item xs={2.4}>
        <KInput.TextField
          name="updatedAt"
          label={trans('updated_at')}
          value={Prototype.date.formatDT(item?.updatedAt, '')}
          disabled
        />
      </KGrid.Item>
    </>
  );
};

export default memo(FormFooter);
