import React, { useCallback, memo, useMemo, useState } from 'react';

import { ENDPOINTS, TableName, WebTable } from '@constants';
import DataTable from 'containers/DataTable';
import { Prototype } from 'core';
import {
  useDataTable,
  useGlobalTable,
  useNewButton,
  useActionButton
} from 'hooks';
import { useDocumentTitle } from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { KContainer, KLabel, KBreadcrumbs, KColors, KPicker } from 'uikit';
import { TableUtils, UIUtils } from 'utils';

import { onShowExchangeRate, useDeleteExchangeRate } from './helpers';
import Import from './Import';

const ExchangeRate = () => {
  useDocumentTitle('Library | Exchange Rate');

  const { onDelete } = useDeleteExchangeRate();

  const [filterDate, setFilterDate] = useState<any>(Prototype.date.now());

  const columnsFunc = useCallback(
    (data: any[]) => {
      return [
        {
          label: trans('action'),
          name: 'action',
          options: TableUtils.options.actionWithMenuList(data, item => [
            {
              title: trans('edit'),
              icon: 'EditOutlined',
              onPress: () => onShowExchangeRate(item)
            },
            {
              title: trans('delete'),
              icon: 'Delete',
              onPress: () => onDelete?.([item])
            }
          ])
        },
        {
          label: trans('from_currency_code'),
          name: 'fromCurrency.code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data[rowData.rowIndex];
                    onShowExchangeRate(item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('from_currency_name'),
          name: 'fromCurrency.name',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data?.[rowData.rowIndex];
                    onShowExchangeRate(item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('to_currency_code'),
          name: 'toCurrency.code',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data?.[rowData.rowIndex];
                    onShowExchangeRate(item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('to_currency_name'),
          name: 'toCurrency.name',
          options: {
            customBodyRender: (v: string, rowData: any) => {
              return (
                <KLabel.Text
                  onPress={() => {
                    const item = data?.[rowData.rowIndex];
                    onShowExchangeRate(item);
                  }}
                >
                  {v}
                </KLabel.Text>
              );
            }
          }
        },
        {
          label: trans('buying_rates_transfer'),
          name: 'buyRateTransfer',
          options: TableUtils.options.number()
        },
        {
          label: trans('selling_rates'),
          name: 'sellRate',
          options: TableUtils.options.number()
        }
      ];
    },
    [onDelete]
  );

  const otherParams = useMemo(() => {
    return {
      includeFields: ['toCurrency', 'fromCurrency', 'updatedAt'],
      createdDate: Prototype.date.formatDB(filterDate)
    };
  }, [filterDate]);

  const mappedFields = useMemo(() => {
    return {
      'fromCurrency.code': 'fromCurrencyCode',
      'fromCurrency.name': 'fromCurrencyName',
      'toCurrency.code': 'toCurrencyCode',
      'toCurrency.name': 'toCurrencyName'
    };
  }, []);

  const table = useDataTable({
    name: WebTable.EXCHANGE_RATE,
    tableName: TableName.EXCHANGE_RATE,
    otherOptions: {
      tableBodyMaxHeight: '50vh'
    },
    apiURL: ENDPOINTS.exchangeRate(),
    showFilter: false,
    otherParams,
    mappedFields,
    columnsFunc
  });

  useGlobalTable(table);

  const onAdd = useCallback(() => {
    const firstToCurrency = table?.data?.data?.[0]?.toCurrency;
    onShowExchangeRate(undefined, firstToCurrency);
  }, [table.data]);

  const newButton = useNewButton({
    onPress: onAdd
  });

  const actions = useMemo(() => {
    const arr: any = [
      {
        title: trans('import'),
        icon: {
          name: 'ImportExportOutlined',
          color: KColors.primary.normal
        },
        onPress: () => {
          UIUtils.popup.open({
            title: trans('import'),
            maxWidth: 'lg',
            content: () => <Import />
          });
        }
      }
    ];

    return arr;
  }, []);

  const actionButton = useActionButton({
    actions,
    isHorizontal: true,
    order: 1
  });

  const updatedAtValue = table?.data?.['extra']?.updatedAt;

  return (
    <>
      <KBreadcrumbs
        hasBackIcon={false}
        breadcrumbs={[trans('routes.library'), trans('routes.exchange_rate')]}
      />

      <KContainer.Card margin="0.75rem" marginT="0.25rem">
        <DataTable
          {...table}
          leftNode={
            <KContainer.View minW={'200px'}>
              <KPicker.Date
                onChange={v => {
                  if (v?.isValid()) {
                    setFilterDate(v);
                  }
                }}
              />
            </KContainer.View>
          }
          onAdd={onAdd}
          onDelete={onDelete}
        />

        {updatedAtValue && (
          <KLabel.Text>
            {trans('update_on')}{' '}
            <KLabel.Text
              color={KColors.primary.normal}
              italic
              typo="TextXMdNormal"
            >
              {Prototype.date.formatDT(updatedAtValue)}
            </KLabel.Text>
          </KLabel.Text>
        )}
      </KContainer.Card>

      {actionButton}

      {newButton}
    </>
  );
};

export default memo(ExchangeRate);
