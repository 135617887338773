import {
  KButtonOutline,
  KButtonSolid,
  KButtonText,
  KButtonTransparent
} from './Base';
import KButtonIcon from './Icon';

export default class KButton {
  static Outline = KButtonOutline;

  static Solid = KButtonSolid;

  static Text = KButtonText;

  static Transparent = KButtonTransparent;

  static Icon = KButtonIcon;
}
