import pxToRem from '../functions/pxToRem';

const borders = {
  radius: {
    x: pxToRem(4),
    '2x': pxToRem(8)
  }
};

export default borders;
