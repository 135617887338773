import { AdvanceSearch, BaseResponse } from '@dto';
import { snakeCase } from 'lodash';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import { ENDPOINTS, Status, TableName } from '@constants';
import APIManager from '@services';
import Filter from 'containers/DataTable/Filter';
import trans from 'translation';
import { useCancelablePromise } from 'uikit-common';
import { UIUtils } from 'utils';

export const useFilterTable = (
  ref: RefObject<HTMLButtonElement>,
  tableName: TableName,
  onSubmit?: (v: any) => void
) => {
  const mounted = useRef(false);
  //   const ref = React.createRef<HTMLButtonElement>();
  const [searchConditions, setSearchConditions] = useState<any[]>([]);
  const [advanceSearch, setAdvanceSearch] = useState<AdvanceSearch[]>([]);
  const { promise, handleNewPromise } = useCancelablePromise();

  const initializeFetchFilter = useCallback(async () => {
    handleNewPromise(
      APIManager.request({
        url: ENDPOINTS.advanceSearch(),
        body: {
          tableName,
          status: Status.Active
        }
      })
    );
    const res: BaseResponse<AdvanceSearch[]> = await promise.current;
    if (!res.error && res.data) {
      setAdvanceSearch(
        res.data.map(i => ({
          ...i,
          label: trans(snakeCase(i?.columnName ?? ''))
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleNewPromise, promise]);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      initializeFetchFilter();
    }
  }, [initializeFetchFilter]);

  const onFilter = useCallback(() => {
    UIUtils.popper.dismiss();
    setTimeout(
      () =>
        UIUtils.popper.open({
          anchorEl: ref.current,
          placement: 'bottom',
          content: dismiss => (
            <Filter
              dismiss={dismiss}
              advanceSearch={advanceSearch}
              searchConditions={searchConditions}
              onSubmit={v => {
                setSearchConditions(v);
                onSubmit?.(v);
              }}
              //   filterZIndex={filterZIndex}
            />
          ),
          maxW: 700,
          style: {
            zIndex: 100
          }
        }),
      100
    );
  }, [advanceSearch, onSubmit, ref, searchConditions]);
  return { onFilter, searchConditions, setSearchConditions };
};
