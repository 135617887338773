import { CSSProperties } from 'react';

import borders from '../base/borders';
import { baseColors } from '../base/colors';
import typography from '../base/typography';
import pxToRem from '../functions/pxToRem';

export const drawerWidth = 250;
export const drawerWidthMini = 80;

const sidebar = {
  styleOverrides: {
    paper: {
      height: '100vh',
      margin: 0,
      backgroundColor: baseColors.black,
      color: `${baseColors.white} !important`
    }
  }
};

export default sidebar;

const sidebarListItem = (isActive: boolean, hasParent: boolean = false) => ({
  padding: `${pxToRem(8)} ${pxToRem(10)}`,
  paddingBottom: pxToRem(12),
  paddingLeft: hasParent ? pxToRem(40) : pxToRem(10),
  margin: `${pxToRem(10)} ${pxToRem(16)} 0`,
  borderRadius: borders.radius.x,
  backgroundColor: isActive ? baseColors.success : baseColors.transparent,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '&:hover': {
    backgroundColor: '#c8c8c833'
  },
  '&,&:hover,&:focus': {
    color: 'inherit'
  }
});

const sidebarListItemMini = {
  justifyContent: 'center',
  paddingBottom: pxToRem(8),
  paddingLeft: pxToRem(10)
};

const sidebarListItemIcon = {
  color: baseColors.white,
  margin: 0,
  marginRight: pxToRem(12),
  verticalAlign: 'middle'
};

const sidebarListItemIconMini = {
  marginRight: 0
};

const sidebarListItemText = {
  ...typography.body1,
  margin: 0,
  marginTop: pxToRem(4)
};

const sidebarListItemTextMini = {
  display: 'none'
};

const sidebarCollapseListItem = (isActive: boolean) => ({
  padding: `${pxToRem(8)} ${pxToRem(10)}`,
  paddingBottom: pxToRem(12),
  margin: `${pxToRem(10)} ${pxToRem(16)} 0`,
  borderRadius: borders.radius.x,
  backgroundColor: isActive ? '#c8c8c833' : baseColors.transparent,
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  '&:hover': {
    backgroundColor: '#c8c8c833'
  },
  '&,&:hover,&:focus': {
    color: 'inherit'
  }
});

const sidebarCopyright: CSSProperties = {
  position: 'fixed',
  bottom: 0,
  textAlign: 'center',
  width: drawerWidth,
  paddingBottom: pxToRem(12),
  ...typography.body2
};

export {
  sidebarListItem,
  sidebarListItemMini,
  sidebarListItemIcon,
  sidebarListItemIconMini,
  sidebarListItemText,
  sidebarListItemTextMini,
  sidebarCollapseListItem,
  sidebarCopyright
};
