import DatePicker from './Date';
import DateTimePicker from './DateTime';
import TimePicker from './Time';

export default class KPicker {
  static Date = DatePicker;

  static DateTime = DateTimePicker;

  static Time = TimePicker;
}
