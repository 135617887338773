import { IContainerTypeSizeParams, IWithOceanAirParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDContainerTypeSize = () => {
  return useCUDMutationEnhancer<IContainerTypeSizeParams>({
    endPoint: ENDPOINTS.containerTypeSize(),
    webTable: WebTable.CONTAINER_TYPE_SIZE
  });
};

export const useCUDDangerousPackingGroups = () => {
  return useCUDMutationEnhancer<IWithOceanAirParams>({
    endPoint: ENDPOINTS.containerDangerousPackingGroups(),
    webTable: WebTable.CONTAINER_DANGEROUS_PACKING_GROUPS
  });
};

export const useCUDDangerousClass = () => {
  return useCUDMutationEnhancer<IWithOceanAirParams>({
    endPoint: ENDPOINTS.containerDangerousClass(),
    webTable: WebTable.CONTAINER_DANGEROUS_CLASS
  });
};
