import { ENDPOINTS, QUERY_KEYS, ReadStatus, WebTable } from '@constants';
import APIManager from '@services';
import { useMutationEnhancer, useQueryEnhancer } from 'hooks/core';

interface IIparams {
  receiveStaffId?: string | number;
  readStatus?: ReadStatus;
}

export const useFetchNotificationLog = (params: IIparams) => {
  return useQueryEnhancer<any>({
    queryKey: [
      QUERY_KEYS.notificationLog,
      params?.receiveStaffId,
      params?.readStatus
    ],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.notification(),
        body: params
      });
      return res?.data?.data || [];
    },
    enabled: !!params?.receiveStaffId
  });
};

export const useCUDNotificationLog = () => {
  return useMutationEnhancer<any, { id: number | string }>({
    mutationFn: async data => {
      const url = data?.id
        ? ENDPOINTS.notification(':id/read', {
            id: data?.id
          })
        : ENDPOINTS.notification('read-all');

      const res = await APIManager.request({
        url: url,
        method: 'POST'
      });

      return res.success;
    },
    mutationKeys: [QUERY_KEYS.notificationLog as any]
  });
};
