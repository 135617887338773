import React, { memo, useMemo } from 'react';

import {
  AirOceanType,
  NotificationLogSourceType,
  ReadStatus
} from '@constants';
import { Prototype } from 'core';
import { useCUDNotificationLog } from 'hooks/notification';
import trans from 'translation';
import { KChip, KColors, KContainer, KGrid, KLabel, KLoading } from 'uikit';
import './styles.css';

const prefix = ({
  source,
  sourceId,
  content,
  extraData
}: {
  source: NotificationLogSourceType;
  sourceId: number;
  content: string;
  extraData?: {
    consolId: string | null;
    serviceId: string | null;
    shipmentId: string;
    freightMode: 'OCEAN' | 'AIR';
  };
}) => {
  const PREFIX = {
    [AirOceanType.Ocean]: 'ocean-freight',
    [AirOceanType.Air]: 'air-freight'
  };
  let path = '';
  const oceanOrAirFreight = PREFIX[extraData?.freightMode as AirOceanType];

  if (content.includes('Re-Assigned')) {
    return (path = '');
  }

  switch (source) {
    case NotificationLogSourceType.JOB_ORDER:
      path = 'sales/job-order';
      break;
    case NotificationLogSourceType.SYSTEM:
      path = 'help-support/case';
      break;
    case NotificationLogSourceType.SERVICE:
      path = 'service';
      break;
    case NotificationLogSourceType.SHIPMENT:
      path = `${oceanOrAirFreight}/shipment`;
      break;
    case NotificationLogSourceType.AP_INVOICE:
      path = `${oceanOrAirFreight}/consolidation/${extraData?.consolId}/shipment/${extraData?.shipmentId}/billing/ap`;
      break;
    case NotificationLogSourceType.AR_INVOICE:
      path = `${oceanOrAirFreight}/consolidation/${extraData?.consolId}/shipment/${extraData?.shipmentId}/billing/ar`;
      break;
    case NotificationLogSourceType.ACCOUNTING_SPEND_MONEY:
      path = 'spend-money-request';
      break;
  }
  return `/admin/${path}/${sourceId}`;
};

const ItemNotification = ({ item }: any) => {
  const { mutate, isLoading } = useCUDNotificationLog();

  const urlRedirect = useMemo(() => {
    return prefix({
      source: item?.source,
      sourceId: item.sourceId,
      content: item.content,
      extraData: item?.extraData
    });
  }, [item.content, item?.extraData, item?.source, item.sourceId]);

  const timeAgo = Prototype.date.toLocal(item?.createdAt)?.fromNow();
  return (
    <KContainer.Touchable
      className="item-notification"
      margin={'0rem'}
      style={{ borderRadius: '0.725rem' }}
      onPress={() => {
        if (item.readStatus !== 'READ') {
          mutate(
            {
              id: item?.id
            },
            {
              onSuccess() {
                window.open(urlRedirect, '_self');
              }
            }
          );
        } else {
          window.open(urlRedirect, '_self');
        }
      }}
    >
      {isLoading && <KLoading />}
      <KGrid.Container
        marginY={'0rem'}
        marginLeft={'0rem'}
        style={{
          padding: '10px'
        }}
      >
        <KGrid.Item
          xs={3}
          style={{
            padding: '0'
          }}
        >
          <KChip
            size="md"
            brC={KColors.warning.normal}
            background={KColors.opacity.warning['16']}
            label={trans(`${item?.source}`.toLowerCase())}
            width="100%"
          />
        </KGrid.Item>

        <KGrid.Item
          xs={8.2}
          style={{
            paddingTop: '0'
          }}
        >
          <KLabel.Paragraph typo="TextLgMedium">
            {item?.subject}
          </KLabel.Paragraph>
          <KLabel.Paragraph
            marginV={'0.25rem'}
            dangerouslySetInnerHTML={{
              __html: item?.content.replace(/\n/g, '<br>')
            }}
          ></KLabel.Paragraph>
          <KLabel.Paragraph typo="TextXsNormal">
            {` sent by ${item?.sentUsername} at ${timeAgo}`}
          </KLabel.Paragraph>
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={item?.readStatus !== ReadStatus.READ}
          >
            <KGrid.Item xs={0.8}>
              <KContainer.View
                height={'0.8rem'}
                width={'0.8rem'}
                br={'2x'}
                background={KColors.primary.normal}
              ></KContainer.View>
            </KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KGrid.Container>
    </KContainer.Touchable>
  );
};
export default memo(ItemNotification);
