import { IModifyBranchContact } from '@request-dto';
import React, { memo, useCallback } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { KListItem, KInput, KGrid, KForm } from 'uikit';
import * as yup from 'yup';

import { ENDPOINTS, Status, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import {
  useBranchId,
  useCUDBranchContact,
  useFetchBranchCompany,
  useResolverForm
} from 'hooks';
import { useDeleteBranchContactHelper } from 'pages/Company/helper';
import trans from 'translation';
import { ValidationUtils } from 'utils';

const schema = yup.object().shape({
  staffInBranch: ValidationUtils.requiredAny().test(
    'staffInBranch',
    '',
    function (v: any) {
      return !!this.parent?.id || v;
    }
  )
});

interface IProps {
  item: any;
}

const FormContact = ({ item }: IProps) => {
  const isEdit = !!item;

  const {
    createMutation: { mutate: createMutation, isLoading: addLoading },
    updateMutation: { mutate: updateMutation, isLoading: updateLoading }
  } = useCUDBranchContact();

  const { onDelete, deleteLoading } = useDeleteBranchContactHelper();

  const { branchId } = useBranchId();

  const { data: dataBranch } = useFetchBranchCompany(branchId);

  const methods = useResolverForm<IModifyBranchContact>({
    schema: schema,
    configs: {
      values: {
        id: item?.id,
        branch: dataBranch ?? '',
        staffInBranch: item?.staffInBranch,
        purpose: item?.purpose ?? '',
        isDefault: item?.isDefault ?? false,
        status: item?.status ?? Status.Active
      }
    }
  });

  const [staffInBranchWatch] = useWatch({
    control: methods.control,
    name: ['staffInBranch']
  });

  const onSubmit = useCallback(
    (data: IModifyBranchContact) => {
      const { branch, staffInBranch, ...rest } = data;
      const mParams = {
        branchId: branch?.id,
        staffInBranchId: staffInBranch?.id,
        ...rest
      };

      if (isEdit) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, isEdit, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onSubmit)}>
      <KGrid.Container>
        <KGrid.Item xs={5}>
          <KInput.TextField
            value={dataBranch?.code ?? ''}
            disabled
            label={trans('branch_code')}
          />
        </KGrid.Item>

        <KGrid.Item xs={7}>
          <KInput.TextField
            value={dataBranch?.name ?? ''}
            disabled
            label={trans('branch_name')}
          />
        </KGrid.Item>

        <KGrid.Item xs={7}>
          <Controller
            name="staffInBranch"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('select_staff')}
                  inputProps={{
                    required: !isEdit,
                    message: error?.message
                  }}
                  disabled={isEdit}
                  getOptionLabel={(o: any) => {
                    return [o?.staff?.displayName, o?.staff?.email]
                      .filter(v => v)
                      .join(' - ');
                  }}
                  apiParams={{
                    includeFields: ['staff', 'department', 'jobTitle']
                  }}
                  apiURL={ENDPOINTS.branch(':id/staff-branch', {
                    id: branchId
                  })}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={5}>
          <KInput.TextField
            label={trans('staff_status')}
            disabled
            value={staffInBranchWatch?.status ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            disabled
            label={trans('gender')}
            value={staffInBranchWatch?.staff?.gender ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            disabled
            label={trans('department')}
            value={staffInBranchWatch?.department?.name ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            disabled
            label={trans('job_title')}
            value={staffInBranchWatch?.jobTitle?.name ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            disabled
            label={trans('mobile_phone')}
            value={staffInBranchWatch?.staff?.mobilePhone ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            disabled
            label={trans('telephone')}
            value={staffInBranchWatch?.staff?.telephone ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            disabled
            label={trans('first_language')}
            value={staffInBranchWatch?.staff?.firstLanguage?.name ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={12}>
          <Controller
            name="purpose"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField {...field} label={trans('contact_purpose')} />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="isDefault"
            control={methods.control}
            render={({ field }) => {
              return (
                <KListItem.Checkbox
                  {...field}
                  checked={field.value}
                  label={trans('choose_as_default')}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete([item]),
            disabled: !isEdit,
            isLoading: deleteLoading
          },
          {
            isLoading: addLoading || updateLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(FormContact);
