import React from 'react';

import {
  useCUDBranchContact,
  useCUDCompanyBankAccount,
  useCUDBranch,
  useDeleteHelper
} from 'hooks';
import trans from 'translation';
import { UIUtils } from 'utils';

import FormBankAccount from './RightProfile/BankAccount/Form';
import FormContact from './RightProfile/Contact/Form';

export const onShowContact = (v?: any) => {
  UIUtils.popup.open({
    title: trans('branch_contact'),
    maxWidth: 'md',
    content: () => <FormContact item={v} />
  });
};

export const useDeleteBranchContactHelper = () => {
  const {
    deleteMutation: { mutate, isLoading, ...rest }
  } = useCUDBranchContact();

  const onDelete = useDeleteHelper({
    name: trans('contact'),
    mutate
  });

  return { onDelete, deleteLoading: isLoading, ...rest };
};

export const onShowBankAccount = (v?: any) => {
  UIUtils.popup.open({
    title: trans('bank_account'),
    maxWidth: 'md',
    content: () => <FormBankAccount item={v} />
  });
};

export const useDeleteCompanyBankAccount = () => {
  const {
    deleteMutation: { mutate, isLoading, ...rest }
  } = useCUDCompanyBankAccount();

  const onDelete = useDeleteHelper({
    name: 'bank_account',
    mutate
  });

  return { onDelete, deleteLoading: isLoading, ...rest };
};

export const useDeleteBranchCompanyHelper = () => {
  const {
    deleteMutation: { mutate, isLoading, ...rest }
  } = useCUDBranch();

  const onDelete = useDeleteHelper({
    name: 'branch',
    mutate
  });

  return { onDelete, deleteLoading: isLoading, ...rest };
};
