import { IChargeCodeUnitParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDChargeCodeUnit = () => {
  return useCUDMutationEnhancer<IChargeCodeUnitParams>({
    endPoint: ENDPOINTS.chargeCodeUnit(),
    webTable: WebTable.CHARGE_CODE_UNIT
  });
};
