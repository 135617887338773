import { ILibraryParams } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../core';

export const useCUDOtherNoteType = () => {
  return useCUDMutationEnhancer<ILibraryParams>({
    endPoint: ENDPOINTS.noteType(),
    webTable: WebTable.NOTE_TYPE
  });
};

export const useCUDOtherEdocType = () => {
  return useCUDMutationEnhancer<ILibraryParams>({
    endPoint: ENDPOINTS.edocType(),
    webTable: WebTable.EDOC_TYPE
  });
};

export const useCUDTermsCondition = () => {
  return useCUDMutationEnhancer<ILibraryParams>({
    endPoint: ENDPOINTS.termsCondition(),
    webTable: WebTable.TERMS_CATEGORY
  });
};
