import Step from '@mui/material/Step';
import StepConnector, {
  stepConnectorClasses
} from '@mui/material/StepConnector';
import StepContent from '@mui/material/StepContent';
import { StepIconProps } from '@mui/material/StepIcon';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import React, { memo } from 'react';

import { KColors } from '../../constants';
import KImage from '../Image';
import { KStepperProps } from '../types';

const KConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 20
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#3B82F6'
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: KColors.primary.normal
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 5,
    marginLeft: '1rem',
    marginRight: '1rem'
  }
}));

const KStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#3B82F6'
  }),
  ...(ownerState.completed && {
    backgroundColor: KColors.primary.normal
  })
}));

const KStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <KStepIconRoot ownerState={{ completed, active }} className={className}>
      {props.icon}
    </KStepIconRoot>
  );
};

const KStepContent = styled(StepContent)(() => ({
  margin: 0,
  padding: 0
}));

const KStepper = (props: KStepperProps) => {
  const { steps, activeStep = 0 } = props;

  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<KConnector />}
    >
      {steps.map(step => (
        <Step key={step.label}>
          <StepLabel
            StepIconComponent={iconProps => (
              <KStepIcon
                {...iconProps}
                icon={
                  step.icon ? <KImage.MuiIcon icon={step.icon} /> : undefined
                }
              />
            )}
          >
            {step.label}
          </StepLabel>
          {step.content && (
            <KStepContent TransitionProps={{ unmountOnExit: false }}>
              {step.content}
            </KStepContent>
          )}
        </Step>
      ))}
    </Stepper>
  );
};

export default memo(KStepper);
