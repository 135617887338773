import {
  IChargeCodeParams,
  IChargeCodeCategoryParams,
  IChargeCodeSubCategoryParams
} from '@request-dto';

import { ENDPOINTS, QUERY_KEYS, WebTable } from '@constants';
import APIManager from '@services';
import { IBodyField } from 'pages/Library/Category/Sales/Quotation/Form.ChargeCode';

import { useCUDMutationEnhancer, useQueryEnhancer } from '../../../core';

export const useCUDChargeCodeCategory = () => {
  return useCUDMutationEnhancer<IChargeCodeCategoryParams>({
    endPoint: ENDPOINTS.chargeCodeCategory(),
    webTable: WebTable.CHARGE_CODE_CATEGORY,
    dependentWebTable: [WebTable.CHARGE_CODE_SUB_CATEGORY, WebTable.CHARGE_CODE]
  });
};

export const useCUDChargeCodeSubCategory = () => {
  return useCUDMutationEnhancer<IChargeCodeSubCategoryParams>({
    endPoint: ENDPOINTS.chargeCodeSubCategory(),
    webTable: WebTable.CHARGE_CODE_SUB_CATEGORY,
    dependentWebTable: [WebTable.CHARGE_CODE_CATEGORY, WebTable.CHARGE_CODE]
  });
};

export const useCUDChargeCode = () => {
  return useCUDMutationEnhancer<IChargeCodeParams>({
    endPoint: ENDPOINTS.chargeCode(),
    webTable: WebTable.CHARGE_CODE
  });
};

export const useFetchChargeCodeQuotation = (body: IBodyField) => {
  return useQueryEnhancer<any>({
    queryKey: [QUERY_KEYS.chargeCodeList, JSON.stringify(body)],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.chargeCode(),
        body
      });

      return res.data?.data ?? [];
    }
  });
};
