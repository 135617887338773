import { IClient } from '@dto';

import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  Status,
  WebTable
} from '@constants';
import APIManager from '@services';

import {
  useGenericMutationEnhancer,
  usePageCUDMutationEnhancer,
  useQueryEnhancer
} from '../core';

export * from './address';
export * from './salePerson';
export * from './arAp';
export * from './relatedParty';

export const useDeleteClients = (onSuccess?: () => void) => {
  return useGenericMutationEnhancer<number[]>({
    endPoint: ENDPOINTS.client('bulk-delete'),
    method: 'DELETE',
    webTable: WebTable.CLIENT,
    onSuccess
  });
};

export const useFetchClient = (id?: number | string) => {
  return useQueryEnhancer<IClient | undefined>({
    queryKey: [QUERY_KEYS.client, id ? parseInt(id as string) : null],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.client(':id', { id })
      });

      return res.data;
    },
    enabled: !!id
  });
};

export const useFetchClientTypeList = () => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.clientTypeList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.clientType(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC',
          status: Status.Active
        }
      });

      return res.data?.data;
    }
  });
};

export const useFetchEcusClientTypeList = () => {
  return useQueryEnhancer<any[] | undefined>({
    queryKey: [QUERY_KEYS.ecusClientTypeList],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.ecusClientType(),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          sortBy: 'displayPosition:ASC',
          status: Status.Active
        }
      });

      return res.data?.data;
    }
  });
};

export const useCUDClient = () => {
  return usePageCUDMutationEnhancer<any>({
    endPoint: ENDPOINTS.client(),
    webTable: WebTable.CLIENT,
    queryKey: QUERY_KEYS.client
  });
};
