import { cloneDeep, get, isEmpty } from 'lodash';
import { useCallback, useMemo } from 'react';
import { RouteObject } from 'react-router-dom';

import { IAdminRoute } from 'routes/helpers';
import { TableUtils } from 'utils';
import StorageEnhance, { STORAGE_KEYS } from 'utils/storage';

export const getCachedTableConfigs = () => {
  const cacheTableConfigs = localStorage.getItem('tableConfigs');
  return cacheTableConfigs ? JSON.parse(cacheTableConfigs) : undefined;
};

export const cacheColumnOrder = (name: string, columnOrder: number[]) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      columnOrder
    }
  };
  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const cacheViewColumns = (
  name: string,
  columnName: string,
  action: string
) => {
  let tableConfigs = getCachedTableConfigs();

  const result = {
    ...(tableConfigs || {}),
    [name]: {
      ...(tableConfigs?.[name] || {}),
      viewColumns: {
        ...(tableConfigs?.[name]?.viewColumns || {}),
        [columnName]: action === 'remove' ? true : undefined
      }
    }
  };

  localStorage.setItem('tableConfigs', JSON.stringify(result));
};

export const getColumnOrderFromCache = (
  name: string,
  length: number
): number[] | undefined => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  const { columnOrder } = tableConfigs[name];
  if (columnOrder?.length === length) {
    return columnOrder;
  } else {
    // reset cache if add or delete in columns
    let newColumnOrder = [];
    for (let i = 0; i < length; i++) {
      newColumnOrder.push(i);
    }
    cacheColumnOrder(name, newColumnOrder);
    return newColumnOrder;
  }
};

export const getViewColumnsFromCache = (name: string) => {
  const tableConfigs = getCachedTableConfigs();
  if (!tableConfigs || !tableConfigs[name]) {
    return undefined;
  }

  return tableConfigs[name]?.viewColumns;
};

export const reorderColumnsBasedOnCache = (
  name: string,
  columns: any[]
): any[] => {
  // const columnOrder = getColumnOrderFromCache(name, columns.length);
  const viewColumns = getViewColumnsFromCache(name);

  if (viewColumns) {
    columns = columns.map(i => {
      if (viewColumns[i.name]) {
        i.options = { ...(i.options || {}), display: false };
      } else {
        i.options = { ...(i.options || {}), display: true };
      }
      return i;
    });
  }

  return columns;

  // if (columnOrder?.length === columns.length) {
  //   return columnOrder.map(i => columns[i]);
  // } else {
  //   return columns;
  // }
};

export const objPermissions = () => {
  const localUser = StorageEnhance.get(STORAGE_KEYS.user);
  const resPer = useMemo(() => {
    let res = localUser?.staffPermission?.permissionGroup
      ?.map((o: any) => {
        return [...o.permissionSubGroup, o?.code];
      })
      .flat();

    res = res?.reduce((result: any, item: any) => {
      const key = item;
      result[key] = item;
      return result;
    }, {});
    return res;
  }, [localUser?.staffPermission?.permissionGroup]);

  const isAdmin = localUser?.staffPermission?.isAdmin ?? false;
  const isBranchAdmin = localUser?.staffPermission?.isBranchAdmin ?? false;

  const convertTab = useCallback(
    (tabs: any) => {
      return isAdmin || isBranchAdmin
        ? tabs
        : tabs.filter((o: any) => {
            const isCode = (o?.code ?? []).some((x: any) => resPer?.[x]);
            return o?.code && !!resPer ? isCode : true;
          });
    },
    [isAdmin, isBranchAdmin, resPer]
  );

  return {
    resPer,
    // super admin
    isAdmin: localUser?.staffPermission?.isAdmin,
    // branch admin
    isBranchAdmin: localUser?.staffPermission?.isBranchAdmin,
    convertTab
  };
};

export const normalizeFinalColumns = (
  columns: any[],
  mappedFields?: {
    [key: string]: string;
  }
) => {
  return columns.map(i => ({
    ...i,
    mappedName: get(mappedFields, i.name, i.name),
    options: ['createdAt', 'updatedAt'].includes(i.name)
      ? { ...TableUtils.options.date(), ...i.options }
      : ['createdUsername', 'updatedUsername'].includes(i.name)
      ? { ...TableUtils.options.baseXLg, ...i.options }
      : // : ['remark', 'description'].includes(i.name)
        // ? { ...TableUtils.options.withEllipsis, ...i.options }
        { ...TableUtils.options.base, ...i.options },
    allowedToSort: i.options?.sort ?? true
  }));
};

export const usePermissionAdmin = (keyPer: string) => {
  const { resPer, isAdmin, isBranchAdmin } = objPermissions();
  return !resPer?.[keyPer] && !isAdmin && !isBranchAdmin;
};

export const hideMenu = (listKey?: string[]) => {
  if (!listKey) return false;
  const { resPer, isAdmin, isBranchAdmin } = objPermissions();
  const isA = (listKey || []).some(x => resPer?.[x]);

  const isFullPer = isAdmin || isBranchAdmin;
  return !isA && !isFullPer;
};

export const setupAdminRoutes = (
  d?: IAdminRoute[],
  userData?: any
): RouteObject[] => {
  const { isAdmin, isBranchAdmin, userPers } = userData || {};

  if (!d) {
    return [];
  }

  if (isAdmin || isBranchAdmin) {
    return d as RouteObject[];
  }

  const arr = cloneDeep(
    d.filter(i => i.name !== 'Administration' && i.name !== 'System Settings')
  );

  const data = arr.filter(i => {
    const hasPer = (i.code ?? []).some((x: string) => !!userPers?.[x]);

    if (i.code && !hasPer) {
      return false;
    }

    if (!isEmpty(i.children)) {
      i.children = setupAdminRoutes(i.children, userData);
    }

    return true;
  });

  return data as RouteObject[];
};
