import React, { memo } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { KContainer, KGrid, KInput } from 'uikit';

import { CalculatorType } from '@constants';
import trans from 'translation';

import { IFormData } from './helpers';

const Instruction = () => {
  const methods = useFormContext<IFormData>();

  const [calculatorType, currency, isSystem] = useWatch({
    control: methods.control,
    name: ['calculatorType', 'currency', 'isSystem']
  });

  const suffix = ` (${currency?.code ?? ''})`;

  return (
    <KContainer.RenderWhen>
      <KContainer.RenderWhen.If
        isTrue={calculatorType !== CalculatorType.Value}
      >
        <KGrid.Item xs={8}>
          <Controller
            name="instruction"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('instruction')}
                  disabled={isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KContainer.RenderWhen>
          <KContainer.RenderWhen.If
            isTrue={calculatorType === CalculatorType.Composite}
          >
            <KGrid.Item xs={4}>
              <Controller
                name="baseValue"
                control={methods.control}
                render={({ field, fieldState: { error } }) => {
                  return (
                    <KInput.TextField
                      {...field}
                      label={trans('base_value') + suffix}
                      type="number"
                      required
                      message={error?.message}
                      disabled={isSystem}
                    />
                  );
                }}
              />
            </KGrid.Item>
          </KContainer.RenderWhen.If>

          <KContainer.RenderWhen.If isTrue>
            <KGrid.Item xs={4}></KGrid.Item>
          </KContainer.RenderWhen.If>
        </KContainer.RenderWhen>
      </KContainer.RenderWhen.If>
    </KContainer.RenderWhen>
  );
};

export default memo(Instruction);
