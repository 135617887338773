export const GlobalDebug = (function () {
  const savedConsole = console;
  return function (debugOn?: boolean, suppressAll?: boolean) {
    const suppress = suppressAll || false;
    if (debugOn === false) {
      console = {} as any;
      console.log = function () {};
      if (suppress) {
        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  };
})();

export const overrideConsole = () => {
  let error = console.error.bind(console);
  console.error = (...args) => {
    if (typeof args?.[0] === 'string') {
      if (args?.[0]?.indexOf('The pseudo class ":nth-child"') === 0) {
        return;
      }
      if (args?.[0]?.indexOf('Warning: %s is deprecated in StrictMode') === 0) {
        return;
      }
      if (args?.[0]?.indexOf('Warning: React does not recognize') === 0) {
        return;
      }
    }
    error(...args);
  };
};
