import loadable from '@loadable/component';
import pMinDelay from 'p-min-delay';
import React from 'react';

import { GroupReport } from '@constants';
import ReportLayout from 'pages/Report/DetailsLayout';

const ReportExport = loadable(() =>
  pMinDelay(import('pages/Report/Export'), 200)
);

const ReportImport = loadable(() =>
  pMinDelay(import('pages/Report/Import'), 200)
);

const ReportLogistic = loadable(() =>
  pMinDelay(import('pages/Report/Logistic'), 200)
);

const ReportSales = loadable(() =>
  pMinDelay(import('pages/Report/Sales'), 200)
);

const ReportCustomerService = loadable(() =>
  pMinDelay(import('pages/Report/CustomerService'), 200)
);

import { IAdminRoute } from './helpers';

const reportRoutes: IAdminRoute = {
  name: 'Report',
  path: 'report',
  element: <ReportLayout />,
  icon: 'insights',
  noChildren: true,
  menu: [
    GroupReport.REPORT_OF_EXPORT_LIST,
    GroupReport.REPORT_OF_IMPORT_LIST,
    GroupReport.REPORT_OF_LOGISTICS_LIST,
    GroupReport.REPORT_OF_SALES_LIST,
    GroupReport.REPORT_OF_CUSTOMER_SERVICE_LIST,
    GroupReport.REPORT_OF_MARKETING_LIST
  ],
  children: [
    {
      parentKey: 'accounting',
      name: 'Export',
      path: 'export',
      element: <ReportExport />,
      code: [GroupReport.REPORT_OF_EXPORT_LIST]
    },
    {
      parentKey: 'accounting',
      name: 'Import',
      path: 'import',
      element: <ReportImport />,
      code: [GroupReport.REPORT_OF_IMPORT_LIST]
    },
    {
      parentKey: 'accounting',
      name: 'Logistics',
      path: 'logistics',
      element: <ReportLogistic />,
      code: [GroupReport.REPORT_OF_LOGISTICS_LIST]
    },
    {
      parentKey: 'accounting',
      name: 'Sales',
      path: 'sales',
      element: <ReportSales />,
      code: [GroupReport.REPORT_OF_SALES_LIST]
    },
    {
      parentKey: 'accounting',
      name: 'Customer Service',
      path: 'customer-service',
      element: <ReportCustomerService />,
      code: [GroupReport.REPORT_OF_CUSTOMER_SERVICE_LIST]
    },
    {
      parentKey: 'accounting',
      name: 'Marketing',
      path: 'marketing',
      element: <div>marketing</div>,
      code: [GroupReport.REPORT_OF_MARKETING_LIST]
    }
  ]
};

export default reportRoutes;
