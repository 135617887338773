import { IModifyCarrierPolicyNew } from '@request-dto';

import { ENDPOINTS, WebTable } from '@constants';

import { useCUDMutationEnhancer } from '../../../core';

export const useCUDCarrierPolicy = () => {
  return useCUDMutationEnhancer<IModifyCarrierPolicyNew>({
    endPoint: ENDPOINTS.carrierPolicy(),
    webTable: WebTable.CARRIER_POLICY
  });
};
