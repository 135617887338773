import { IBranchOfStaff } from '@dto';
import {
  IAssignStaffBranchParams,
  IUpdateStaffBranchParams
} from '@request-dto';
import { generatePath } from 'react-router-dom';

import {
  ENDPOINTS,
  PAGE_SIZE_DEFAULT_MAX,
  QUERY_KEYS,
  WebTable,
  tableRef
} from '@constants';
import APIManager, { END_POINTS } from '@services';
import { UIUtils } from 'utils';

import {
  useMutationEnhancer,
  useQueryEnhancer,
  useGenericMutationEnhancer
} from '../core';

export const useFetchStaffBranchList = (staffId?: string) => {
  return useQueryEnhancer<IBranchOfStaff[] | undefined>({
    queryKey: [QUERY_KEYS.staffBranchList, staffId],
    queryFn: async () => {
      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id/staff-branch', { id: staffId }),
        body: {
          size: PAGE_SIZE_DEFAULT_MAX,
          includeFields: ['branch']
        }
      });

      return res.data?.data;
    },
    enabled: !!staffId
  });
};

export const useRemoveStaffFromBranch = (id?: string | number) => {
  return useGenericMutationEnhancer<number[]>({
    endPoint: generatePath(END_POINTS.STAFF.DELETE_STAFF_BRANCH_LIST, { id }),
    method: 'DELETE',
    webTable: WebTable.STAFF_BRANCH
  });
};

export const useChooseStaffBranchAsDefault = () => {
  return useMutationEnhancer<
    boolean | undefined,
    { id: string; branchId: number }
  >({
    mutationFn: async data => {
      const res = await APIManager.request({
        url: generatePath(
          END_POINTS.STAFF.CHOOSE_STAFF_BRANCH_AS_DEFAULT,
          data
        ),
        method: 'PUT',
        showToast: true
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.STAFF_BRANCH]?.init?.();
      }
    }
  });
};

export const useAssignBranchToStaff = () => {
  return useMutationEnhancer<boolean | undefined, IAssignStaffBranchParams>({
    mutationFn: async data => {
      const { staffId, ...rest } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id/staff-branch', { id: staffId }),
        method: 'POST',
        showToast: true,
        body: rest
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.STAFF_BRANCH]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};

export const useUpdateStaffBranch = () => {
  return useMutationEnhancer<boolean | undefined, IUpdateStaffBranchParams>({
    mutationFn: async data => {
      const { id, staffId, ...rest } = data;

      const res = await APIManager.request({
        url: ENDPOINTS.staff(':id/staff-branch/:branchId', {
          id: staffId,
          branchId: id
        }),
        method: 'PUT',
        showToast: true,
        body: rest
      });

      return res.success;
    },
    onSuccess: data => {
      if (data) {
        tableRef?.[WebTable.STAFF_BRANCH]?.init?.();
        UIUtils.popup.dismiss();
      }
    }
  });
};
