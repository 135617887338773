import React, { createContext, useContext, useMemo, useState } from 'react';

import { AirOceanType } from '@constants';

const ServiceContext = createContext<any>({});

interface IObjValue {
  dataServiceDetails?: any;
  typeofService?: string;
  isLinkToShipment?: boolean;
  serviceId?: string | number;
  mode: AirOceanType;
}

interface IValue {
  setObjValue: (objValue: IObjValue) => void;
  objValue: IObjValue;
}

const ServiceProvider = ({ children }: { children: any }) => {
  const [objValue, setObjValue] = useState({
    dataServiceDetails: null,
    isLinkToShipment: false,
    typeofService: '',
    serviceId: '',
    mode: AirOceanType.Ocean
  });

  const value = useMemo(() => {
    return {
      objValue,
      setObjValue
    };
  }, [objValue]);

  return (
    <ServiceContext.Provider value={value}>{children}</ServiceContext.Provider>
  );
};

const useServiceContext = (): IValue => useContext(ServiceContext);

export default ServiceProvider;

export { useServiceContext };
