const icon = {
  styleOverrides: {
    fontSizeSmall: {
      width: '1rem',
      height: '1rem'
    }
  }
};

export default icon;
