import { IModifyExchangeRate } from '@request-dto';
import React, { memo, useCallback } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ENDPOINTS } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDExchangeRate } from 'hooks';
import trans from 'translation';
import { KGrid, KInput, KForm } from 'uikit';
import { ValidationUtils } from 'utils';

import { useDeleteExchangeRate } from './helpers';

interface Props {
  item?: any;
  firstToCurrency?: any;
}

const schema = yup.object().shape({
  fromCurrency: yup
    .mixed()
    .test('fromCurrency', trans('validation.required.default'), function (v) {
      const isEdit = this.parent.id;
      if (isEdit) {
        return true;
      }
      return !!v;
    }),
  buyRateTransfer: ValidationUtils.requiredMinNumber(1),
  sellRate: ValidationUtils.requiredMinNumber(1)
});

const ExchangeRateForm = ({ item, firstToCurrency }: Props) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDExchangeRate();

  const { onDelete, deleteLoading } = useDeleteExchangeRate();

  const isEdit = !!item;

  const methods = useResolverForm<IModifyExchangeRate>({
    schema,
    configs: {
      values: {
        id: item?.id,
        fromCurrency: item?.fromCurrency,
        toCurrency: item?.toCurrency ?? firstToCurrency,
        buyRateTransfer: item?.buyRateTransfer ?? 1,
        sellRate: item?.sellRate ?? 1
      }
    }
  });

  const onFormValid = useCallback(
    (data: IModifyExchangeRate) => {
      const { fromCurrency, toCurrency, buyRateTransfer, sellRate } = data;

      const mParams = {
        id: data?.id,
        fromCurrencyId: fromCurrency?.id,
        toCurrencyId: toCurrency?.id,
        buyRateTransfer,
        sellRate
      };
      if (!!mParams.id) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, updateMutation]
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="fromCurrency"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('from_currency_code')}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                  apiURL={ENDPOINTS.currency()}
                  inputProps={{
                    required: !isEdit,
                    message: error?.message
                  }}
                  disabled={isEdit}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="fromCurrency"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('from_currency_name')}
                  apiURL={ENDPOINTS.currency()}
                  inputProps={{
                    required: !isEdit,
                    message: error?.message
                  }}
                  disabled={isEdit}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="toCurrency"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('to_currency_code')}
                  apiURL={ENDPOINTS.currency()}
                  disabled={isEdit || firstToCurrency}
                  getOptionLabel={(o: any) => o?.code ?? ''}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <Controller
            name="toCurrency"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('to_currency_name')}
                  apiURL={ENDPOINTS.currency()}
                  disabled={isEdit}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="buyRateTransfer"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('buying_rates_transfer')}
                  type="number"
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={6}>
          <Controller
            name="sellRate"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('selling_rates')}
                  type="number"
                  message={error?.message}
                  required
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdBy"
            disabled
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="createdAt"
            disabled
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedBy"
            disabled
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
          />
        </KGrid.Item>

        <KGrid.Item xs={3}>
          <KInput.TextField
            name="updatedAt"
            disabled
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit && !!onDelete}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            disabled: !isEdit,
            isLoading: deleteLoading
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(ExchangeRateForm);
