import { IMetadata } from '@dto';
import { IItemProps } from '@ui';
import React, { memo, useCallback, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import * as yup from 'yup';

import { ClientType, ENDPOINTS, Status, generateOptions } from '@constants';
import { BottomActions } from 'components';
import { Prototype } from 'core';
import { useResolverForm, useCUDVessel } from 'hooks';
import trans from 'translation';
import { KGrid, KInput, KListItem, KForm } from 'uikit';
import { ValidationUtils } from 'utils';

import { useDeleteVesselsHelper } from '../../helpers';

interface FormData {
  code: string;
  name: string;
  lloyd: string;
  status: Status;
  operatorShippingLine: IMetadata;
}

const schema = yup.object().shape({
  code: ValidationUtils.required(),
  name: ValidationUtils.required()
});

const VesselForm = ({ item }: IItemProps) => {
  const {
    createMutation: { mutate: createMutation },
    updateMutation: { mutate: updateMutation },
    modifyLoading
  } = useCUDVessel();

  const { onDelete, deleteLoading } = useDeleteVesselsHelper();
  const isEdit = !!item;

  const methods = useResolverForm<FormData>({
    schema,
    configs: {
      values: {
        code: item?.code ?? '',
        name: item?.name ?? '',
        lloyd: item?.lloyd ?? '',
        status: item?.status ?? Status.Active,
        operatorShippingLine: item?.operatorShippingLine
      }
    }
  });

  const onFormValid = useCallback(
    (data: FormData) => {
      const mParams = {
        ...data,
        id: item?.id,
        operatorShippingLineId: data.operatorShippingLine?.id
      };

      if (isEdit) {
        updateMutation(mParams);
      } else {
        createMutation(mParams);
      }
    },
    [createMutation, isEdit, item?.id, updateMutation]
  );

  const apiParams = useMemo(
    () => ({
      clientTypeCodes: [ClientType.ShippingLine]
    }),
    []
  );

  return (
    <KForm onSubmit={methods.handleSubmit(onFormValid)}>
      <KGrid.Container>
        <KGrid.Item xs={3}>
          <Controller
            name="code"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('code')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={7}>
          <Controller
            name="name"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  required
                  label={trans('name')}
                  message={error?.message}
                  disabled={item?.isSystem}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2}>
          <KListItem.Radio
            label={trans('is_system')}
            checked={item?.isSystem}
            disabled
            containerStyle={{ marginTop: '0.75rem' }}
          />
        </KGrid.Item>

        <KGrid.Item xs={7}>
          <Controller
            name="operatorShippingLine"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.Autocomplete
                  {...field}
                  label={trans('operator_shipping_line')}
                  inputProps={{
                    message: error?.message
                  }}
                  apiURL={ENDPOINTS.client()}
                  apiParams={apiParams}
                  hasEdit
                  editURL="client"
                  hasAddNew
                  addNewURL="client"
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={5}>
          <Controller
            name="lloyd"
            control={methods.control}
            render={({ field, fieldState: { error } }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('lloyd_imo')}
                  message={error?.message}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <Controller
            name="status"
            control={methods.control}
            render={({ field }) => {
              return (
                <KInput.TextField
                  {...field}
                  label={trans('status')}
                  options={generateOptions(Status)}
                />
              );
            }}
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdBy"
            label={trans('created_by')}
            value={item?.createdUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="createdAt"
            label={trans('created_at')}
            value={Prototype.date.formatDT(item?.createdAt, '')}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedBy"
            label={trans('updated_by')}
            value={item?.updatedUsername ?? ''}
            disabled
          />
        </KGrid.Item>

        <KGrid.Item xs={2.4}>
          <KInput.TextField
            name="updatedAt"
            label={trans('updated_at')}
            value={Prototype.date.formatDT(item?.updatedAt, '')}
            disabled
          />
        </KGrid.Item>
      </KGrid.Container>

      <BottomActions
        showDelete={isEdit}
        btnProps={[
          {
            onPress: () => onDelete?.([item]),
            isLoading: deleteLoading,
            disabled: item?.isSystem
          },
          {
            isLoading: modifyLoading
          }
        ]}
      />
    </KForm>
  );
};

export default memo(VesselForm);
