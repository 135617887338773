import { alertRef, popperRef, popupRef } from '@constants';
import { KAlertProps, KPopperProps, KPopupProps } from 'uikit';

export default class UIUtils {
  static readonly alert = {
    open: (params: KAlertProps) => {
      alertRef.current?.open(params);
    },
    dismiss: () => {
      alertRef.current?.dismiss();
    },
    dismissAll: () => {
      alertRef.current?.dismissAll();
    }
  };

  static readonly popup = {
    open: (params: KPopupProps) => {
      popupRef.current?.open(params);
    },
    dismiss: () => {
      popupRef.current?.dismiss();
    },
    dismissAll: () => {
      popupRef.current?.dismissAll();
    }
  };

  static readonly popper = {
    open: (params: KPopperProps) => {
      popperRef.current?.open(params);
    },
    dismiss: () => {
      popperRef.current?.dismiss();
    },
    dismissAll: () => {
      popperRef.current?.dismissAll();
    }
  };
}
