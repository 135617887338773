import { baseColors } from './colors';

const globals = {
  html: {
    scrollBehavior: 'smooth'
  },
  '*': {
    scrollbarWidth: 'thin'
  },
  '*::-webkit-scrollbar': {
    width: '0.4rem',
    height: '0.4rem'
  },
  '*::-webkit-scrollbar-thumb': {
    background: 'darkgrey',
    borderRadius: 6
  },
  '*, *::before, *::after': {
    margin: 0,
    padding: 0
  },
  'a, a:link, a:visited': {
    textDecoration: 'none !important'
  },
  'a.link, .link, a.link:link, .link:link, a.link:visited, .link:visited': {
    color: `${baseColors.black} !important`,
    transition: 'color 150ms ease-in !important'
  },
  'a.link:hover, .link:hover, a.link:focus, .link:focus': {
    color: `${baseColors.info} !important`
  }
};

export default globals;
