import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import { WithPopupProps } from '@ui';
import React, {
  forwardRef,
  memo,
  useCallback,
  useId,
  useImperativeHandle,
  useMemo,
  useState
} from 'react';
import Draggable from 'react-draggable';

import { popperRef } from '@constants';
import { KButton, KLabel, KPopupProps } from 'uikit';

const Draggable1: any = Draggable;

const PaperComponent = memo((props: PaperProps) => {
  return (
    <Draggable1
      handle={`#${props['aria-labelledby']}`}
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable1>
  );
});

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const KDialogTitle = memo((props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2, pl: 3, cursor: 'move' }} {...other}>
      {children}
      <KButton.Icon
        aria-label="close"
        icon="Close"
        onPress={onClose}
        sx={{
          position: 'absolute',
          right: '0.5rem',
          top: '0.5rem',
          color: theme => theme.palette.grey[500]
        }}
      />
    </DialogTitle>
  );
});

interface KPopupItemProps {
  item: KPopupProps;
  dismiss: () => void;
}

const KPopupItem = memo(({ item, dismiss }: KPopupItemProps) => {
  const {
    id: itemId,
    touchOutsideToDismiss,
    title,
    titleProps,
    content,
    ...rest
  } = item;
  const { color, typo } = titleProps || {};

  const randomId = useId();

  const id = useMemo(
    () => (itemId || randomId).replace(/:/g, ''),
    [itemId, randomId]
  );

  return (
    <Dialog
      key={id}
      aria-labelledby={`title-${id}`}
      maxWidth="sm"
      fullWidth
      {...rest}
      open={true}
      PaperComponent={PaperComponent}
      onClose={touchOutsideToDismiss ? dismiss : undefined}
    >
      <KDialogTitle id={`title-${id}`} onClose={dismiss}>
        <KLabel.Text typo={typo || 'TextMdMedium'} color={color}>
          {title}
        </KLabel.Text>
      </KDialogTitle>

      <DialogContent dividers>{content(dismiss)}</DialogContent>
    </Dialog>
  );
});

const KPopup = forwardRef<WithPopupProps>((_, ref) => {
  const [data, setData] = useState<KPopupProps[]>([]);

  const dismiss = useCallback(() => {
    const newData = data.slice(0, -1);
    setData(newData);
    popperRef.current?.dismissAll();
  }, [data]);

  useImperativeHandle(
    ref,
    () => ({
      open: payload => {
        setData([...data, payload]);
        popperRef.current?.dismissAll();
      },
      dismiss,
      dismissAll: () => {
        setData([]);
        popperRef.current?.dismissAll();
      }
    }),
    [data, dismiss]
  );

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <>
      {data.map(i => (
        <KPopupItem key={i.title} item={i} dismiss={dismiss} />
      ))}
    </>
  );
});

export default memo(KPopup);
