import React, { memo, useCallback } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { KContainer, KLabel, KListItem } from 'uikit';

import trans from 'translation';

import { IFormData } from './helpers';

const Kind = () => {
  const methods = useFormContext<IFormData>();

  const {
    formState: { errors }
  } = methods;

  const onAfterChange = useCallback(() => {
    methods.trigger('kind');
  }, [methods]);

  const [isSystem] = useWatch({
    control: methods.control,
    name: ['isSystem']
  });

  return (
    <>
      <Controller
        name="kind.0"
        control={methods.control}
        render={({ field }) => {
          return (
            <KListItem.Checkbox
              {...field}
              label={trans('dangerous')}
              checked={field.value}
              containerStyle={{ marginTop: '0.25rem' }}
              onAfterChange={onAfterChange}
              disabled={isSystem}
            />
          );
        }}
      />

      <Controller
        name="kind.1"
        control={methods.control}
        render={({ field }) => {
          return (
            <KListItem.Checkbox
              {...field}
              label={trans('refrigerated')}
              checked={field.value}
              containerStyle={{ marginTop: '0.5rem' }}
              onAfterChange={() => {
                onAfterChange();
                methods.setValue('containerTypeSizes', []);
              }}
              disabled={isSystem}
            />
          );
        }}
      />

      {!!errors?.kind && (
        <KContainer.View marginT="0.5rem">
          <KLabel.Error withIcon>{errors?.kind?.message}</KLabel.Error>
        </KContainer.View>
      )}
    </>
  );
};

export default memo(Kind);
