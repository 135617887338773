import common_en from './en.common.json';
import navigator_en from './en.navigator.json';
import option_en from './en.option.json';
import unit_en from './en.unit.json';
import message_en from './message_en';
import role_en from './role_en';
import staff_en from './staff_en';
import validation_en from './validation_en';

const en = {
  translation: {
    ...common_en,
    ...navigator_en,
    ...option_en,
    ...unit_en,

    validation: validation_en,
    staff: staff_en,
    role: role_en,
    message: message_en
  }
};

const vi = {
  translation: {
    ...common_en,
    ...navigator_en
  }
};

export { vi, en };
