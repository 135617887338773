import { useMemo } from 'react';

import {
  AirContainerMode,
  AirOceanType,
  ClientType,
  DeliveryMode,
  DeliveryModeAir,
  OceanContainerMode,
  generateOptions,
  OceanBasicContainerMode
} from '@constants';
import trans from 'translation';

export const useAirOceanParams = (mode?: AirOceanType) => {
  if (!mode) {
    return {};
  }

  const isAir = mode === AirOceanType.Air;

  return {
    isAir: isAir ? true : undefined,
    isOcean: isAir ? undefined : true
  };
};

export const useAirOceanUIParams = ({
  mode,
  screen
}: {
  mode: AirOceanType;
  screen: 'job-order' | 'service';
}) => {
  const options = useMemo(() => {
    const data = {
      [AirOceanType.Ocean]: {
        key: 'shippingLine',
        contModeOptions:
          screen === 'service'
            ? generateOptions(OceanContainerMode)
            : generateOptions(OceanBasicContainerMode),
        deliveryModeOptions: generateOptions(DeliveryMode),
        clientTypeCodes: [ClientType.ShippingLine],
        isOcean: true,
        title: {
          pol: 'port_of_loading',
          pod: 'port_of_discharge',
          oblNo: 'mbl_no',
          oblPaymentTerm: 'mbl_payment_term',
          oblReleasedDate: 'mbl_released_date',
          oblReleasedType: 'mbl_released_type',
          hblNo: 'hbl_no',
          hblPaymentTerm: 'hbl_payment_term',
          hblReleasedDate: 'hbl_released_date',
          hblReleasedType: 'hbl_released_type',
          shippingLineCode: 'shipping_line_code',
          shippingLineName: 'shipping_line_name'
        }
      },
      [AirOceanType.Air]: {
        key: 'airline',
        contModeOptions: generateOptions(AirContainerMode),
        deliveryModeOptions: generateOptions(DeliveryModeAir),
        clientTypeCodes: [ClientType.Airline],
        isAir: true,
        title: {
          pol: 'airport_of_loading',
          pod: 'airport_of_discharge',
          oblNo: 'mawb_no',
          oblPaymentTerm: 'mawb_payment_term',
          oblReleasedDate: 'mawb_released_date',
          oblReleasedType: 'mawb_released_type',
          hblNo: 'hawb_no',
          hblPaymentTerm: 'hawb_payment_term',
          hblReleasedDate: 'hawb_released_date',
          hblReleasedType: 'hawb_released_type',
          shippingLineCode: 'airline_code',
          shippingLineName: 'airline_name'
        }
      }
    };

    return data[mode];
  }, [mode, screen]);

  return {
    ...options,
    title: Object.keys(options.title).reduce((acc, key) => {
      acc[key] = trans((options.title as any)[key]);
      return acc;
    }, {} as any)
  };
};
