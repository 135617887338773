import React, { createRef, memo, useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { GroupReport, UITab } from '@constants';
import { useNavigateDefaultTab, useUITabsWithCallback } from 'hooks';
import {
  setDocumentTitle,
  useDocumentTitle
} from 'hooks/useDocumentTitle/use-document-title';
import trans from 'translation';
import { KContainer, KTab } from 'uikit';

const DetailsLayout = () => {
  useDocumentTitle('Report | Export');
  const navigate = useNavigate();

  const tabsRef = createRef<any>();

  const cb = useCallback(() => {
    const _tabs = [
      {
        key: UITab.ReportExport,
        path: 'export',
        label: trans('tab.export'),
        code: [GroupReport.REPORT_OF_EXPORT_LIST]
      },
      {
        key: UITab.ReportImport,
        path: 'import',
        label: trans('tab.import'),
        code: [GroupReport.REPORT_OF_IMPORT_LIST]
      },
      {
        key: UITab.ReportLogistics,
        path: 'logistics',
        label: trans('tab.logistics'),
        code: [GroupReport.REPORT_OF_LOGISTICS_LIST]
      },
      {
        key: UITab.ReportSales,
        path: 'sales',
        label: trans('tab.sales'),
        code: [GroupReport.REPORT_OF_SALES_LIST]
      },
      {
        key: UITab.ReportCustomerService,
        path: 'customer-service',
        label: trans('tab.customer_service'),
        code: [GroupReport.REPORT_OF_CUSTOMER_SERVICE_LIST]
      }
      // {
      //   key: UITab.ReportMarketing,
      //   path: 'marketing',
      //   label: trans('tab.marketing'),
      //   code: [GroupReport.REPORT_OF_MARKETING_LIST]
      // }
    ];

    return _tabs;
  }, []);

  const tabs = useUITabsWithCallback(cb, {
    defFb: '/admin/report'
  });

  useNavigateDefaultTab(tabsRef, tabs, 3);

  const onChangeTab = useCallback(
    (idx: number) => {
      navigate(tabs[idx].path);
      setDocumentTitle(`Report | ${tabs[idx].label}`);
    },
    [navigate, tabs]
  );

  if (tabs.length === 0) return null;

  return (
    <>
      <KTab.Main ref={tabsRef} tabs={tabs} onChangeTab={onChangeTab} />

      <KContainer.View paddingH="0.75rem" paddingV="0.25rem" marginB="4rem">
        <Outlet />
      </KContainer.View>
    </>
  );
};

export default memo(DetailsLayout);
