import React, { createRef, memo, useCallback } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import { GroupAccounting, UITab } from '@constants';
import { useNavigateDefaultTab, useUITabsWithCallback } from 'hooks';
import trans from 'translation';
import { KContainer, KTab, KTabInstance } from 'uikit';
import { isNew } from 'utils';

import { useAccountingBreadcrumbs } from './helpers';

import { useDocumentTitle } from '../../hooks/useDocumentTitle/use-document-title';

const DetailsLayout = () => {
  const { pathname } = useLocation();

  const {
    statementOfAccountId,
    paymentId,
    statementInvoiceId,
    receivableAdviceId,
    paymentAdviceId,
    moneyId
  } = useParams();

  const navigate = useNavigate();

  const tabsRef = createRef<KTabInstance>();

  const cb = useCallback(() => {
    const _tabs = [
      {
        key: UITab.Accounting,
        path: 'dashboard',
        label: trans('tab.dashboard'),
        code: [GroupAccounting.ACCOUNTING_DASHBOARD_DETAIL]
      },
      {
        key: UITab.AccountingSales,
        path: 'sales',
        label: trans('tab.sales'),
        code: [
          GroupAccounting.ACCOUNTING_AR_INVOICE_LIST,
          isNew(5) || receivableAdviceId
            ? GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE_DETAIL
            : GroupAccounting.ACCOUNTING_RECEIVABLE_ADVICE_LIST
        ]
      },
      {
        key: UITab.AccountingPurchases,
        path: 'purchases',
        label: trans('tab.purchases'),
        code: [
          GroupAccounting.ACCOUNTING_AP_INVOICE_LIST,
          isNew(5) || paymentAdviceId
            ? GroupAccounting.ACCOUNTING_PAYMENT_ADVICE_DETAIL
            : GroupAccounting.ACCOUNTING_PAYMENT_ADVICE_LIST
        ]
      },
      {
        key: UITab.AccountingStatement,
        path: 'statement',
        label: trans('tab.statement'),
        code: [
          isNew(4) || statementOfAccountId
            ? GroupAccounting.ACCOUNTING_STATEMENT_OF_ACCOUNT_DETAIL
            : GroupAccounting.ACCOUNTING_STATEMENT_OF_ACCOUNT_LIST,
          isNew(5) || paymentId
            ? GroupAccounting.ACCOUNTING_STATEMENT_PAYMENT_DETAIL
            : GroupAccounting.ACCOUNTING_STATEMENT_PAYMENT_LIST,
          isNew(5) || statementInvoiceId
            ? GroupAccounting.ACCOUNTING_STATEMENT_INVOICE_DETAIL
            : GroupAccounting.ACCOUNTING_STATEMENT_INVOICE_LIST
        ]
      },
      {
        key: UITab.AccountingBanking,
        path: 'banking',
        label: trans('tab.banking'),
        code: [
          GroupAccounting.ACCOUNTING_BANK_ACCOUNT_LIST,
          GroupAccounting.ACCOUNTING_BANK_TRANSACTION_LIST,
          isNew(7) || moneyId
            ? GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_DETAIL
            : GroupAccounting.ACCOUNTING_RECEIVE_SPEND_MONEY_LIST
        ]
      },
      {
        key: UITab.Report,
        path: 'report',
        label: trans('tab.report')
      },
      {
        key: UITab.AccountingMore,
        path: 'more',
        label: trans('tab.more'),
        code: [
          GroupAccounting.ACCOUNTING_CHART_OF_ACCOUNT_LIST,
          GroupAccounting.ACCOUNTING_LINKED_ACCOUNT_LIST,
          GroupAccounting.ACCOUNTING_TAX_CODE_LIST
        ]
      }
    ];

    return _tabs;
  }, [
    moneyId,
    paymentAdviceId,
    paymentId,
    receivableAdviceId,
    statementInvoiceId,
    statementOfAccountId
  ]);

  const tabs = useUITabsWithCallback(cb, {
    defFb: '/admin/accounting'
  });

  useNavigateDefaultTab(tabsRef, tabs, 3);

  useDocumentTitle('Accounting | Apollogix');

  const onChangeTab = useCallback(
    (idx: number) => {
      navigate(tabs[idx].path);
    },
    [navigate, tabs]
  );

  const breadcrumbs = useAccountingBreadcrumbs(pathname);

  if (tabs.length === 0) return null;

  return (
    <>
      {breadcrumbs}

      <KTab.Main ref={tabsRef} tabs={tabs} onChangeTab={onChangeTab} />

      <KContainer.View paddingH="0.75rem" paddingV="0.25rem" marginB="4rem">
        <Outlet />
      </KContainer.View>
    </>
  );
};

export default memo(DetailsLayout);
